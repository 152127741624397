import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent implements OnInit {

  @Input() record: FormGroup;
  @Input() screen;

  constructor() { }

  get maxDuration() { return this.record.get('maxDuration'); }

  ngOnInit(): void {
    this.fillForm();
  }

  private fillForm() {
    if (this.screen) {
      this.record.get('maxDuration').patchValue(this.screen.content.questions[0].maxDuration / 60 || 1);
    }
  }

}
