import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TestService } from 'src/app/core/services/test.service';
import { ObservationComponent } from '../modal/observation/observation.component';

@Component({
  selector: 'app-correction',
  templateUrl: './correction.component.html',
  styleUrls: ['./correction.component.scss']
})
export class CorrectionComponent implements OnInit {

  @Input() test;
  @Input() screen;
  @Input() candidateTest;

  @Output() gradeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() observationChange: EventEmitter<string> = new EventEmitter<string>();

  public faRegular = {
    thumbsUp: faThumbsUp,
    thumbsDown: faThumbsDown,
  };
  public faSolid = {
    plus: faPlus,
  };
  public grade: number = -1;
  private rating: any = {
    activityId: null,
    userManager: null,
    scoreType: null,
    nota: null,
    vacancyTest: null,
    observation: null,
    isCorrect: null
  };
  private observationHistory = [];
  private companyId: string = '';
  private vacancyId: string = '';
  private userId: string = '';
  private stepId: string = '';
  private testId: string = '';
  private token: string = '';

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private testService: TestService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.params.companyId;
    this.vacancyId = this.route.snapshot.params.vacancyId;
    this.stepId = this.route.snapshot.params.stepId;
    this.testId = this.route.snapshot.params.testId;
    this.token = this.route.snapshot.queryParams.token;

    if (this.candidateTest && this.candidateTest.user && this.candidateTest.user._id) {
      this.userId = this.candidateTest.user._id;
    }

    if (localStorage.getItem('user')) {
      const userManager = JSON.parse(localStorage.getItem('user'));

      this.rating.userManager = {
        _id: userManager._id,
        firstName: userManager.firstName,
        lastName: userManager.lastName,
        email: userManager.email
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['screen'] !== undefined) {
      this.grade = this.screen.nota;
      this.getObservationHistory();
    }
  }

  private getObservationHistory() {
    if (!this.screen.evaluations || !this.screen.evaluations.length) {
      this.observationHistory = [];
    };

    const history = this.screen.evaluations
      .filter(evaluation => evaluation.observation);

    this.observationHistory = history
      .filter((evaluation, index) => history.findIndex(obj => obj.observation === evaluation.observation) === index);
  }

  public openObservationModal() {
    let modal = this.modalService.open(ObservationComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.observationHistory = this.observationHistory;

    modal.result.then(res => {
      if (res) {
        this.saveRating(res);
      }
    }).catch(err => { });
  }

  public changeGrade(nota: number) {
    this.grade = nota;
  }

  public correctionQuestion(): void {
    if (String(this.screen.contentFormat) === 'multipleQuiz' || this.grade === this.screen.nota) {
      return;
    }

    this.gradeChange.emit(this.grade);
  }

  public saveRating(observation: string) {
    this.rating.nota = this.screen.nota;
    if (this.screen.scoreType === 'boolean') {
      this.rating.isCorrect = this.screen.isCorrect;
    }
    this.rating.observation = observation || null;
    this.rating.activityId = this.screen._id;
    if (this.test.rule === 'tests_with_weights' || this.test.rule === 'tests_with_weights_profiles') {
      this.rating.scoreType = this.screen.contentFormat === 'weightQuiz' || this.screen.contentFormat === 'weightProfileQuiz'
        ? 'number'
        : this.screen.scoreType;
    } else {
      this.rating.scoreType = this.screen.contentFormat === 'multipleQuiz'
        ? 'boolean'
        : this.screen.scoreType;
    }
    this.rating.vacancyTest = this.testId;

    this.testService.ratingQuestion(this.companyId, this.vacancyId, this.userId, this.stepId, this.testId, this.token, this.rating)
      .subscribe((res) => {
        if (res) {
          const answerIndex = res.answers.findIndex(answer => String(answer.activityId) === String(this.screen._id));
          if (answerIndex !== -1) {
            this.screen.evaluations = res.answers[answerIndex].evaluations || [];
            this.screen.isCorrect = res.answers[answerIndex].isCorrect;
            this.screen.nota = Number(res.answers[answerIndex].nota);
            this.screen.observation = res.answers[answerIndex].observation || '';
          }
          this.getObservationHistory();
          this.observationChange.emit(res);
        }

        this.toastrService.clear();
        this.toastrService.success('A avaliação foi salva com sucesso', 'Informação salva!', {
          enableHtml: true
        });
      }, (err) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
      });
  }

}
