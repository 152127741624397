<div class="position-relative">
  <button *ngIf="filters?.length > 1" (click)="removeFilterBadge(null)" class="taqe-badge clean">
    Limpar todos
  </button>

  <div class="" [ngClass]="{ 'pt-10': filters?.length > 1 }">
    <ng-container *ngFor="let filter of filters; let i = index">
      <button class="taqe-badge" (click)="removeFilterBadge(i)">
        {{ (filter | filterName) || '' }}
        <i class="ion-close"></i>
      </button>
    </ng-container>
  </div>
</div>
