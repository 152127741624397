import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import CONSTANTS from 'src/app/core/constants';
import { FileUploader } from 'ng2-file-upload';
import { ActivityBase } from 'src/app/models/test.model';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent implements OnInit {

  @Input() screen: ActivityBase;
  @Input() instruction: FormGroup;
  @Input() imageUploader: FileUploader;
  @Input() videoUploader: FileUploader;
  @Input() typeScreen: string;
  @Input() image: string;
  @Input() video: string;
  @Input() errorFileMessage: string;

  @Output() fileChange: EventEmitter<string> = new EventEmitter<string>();

  public assets_path: string = environment.assets_path;
  public ALLOWED_IMAGE = CONSTANTS.ALLOWED_IMAGE;
  public ALLOWED_VIDEO = CONSTANTS.ALLOWED_VIDEO;

  constructor() {
  }

  get name() { return this.instruction.get('name'); }
  get textField() { return this.instruction.get('text'); }

  ngOnInit(): void {
    this.fillForm();
  }

  private fillForm() {
    if (this.screen) {
      this.instruction.get('name').patchValue(this.screen.name);

      if (this.typeScreen === 'instruction') {
        this.instruction.get('text').patchValue(this.screen.presentation?.text || '');
      } else {
        this.instruction.get('text').patchValue(this.screen.content.questions[0]?.text || '');
      }
    }
  }

  public changeName(event: any) {
    this.instruction.get('name').patchValue(event.target.value.trim());
  }

  public changeText(event: any) {
    const text = event.target.value.trim();
    this.instruction.get('text').patchValue(text);
  }

  public changeFile(type: string) {
    this.fileChange.emit(type);
  }

}
