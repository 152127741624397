import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { RatingTestsComponent } from './rating-tests/rating-tests.component';
import { EditCustomScreensComponent } from './edit-custom-screens/edit-custom-screens.component';
import { EditScreenComponent } from './edit-custom-screens/edit-screen/edit-screen.component';
import { EditScreenAccessByUrlGuard } from './core/guards/edit-screen-access-by-url.guard';
import { HomepageCustomScreensComponent } from './homepage-custom-screens/homepage-custom-screens.component';
import { CreateCustomScreensComponent } from './create-custom-screens/create-custom-screens.component';
import { TestCorrectionComponent } from './test-correction/test-correction.component';

const routes: Routes = [{
  path: 'companies/tests',
  component: AppComponent
}, {
  path: 'companies/tests/:companyId',
  component: HomepageCustomScreensComponent
}, {
  path: 'companies/tests/:companyId/create-test',
  component: CreateCustomScreensComponent
}, {
  path: 'companies/tests/:companyId/edit-test/:testId',
  component: EditCustomScreensComponent,
}, {
  path: 'companies/tests/:companyId/edit-test/:testId/edit-screen/:typeScreen',
  canActivate: [EditScreenAccessByUrlGuard],
  component: EditScreenComponent,
}, {
  path: 'companies/tests/:companyId/:vacancyId/:stepId/:testId/result',
  component: TestCorrectionComponent
}, {
  path: 'companies/tests/:companyId/:vacancyId/:userId/:stepId/:testId/result',
  component: TestCorrectionComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
