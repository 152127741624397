<h3 class="group-screen-title mb-12">Resposta do candidato |
  <span class="sub-title">{{ screen.contentFormatName }}</span>
</h3>

<div class="custom-card m-w-90 ml-16 mb-40" *ngIf="screen.contentFormat === 'text'">
  <p class="text preview">{{ screen.answer }}</p>
</div>

<div class="custom-card m-w-90 ml-16 mb-40 d-flex justify-content-center" *ngIf="screen.contentFormat === 'video'">
  <video class="media-preview" poster="{{assets_path}}assets/images/icons/taqe-logo.svg" controls data-overlay="1">
    <source [src]="screen.answer" type="video/mp4">
  </video>
</div>

<div class="custom-card m-w-90 ml-16 mb-40" *ngIf="screen.contentFormat === 'audio'">
  <audio class="w-100" controls data-overlay="1" preload="auto" [src]="screen.answer" type="audio/mpeg">
  </audio>
</div>

<div class="custom-card m-w-90 ml-16 mb-40" *ngIf="screen.contentFormat === 'multipleQuiz'">
  <div class="group-quiz">
    <p class="quiz-preview mb-24">Alternativa escolhida: {{ screen.answer }}</p>
    <p class="quiz-preview answer">Alternativa correta: Alternativa {{ screen.correctAnswerLetter }} -
      {{ screen.correctAnswerOption }}</p>
  </div>
</div>

<div class="custom-card m-w-90 ml-16 mb-40" *ngIf="screen.contentFormat === 'weightQuiz'">
  <div class="group-quiz">
    <p class="quiz-preview mb-24">Alternativa escolhida: {{ screen.answer }}</p>
    <p class="quiz-preview answer">Pontuações disponíveis nesta questão:</p>
    <ng-container *ngFor="let alternative of screen.alternatives">
      <p class="quiz-preview answer">
        Alternativa {{ alternative.letter }}: {{ alternative.option }} - {{ alternative.profileValue }}
        {{ !alternative.profileValue || alternative.profileValue === 1 ? 'ponto' : 'pontos' }}</p>
    </ng-container>
  </div>
</div>

<div class="custom-card m-w-90 ml-16 mb-40" *ngIf="screen.contentFormat === 'weightProfileQuiz'">
  <div class="group-quiz">
    <p class="quiz-preview mb-24">Alternativa escolhida: {{ screen.answer }}</p>
    <p class="quiz-preview answer">Pontuações disponíveis nesta questão:</p>
    <ng-container *ngFor="let alternative of screen.alternatives">
      <p class="quiz-preview answer">
        Alternativa {{ alternative.letter }}: {{ alternative.option }} - {{ alternative.profileValue }}
        {{ !alternative.profileValue || alternative.profileValue === 1 ? 'ponto' : 'pontos' }}
        | {{ alternative.profileName }}</p>
    </ng-container>
  </div>
</div>
