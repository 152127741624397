import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TestService } from 'src/app/core/services/test.service';
import { BaseTest, Screen } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-screen',
  templateUrl: './delete-screen.component.html',
  styleUrls: ['./delete-screen.component.scss']
})
export class DeleteScreenComponent implements OnInit {

  @Input() screen: Screen;
  @Input() test: BaseTest;
  @Input() companyId: string;
  @Input() token: string;
  public assets_path: string = environment.assets_path;
  public isSave: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private testService: TestService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  public deleteScreen() {
    if (!this.test.canEdit) {
      return;
    }

    this.isSave = true;
    let activities = this.test.activities.filter(activity => activity._id !== this.screen._id);

    this.testService.updateTest(
      this.companyId,
      this.token,
      this.test._id,
      {
        vacancyTest: {
          activities,
          rule: this.test.rule
        }
      }
    )
      .subscribe((res) => {
        let message = `A tela <b>${this.screen?.name}</b> foi excluída com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });
        this.activeModal.close(res);
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
      });
  }

}
