import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-changes',
  templateUrl: './alert-changes.component.html',
  styleUrls: ['./alert-changes.component.scss']
})
export class AlertChangesComponent implements OnInit {

  @Input() text = '';
  @Input() title = '';

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  close(param) {
    this.activeModal.close(param);
  }

}
