import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import CONSTANTS from '../../core/constants';

@Injectable()
export class UserHelper {
  url: string = environment.url;

  constructor() { }

  getUserFullName(user: any) {
    if (!user || !user.basicInformation) {
      return 'Nome não declarado';
    }

    let fullName = user.basicInformation.fullName;

    if (this.getUserIsOfAnotherGender(user)) {
      fullName = user.basicInformation.socialName;
    }

    if (!fullName || fullName === '') {
      fullName = 'Nome não declarado';
    }

    return fullName;
  }

  getUserIsOfAnotherGender(user: any): Boolean {
    if (!user || !user.basicInformation) {
      return false;
    }

    if (
      !user.basicInformation.socialName ||
      [' ', '-'].includes(user.basicInformation.socialName)
    ) {
      return false;
    }

    if (
      !CONSTANTS.GENDER.binaryGenders.includes(user.basicInformation.gender)
    ) {
      return true;
    }

    return false;
  }
}
