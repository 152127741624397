<div class="container">
    <h1>TESTE SELECIONADO</h1>

    <div class="phone-body " >
        <div class="phone-detail">
            <div class="rectangle"></div>
            <div class="circle"></div>
        </div>
        <div class="phone-content">
            <!-- <owl-carousel-o [options]="customOptions" >
                    <ng-template carouselSlide  *ngFor="let test of tests"> -->
                    <div class="flex-container">
                    <div class="test-name" style="flex: auto auto 50px">
                        <p>Nome do Teste</p>
                        {{test?.name}}
                    </div>
                    <div class="test-image" style="flex: 0 0 250px">
                        <img src="{{ test?.image || assets_path + 'assets/images/capas_no_image.png'}}">
                    </div>
                    <div class="test-description" style="flex: 0 0 150px">
                        <p>Descrição do teste</p>
                        {{test?.description}}
                    </div>
                </div>
                <!-- </ng-template>
            </owl-carousel-o> -->
        </div>
    </div>
    <!-- <div class="footer-box">
        <p>Para editar as informações dessa tela basta clicar sobre os textos e alternativas</p>
    </div> -->
</div>
