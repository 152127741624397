<h1 class="title mb-16">Conteúdos |
  <span class="sub-title">
    {{ tests.length ? pagLog.pagination.total : 0 }} testes
  </span>
</h1>

<div class="screen-content mb-16" *ngIf="!loadTest">
  <ng-container
    *ngFor="let test of tests | paginate: { itemsPerPage: pagLog.pagination.limit, currentPage: page, totalItems: pagLog.pagination.total }; let i = index;">
    <div class="custom-card card-screen w-97">
      <div class="row w-100 card-screen-main">

        <h3 class="col-xl-3 col-xxl-2 custom-card-header card-screen-header mb-12">
          Teste {{ (page - 1) * pagLog.pagination.limit + i + 1 }} -
          {{ (test.name.length > 20) ? (test.name | slice:0:20) +'...' : (test.name) }}
        </h3>

        <div class="col-xl-9 col-xxl-10">
          <dl class="row">
            <dt class="col-6 col-xxl-4 d-flex align-items-center mb-8">
              <div class="icon-tag fill-gray option-icon" [inlineSVG]="assets_path + 'assets/images/icons/tags.svg'">
              </div>
              {{ test.rule ? TEST_TYPES[test.rule] : 'Testes avaliativos' }}
            </dt>
            <dt class="col-6 col-xxl-4 d-flex align-items-center mb-8">
              <mat-icon class="icon-tag color-gray option-icon">layers</mat-icon>
              {{ test.totalVacancyUse }} vagas em uso
            </dt>
            <dt class="col-6 col-xxl-4 d-flex align-items-center mb-8">
              <div class="icon-tag fill-gray option-icon"
                [inlineSVG]="assets_path + 'assets/images/icons/blue-language.svg'">
              </div>
              {{ test.active ? 'Publicado' : 'Ainda não publicado' }}
            </dt>
          </dl>
        </div>
      </div>

      <dl class="card-screen-footer">
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Publicar teste" ngbTooltip="Publicar teste"
            tooltipClass="custom-tooltip" (click)="openPublishTestModal(test, i)">
            <div class="fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/blue-language.svg'"></div>
          </button>
        </dt>
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Duplicar teste" ngbTooltip="Duplicar teste"
            tooltipClass="custom-tooltip" (click)="openTestCloneModal(test)">
            <div class="fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/copy.svg'"></div>
          </button>
        </dt>
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Editar teste" ngbTooltip="Editar teste"
            tooltipClass="custom-tooltip" (click)="editTest(test)">
            <div class="fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/edit.svg'"></div>
          </button>
        </dt>
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Excluir teste" ngbTooltip="Excluir teste"
            tooltipClass="custom-tooltip" (click)="openDeleteTestModal(test, i)">
            <div class="fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/delete.svg'"></div>
          </button>
        </dt>
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Informações do teste" ngbTooltip="Informações do teste"
            tooltipClass="custom-tooltip" (click)="openTestInfoModal(test, ((page - 1) * pagLog.pagination.limit + i))">
            <div class="fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/info.svg'"></div>
          </button>
        </dt>
      </dl>
    </div>
  </ng-container>
</div>

<div *ngIf="loadTest" class="load-position">
  <mat-spinner class="my-4" [diameter]="60"></mat-spinner>
</div>

<section class="center-object" *ngIf="!loadTest">
  <pagination-controls class="custom-pagination" (pageChange)="getPage(page = $event)" previousLabel="" nextLabel="">
  </pagination-controls>
</section>
