import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  @Input() text: string;
  @Input() image: string;
  @Input() video: string;
  @Input() alternatives;
  @Input() alternativesWeight;
  @Input() typeScreen: string;
  @Input() rule: string;

  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() imageChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() videoChange: EventEmitter<string> = new EventEmitter<string>();

  public assets_path: string = environment.assets_path;

  constructor() { }

  ngOnInit(): void {
  }

  public delete(component) {
    if (component === 'text') {
      this.text = '';
      this.textChange.emit(this.text);
    } else if (component === 'image') {
      this.image = '';
      this.imageChange.emit(this.image);
    } else if (component === 'video') {
      this.video = '';
      this.videoChange.emit(this.video);
    }
  }
}
