<ng-container [formGroup]="instruction">
  <div class="custom-card">
    <div class="position-icon-text group-title mb-20">
      <div class="fill-purple mr-3" [inlineSVG]="assets_path + 'assets/images/icons/text-format.svg'"></div>
      Nome da tela
    </div>
    <mat-form-field class="w-100 mb-10" appearance="outline">
      <mat-label>Nome da tela</mat-label>
      <input matInput type="text" placeholder="Digite o nome da tela" formControlName="name"
        (change)="changeName($event)">
      <mat-error *ngIf="name.errors">
        <p class="error-message" *ngIf="name.errors?.required">Campo obrigatório</p>
        <p class="error-message" *ngIf="name.errors?.maxlength">O nome precisa ter menos que 20 caracteres</p>
      </mat-error>
    </mat-form-field>
  </div>

  <mat-accordion>
    <mat-expansion-panel class="expansion-card">

      <mat-expansion-panel-header class="custom-expansion">
        <mat-panel-title class="position-icon-text group-title">
          <div class="fill-purple mr-3" [inlineSVG]="assets_path + 'assets/images/icons/text-format.svg'"></div>
          Adicionar texto
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100 mb-10">
        <mat-label>Instrução</mat-label>
        <textarea matInput #input placeholder="Digite a instrução" formControlName="text" cols="30" rows="10"
          (change)="changeText($event)"></textarea>
        <mat-error *ngIf="textField.errors">
          <p class="error-message" *ngIf="textField.errors?.minlength">O texto de instrução precisa ter pelo menos 5
            caracteres</p>
        </mat-error>
        <mat-hint align="end">{{input.value?.length || 0}} caracteres</mat-hint>
      </mat-form-field>

    </mat-expansion-panel>
  </mat-accordion>

  <div class="custom-card mt-28" [ngClass]="{'disabled': video}">
    <label for="customImageInput" (click)="changeFile('image')" class="position-center-space mb-0">
      <div class="position-icon-text group-title">
        <div class="fill-purple mr-3" [inlineSVG]="assets_path + 'assets/images/icons/image.svg'"></div>
        Adicionar imagem
      </div>
      <mat-icon class="color-purple">upload</mat-icon>
    </label>
    <input type="file" class="custom-file-input-image" id="customImageInput" ng2FileSelect [uploader]="imageUploader"
      [accept]="ALLOWED_IMAGE.MIMETYPE.toString()" placeholder="Escolher imagem de instrução">
  </div>

  <div class="custom-card" [ngClass]="{'disabled': image}">
    <label for="customVideoInput" (click)="changeFile('video')" class="position-center-space mb-0">
      <div class="position-icon-text group-title">
        <div class="fill-purple mr-3" [inlineSVG]="assets_path + 'assets/images/icons/video.svg'"></div>
        Adicionar vídeo
      </div>
      <mat-icon class="color-purple">upload</mat-icon>
    </label>
    <input type="file" class="custom-file-input-image" id="customVideoInput" ng2FileSelect [uploader]="videoUploader"
      [accept]="ALLOWED_VIDEO.MIMETYPE.toString()" placeholder="Escolher vídeo de instrução">
  </div>
</ng-container>

<p class="alert-message" *ngIf="video || image">
  * Não é permitido escolher imagem e vídeo para uma mesma tela
</p>
<p class="message-help mb-20" *ngIf="errorFileMessage">
  {{ errorFileMessage }}
</p>
