<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h2 class="w-100 text-center title-modal">Teste {{ index + 1 }} - {{ test.name }}</h2>

  <dl class="row mx-12">
    <dt class="col-3 d-flex align-items-center mb-8">
      <div class="icon-tag fill-gray option-icon" [inlineSVG]="assets_path + 'assets/images/icons/tags.svg'">
      </div>
      {{ test.rule ? TEST_TYPES[test.rule] : 'Testes avaliativos' }}
    </dt>
    <dt class="col-3 d-flex align-items-center mb-8">
      <mat-icon class="icon-tag color-gray option-icon">layers</mat-icon>
      {{ test.totalVacancyUse }} vagas em uso
    </dt>
    <!-- <dt class="col-3 d-flex align-items-center mb-8">
      <mat-icon class="icon-tag color-gray option-icon">push_pin</mat-icon>
      Teste padrão
    </dt> -->
    <dt class="col-3 d-flex align-items-center mb-8">
      <div class="icon-tag fill-gray option-icon"
        [inlineSVG]="assets_path + 'assets/images/icons/mobile-alt-solid.svg'">
      </div>
      {{ test.activities.length || 0 }} telas
    </dt>
    <dt class="col-3 d-flex align-items-center mb-8" *ngIf="test.createDate">
      <div class="icon-tag fill-gray option-icon" [inlineSVG]="assets_path + 'assets/images/icons/calender.svg'">
      </div>
      Criado em {{ test.createDate | date: 'dd/MM/yyyy' }}
    </dt>
    <!-- <dt class="col-4 d-flex align-items-center mb-8">
      <div class="icon-tag fill-gray option-icon" [inlineSVG]="assets_path + 'assets/images/icons/calender.svg'">
      </div>
      Publicado em
    </dt> -->
    <dt class="col-6 d-flex align-items-center mb-8" *ngIf="test.periodicAvailability">
      <div class="icon-tag fill-gray option-icon" [inlineSVG]="assets_path + 'assets/images/icons/update.svg'"></div>
      {{ periodicAvailability.name }}
    </dt>
    <dt class="col-6 d-flex align-items-center mb-8" *ngIf="test.creator">
      <div class="icon-tag fill-gray option-icon" [inlineSVG]="assets_path + 'assets/images/icons/user.svg'">
      </div>
      Criado por {{ test.creator.firstName + ' ' + test.creator.lastName }}
    </dt>
  </dl>

  <p class="text-description">{{ test.description }}</p>

  <dl class="modal-footer-button">
    <dt class="mx-2">
      <button class="button-icon action-button" aria-label="Publicar teste" ngbTooltip="Publicar teste"
        tooltipClass="custom-tooltip" (click)="openPublishTestModal()">
        <div class="fill-gray" [ngClass]="{ 'fill-blue': test.active, 'fill-gray': !test.active }"
          [inlineSVG]="assets_path + 'assets/images/icons/blue-language.svg'"></div>
      </button>
    </dt>
    <dt class="mx-2">
      <button class="button-icon action-button" aria-label="Editar teste" ngbTooltip="Editar teste"
        tooltipClass="custom-tooltip" (click)="editTest()">
        <div class="fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/edit.svg'"></div>
      </button>
    </dt>
  </dl>

  <!-- <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Voltar</button>
  </div> -->
</div>
