import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserManager } from 'src/app/models/user-manager.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {
  private baseUrl: string = environment.url;

  constructor(
    private httpClient: HttpClient
  ) { }

  private getHeaders(token: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  }

  getUsersManagerByCompany(companyId: string, token: string): Observable<UserManager[]> {
    const headers = this.getHeaders(token);

    return this.httpClient.get<UserManager[]>(`${this.baseUrl}/user-managers/${companyId}/all`, { headers });
  }
}
