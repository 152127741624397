<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="center-object">
    <div class=" fill-blue modal-img" [inlineSVG]="assets_path + 'assets/images/icons/blue-language.svg'"></div>
  </div>
  <h2 class="w-100 text-center title-modal">{{ title }} - {{ test.name }}</h2>

  <p class="w-80">
    {{ description }}
  </p>
  <p class="w-80" *ngIf="confirm">
    {{ confirm }}
  </p>


  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Cancelar</button>
    <button class="btn btn-green mx-4" [ngClass]="{'d-none': !test.canEdit}" type="submit" [disabled]="isSave"
      (click)="publishTest()">
      {{ buttonText }}
    </button>
  </div>
</div>
