import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import CONSTANTS from 'src/app/core/constants';
import { TestService } from '../core/services/test.service';

@Component({
  selector: 'app-create-custom-screens',
  templateUrl: './create-custom-screens.component.html',
  styleUrls: ['./create-custom-screens.component.scss']
})
export class CreateCustomScreensComponent implements OnInit {

  public companyId: string = '' as string;
  public token: string = '' as string;
  public form: FormGroup;
  public testTypeOption: any = [];
  public isSaveForm: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private testService: TestService,
    private toastrService: ToastrService,
  ) { }

  get name() { return this.form.get('name'); }
  get rule() { return this.form.get('rule'); }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.params.companyId;
    this.token = this.route.snapshot.queryParams.token;
    this.testTypeOption = Object.keys(CONSTANTS.TEST_TYPES).map(type => ({ value: type, name: CONSTANTS.TEST_TYPES[type] }));
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      name: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20)
      ]],
      rule: ['', [Validators.required]],
    });
  }

  public changeName(event: any) {
    this.name.patchValue(event.target.value.trim());
  }

  public backHome() {
    this.router.navigateByUrl(`companies/tests/${this.companyId}?token=${this.token}`);
  }

  public submitForm() {
    if (!this.form.valid) {
      return;
    }
    this.isSaveForm = true;

    const data = {
      vacancyTest: {
        name: this.name.value,
        rule: this.rule.value,
      }
    };

    this.testService.createTest(
      data,
      this.companyId,
      this.token,
    )
      .subscribe((res) => {
        let message = `O teste <b>${this.name.value}</b> foi criado com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });

        this.router.navigateByUrl(`companies/tests/${this.companyId}/edit-test/${res._id}?token=${this.token}`);
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As informações não foram salvas!', 'Erro na criação');
        this.isSaveForm = false;
      });
  }

}
