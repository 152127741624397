import { Component, Input, OnInit } from '@angular/core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-evaluation-history',
  templateUrl: './evaluation-history.component.html',
  styleUrls: ['./evaluation-history.component.scss']
})
export class EvaluationHistoryComponent implements OnInit {

  @Input() evaluations;

  public faRegular = {
    clock: faClock,
  };

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  public close(param?) {
    this.activeModal.close(param);
  }

}
