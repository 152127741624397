<div class="container-fluid body">
  <app-header *ngIf="!load" [test]="test" [subTitle]="screens.length + ' telas'"></app-header>

  <div class="row" *ngIf="!load">
    <app-test-info class="col-4 col-xxl-3 mt-60" [(test)]="test" [(screens)]="screens" [companyId]="companyId"
      [token]="token">
    </app-test-info>

    <div class="col-7 offset-05 col-xxl-8 offset-xxl-05">
      <app-choose-screen-type *ngIf="test?.canEdit" [token]="token" [companyId]="companyId" [testId]="test._id"
        [rule]="test.rule">
      </app-choose-screen-type>
      <app-list-screens [(test)]="test" [(screens)]="screens" [companyId]="companyId" [token]="token">
      </app-list-screens>
    </div>
  </div>

  <div *ngIf="load" class="load-position">
    <mat-spinner class="my-4" [diameter]="60"></mat-spinner>
  </div>

  <div class="group-buttons" *ngIf="!load && test?.canEdit">
    <button class="btn btn-green" (click)="goHomepage()">Salvar teste e voltar para a tela inicial</button>
  </div>

  <div class="group-buttons" *ngIf="!load && !test?.canEdit">
    <button class="btn btn-purple-blue" (click)="goHomepage()">Voltar para a tela inicial</button>
  </div>

</div>
