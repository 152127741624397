import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TestService } from 'src/app/core/services/test.service';
import { BaseTest, Screen } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-screen-name',
  templateUrl: './edit-screen-name.component.html',
  styleUrls: ['./edit-screen-name.component.scss']
})
export class EditScreenNameComponent implements OnInit {

  @Input() screen: Screen;
  @Input() test: BaseTest;
  @Input() companyId: string;
  @Input() token: string;
  public assets_path: string = environment.assets_path;
  public form: FormGroup;
  public isSaveForm: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private testService: TestService,
    private toastrService: ToastrService
  ) { }

  get name() { return this.form.get('name'); }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      name: [this.screen?.name || '', [
        Validators.required,
        Validators.maxLength(20)
      ]]
    });
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  public changeName(event: any) {
    this.name.patchValue(event.target.value.trim());
  }

  public submitForm() {
    if (!this.form.valid) {
      return;
    }
    const name = this.form.get('name').value;
    this.isSaveForm = true;

    let activities = this.test.activities.map(activity => {
      if (activity._id === this.screen._id) {
        activity.name = name;
      }
      return activity;
    });

    this.testService.updateTest(
      this.companyId,
      this.token,
      this.test._id,
      {
        vacancyTest: {
          activities
        }
      }
    )
      .subscribe((res) => {
        let message = `O nome da tela <b>${this.screen?.name}</b> foi atualizado com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });
        this.activeModal.close(res);
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
      });
  }

}
