import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTest, Screen } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';

import { EditGeneralTestInformationComponent } from '../modal/edit-general-test-information/edit-general-test-information.component';
import { SortScreensComponent } from '../modal/sort-screens/sort-screens.component';

import { getScreens } from '../../core/helpers';
import CONSTANTS from '../../core/constants';
import { PublishTestComponent } from 'src/app/core/modal/publish-test/publish-test.component';
import { ConfigPeriodicAvailabilityComponent } from '../modal/config-periodic-availability/config-periodic-availability.component';

@Component({
  selector: 'app-test-info',
  templateUrl: './test-info.component.html',
  styleUrls: ['./test-info.component.scss']
})
export class TestInfoComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() screens: Screen[];
  @Input() companyId: string;
  @Input() token: string;

  @Output() testChange: EventEmitter<BaseTest> = new EventEmitter<BaseTest>();
  @Output() screensChange: EventEmitter<Screen[]> = new EventEmitter<Screen[]>();

  public SCREENS_TYPES = CONSTANTS.SCREEN_OPTIONS_DEFAULT;
  public assets_path: string = environment.assets_path;
  public testImg: string = '';

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    if (String(this.test.rule) === 'tests_with_weights') {
      this.SCREENS_TYPES = CONSTANTS.WEIGHTS_SCREEN_OPTIONS;
    } else if (String(this.test.rule) === 'tests_with_weights_profiles') {
      this.SCREENS_TYPES = CONSTANTS.WEIGHTS_PROFILES_SCREEN_OPTIONS;
    }
    this.getTestImage(this.test);
  }

  private getTestImage(test) {
    if (test?.image) {
      this.testImg = test?.image;
    } else if (localStorage.getItem('company')) {
      const company = JSON.parse(localStorage.getItem('company'));
      if (company?.logo) {
        this.testImg = company?.logo;
      }
    }
  }

  public openGeneralInfoModal() {
    let modal = this.modalService.open(EditGeneralTestInformationComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.test = this.test;
    modal.componentInstance.testImg = this.testImg;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.test = res;
        this.getTestImage(res);
        this.testChange.emit(this.test);
      }
    }).catch(err => { });
  }

  public openPublishTestModal() {
    let modal = this.modalService.open(PublishTestComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.test = this.test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.test = res;
        this.testChange.emit(this.test);
      }
    }).catch(err => { });
  }

  public openConfigPeriodicAvailabilityModal() {
    let modal = this.modalService.open(ConfigPeriodicAvailabilityComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.test = this.test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.test = res;
        this.testChange.emit(this.test);
      }
    }).catch(err => { });
  }

  public openSortScreensModal() {
    let modal = this.modalService.open(SortScreensComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.test = this.test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.screens = getScreens(res, this.SCREENS_TYPES);
        this.screensChange.emit(this.screens);
      }
    }).catch(err => { });
  }

}
