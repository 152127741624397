import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnChanges {
  public title: string = 'taqe-modulo-teste' as string;
  @Input() currentUrl: string = '' as string;

  constructor(
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.currentUrl) {
      this.router.navigateByUrl(this.currentUrl);
    }
  }
}
