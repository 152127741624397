<h3 class="sub-title mb-40">{{ statusTitle }}</h3>

<div class="custom-card card-filter ml-12">
  <div class="custom-card-body">
    <form [formGroup]="form">
      <div class="d-flex">
        <mat-form-field class="w-100">
          <mat-label>Buscar pelo nome</mat-label>
          <input type="name" matInput formControlName="name" (change)="changeName($event)" />
        </mat-form-field>
        <fa-icon class="color-blue mt-12" size="lg" [icon]="faSolid.search"></fa-icon>
      </div>
    </form>
  </div>
</div>

<div *ngIf="filterBadges.length" class="mb-12">
  <app-badge [(filters)]="filterBadges" (selectedFilter)="removeFilter($event)"></app-badge>
</div>

<p class="sub-title mb-12">
  {{ candidates.length ? pagLog.pagination.total : 0 }} candidatos
</p>

<div class="group-card-user mb-20" *ngIf="!loadCandidate">
  <ng-container
    *ngFor="let candidate of candidates | paginate: { itemsPerPage: pagLog.pagination.limit, currentPage: page, totalItems: pagLog.pagination.total }; let i = index;">
    <div class="custom-card card-user" [ngClass]="{ 'card-opacity': selectUser && (selectUser !== candidate.user) }"
      (click)="selectCandidate(candidate)">
      <div class="mr-8">
        <img class="user-img" [src]="candidate.photo || (assets_path + '/assets/images/profile-picture.png')" />
      </div>

      <div class="row">
        <h3 class="col-12 custom-card-header card-user-header">{{ candidate.name }}</h3>
        <div class="col-12 user-test-info">
          <fa-icon class="icon-24 fill-gray" size="lg" [icon]="faRegular.calendar"></fa-icon>
          <p>Iniciado em {{candidate.beginDate | date:'dd/MM/yyy'}} às {{candidate.beginDate | date: 'HH:mm'}}</p>
        </div>
        <div class="col-12 user-test-info">
          <fa-layers size="lg" class="icon-24 fill-gray">
            <fa-icon [icon]="faRegular.calendar"></fa-icon>
            <fa-icon [icon]="faSolid.check" transform="shrink-6 down-2"></fa-icon>
          </fa-layers>
          <p>Finalizado em {{candidate.finishDate | date:'dd/MM/yyy'}} às {{candidate.finishDate | date: 'HH:mm'}}</p>
        </div>
        <div class="col-12 col-3xl-5 user-test-info">
          <fa-icon class="icon-24 fill-gray" size="lg" [icon]="faRegular.clipboard" transform="grow-2"></fa-icon>
          <p>{{ candidate.evaluatedTest ? 'Teste avaliado' : 'Teste não avaliado' }}</p>
        </div>
        <div class="col-12 col-3xl-6 user-test-info">
          <mat-icon *ngIf="candidate.status === 'pending'" class="material-icons-outlined color-gray">shield</mat-icon>
          <mat-icon *ngIf="candidate.status === 'approved'" class="material-icons-outlined color-gray">verified_user
          </mat-icon>
          <mat-icon *ngIf="candidate.status === 'disapproved'" class="material-icons-outlined color-gray">gpp_bad
          </mat-icon>
          <p>{{ TEST_STATUS[candidate.status] }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="loadCandidate" class="load-position">
  <mat-spinner class="my-4" [diameter]="60"></mat-spinner>
</div>

<section class="center-object" *ngIf="!loadCandidate">
  <pagination-controls class="custom-pagination" (pageChange)="getPage(page = $event)" previousLabel="" nextLabel="">
  </pagination-controls>
</section>
