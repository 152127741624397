<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="center-object">
    <div class=" fill-blue modal-img" [inlineSVG]="assets_path + 'assets/images/icons/update.svg'"></div>
  </div>
  <h2 class="w-100 text-center title-modal">Refazer teste - {{ test.name }}</h2>

  <p class="w-80 mb-20">
    {{ description }}
  </p>

  <ng-container *ngFor="let periodic of PERIODIC_AVAILABILITY; index as i; let last = last">
    <div class="custom-card w-80 py-10" [ngClass]="{'last-child': last}">
      <button class="card-option w-100" [disabled]="!test.canEdit" (click)="selectPeriodic(periodic.value)">
        {{ periodic.name }}
        <div class="check-icon" [ngClass]="{'active': periodic.value === test.periodicAvailability }">
          <mat-icon>check</mat-icon>
        </div>
      </button>
    </div>
  </ng-container>

  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Cancelar</button>
    <button class="btn btn-green mx-4" [ngClass]="{'d-none': !test.canEdit}" type="submit" [disabled]="isSave"
      (click)="updateTest()">
      Salvar
    </button>
  </div>
</div>
