import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FileUploadModule } from 'ng2-file-upload';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxPaginationModule } from 'ngx-pagination';

import { CoreModule } from '../core/components/core.module';

import { HomepageCustomScreensComponent } from './homepage-custom-screens.component';
import { FiltersComponent } from './filters/filters.component';
import { ListTestsComponent } from './list-tests/list-tests.component';
import { InfoComponent } from './modal/info/info.component';
import { ModalModule } from '../core/modal/modal.module.component';
import { DeleteTestComponent } from './modal/delete-test/delete-test.component';
import { PipesModule } from '../core/pipes/pipes.module';
import { CloneTestComponent } from './modal/clone-test/clone-test.component';

@NgModule({
  declarations: [
    HomepageCustomScreensComponent,
    FiltersComponent,
    ListTestsComponent,
    InfoComponent,
    DeleteTestComponent,
    CloneTestComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FileUploadModule,
    InlineSVGModule.forRoot(),
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatCardModule,
    NgxPaginationModule,
    ModalModule,
    PipesModule,
    CoreModule
  ],
  exports: [
    HomepageCustomScreensComponent
  ]
})
export class HomepageCustomScreensModule { }
