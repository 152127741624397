import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { CreateCustomTestComponent } from './create-custom-test.component';
import { MobileScreenTestComponent } from './mobile-screen-test/mobile-screen-test.component';
import { AsnwerQuiz1Component } from './components-mobile-screen/asnwer-quiz1/asnwer-quiz1.component';

import { TaqeMfeComponentHeaderConfigModule } from 'taqe-mfe-component-header-config';

@NgModule({
  declarations: [
    CreateCustomTestComponent,
    MobileScreenTestComponent,
    AsnwerQuiz1Component
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    TaqeMfeComponentHeaderConfigModule,
    MatProgressBarModule
  ],
  exports: [
    CreateCustomTestComponent
  ]
})
export class CreateCustomTestModule { }
