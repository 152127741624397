import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseTest } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';
import { TestService } from '../../services/test.service';

@Component({
  selector: 'app-publish-test',
  templateUrl: './publish-test.component.html',
  styleUrls: ['./publish-test.component.scss']
})
export class PublishTestComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() companyId: string;
  @Input() token: string;
  public assets_path: string = environment.assets_path;
  public title: string = '';
  public description: string = '';
  public confirm: string = '';
  public buttonText: string = '';
  public isSave: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private testService: TestService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initText();
  }

  private initText() {
    if (!this.test.active) {
      this.title = 'Publicar teste';
      this.description = 'Caso o teste já tenha sido usado em alguma vaga ele não poderá mais ser despublicado.';
      this.confirm = 'Deseja seguir com a publicação de teste?';
      this.buttonText = 'Publicar';
    } else {
      if (this.test.canEdit) {
        this.title = 'Despublicar teste';
        this.description = 'Uma vez que o teste seja despublicado não poderá ser usado nas vagas.';
        this.confirm = 'Deseja seguir com a despublicação de teste?';
        this.buttonText = 'Despublicar';
      } else {
        this.title = 'Teste publicado';
        this.description = 'Uma vez que o teste seja usado nas vagas não poderá ser despublicada.';
      }
    }
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  public publishTest() {
    if (!this.test.canEdit) {
      return;
    }

    this.isSave = true;
    this.testService.updateTest(
      this.companyId,
      this.token,
      this.test._id,
      {
        vacancyTest: {
          active: !this.test.active
        }
      }
    )
      .subscribe((res) => {
        let message = `O teste <b>${this.test?.name}</b> foi <b>${this.test.active ? 'despublicado' : 'publicado'}</b> com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });
        this.activeModal.close(res);
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
      });
  }

}
