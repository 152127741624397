import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faClone, faEye } from '@fortawesome/free-regular-svg-icons';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserHelper } from 'src/app/core/helpers';
import { TestService } from 'src/app/core/services/test.service';
import { AlertChangesComponent } from 'src/app/modal/alert-changes/alert-changes.component';
import { environment } from 'src/environments/environment';
import { EvaluationHistoryComponent } from './modal/evaluation-history/evaluation-history.component';

@Component({
  selector: 'app-candidate-test-info',
  templateUrl: './candidate-test-info.component.html',
  styleUrls: ['./candidate-test-info.component.scss']
})
export class CandidateTestInfoComponent implements OnInit {

  @Input() test;
  @Input() candidateTest;
  @Input() screens;
  @Input() finishedTest: boolean;

  @Output() candidateTestChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() screensChange: EventEmitter<any> = new EventEmitter<any>();

  public faRegular = {
    eye: faEye,
    clone: faClone,
  };
  public faSolid = {
    right: faAngleRight,
    left: faAngleLeft,
  };
  public assets_path: string = environment.assets_path;
  public activeSlide: boolean = true;
  public isSave: boolean = false;
  public enableApproved: boolean = false;
  public selectedScreen;
  public screenOptions = [];
  public startIndex: number = 0;
  public lastIndex: number = 11;
  public helpMessage: string = '';

  private rating: any = {
    activityId: null,
    userManager: null,
    scoreType: null,
    nota: null,
    vacancyTest: null,
    observation: null,
    isCorrect: null
  };
  private companyId: string = '';
  private vacancyId: string = '';
  private userId: string = '';
  private stepId: string = '';
  private testId: string = '';
  private token: string = '';

  constructor(
    private route: ActivatedRoute,
    private testService: TestService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    public userHelper: UserHelper
  ) { }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.params.companyId;
    this.vacancyId = this.route.snapshot.params.vacancyId;
    this.stepId = this.route.snapshot.params.stepId;
    this.testId = this.route.snapshot.params.testId;
    this.token = this.route.snapshot.queryParams.token;

    if (this.candidateTest && this.candidateTest.user && this.candidateTest.user._id) {
      this.userId = this.candidateTest.user._id;
    }

    if (localStorage.getItem('user')) {
      const userManager = JSON.parse(localStorage.getItem('user'));

      this.rating.userManager = {
        _id: userManager._id,
        firstName: userManager.firstName,
        lastName: userManager.lastName,
        email: userManager.email
      };
    }
    this.checkEnableApproved(this.candidateTest);
    this.setScreens();
  }

  private setScreens() {
    this.selectedScreen = this.screens[0];

    if (this.screens.length < 13) {
      this.activeSlide = false;
      this.screenOptions = this.screens;
    } else {
      this.screenOptions = this.screens.slice(this.startIndex, 12);
    }
  }

  private checkEnableApproved(answerTest) {
    if (!answerTest) return;

    if (answerTest.status && answerTest.status !== 'pending') {
      let userManagerName, date;

      this.enableApproved = false;
      if (answerTest.history) {
        userManagerName = answerTest.history.userManagerName || '';
        date = answerTest.history.date
          ? new Date(answerTest.history.date).toLocaleDateString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' })
          : null;
        this.helpMessage = `Este teste já foi corrigido e ${answerTest.status === 'approved' ? 'aprovado' : 'reprovado'} por ${userManagerName} na data ${date}.`;
      } else {
        this.helpMessage = `Este teste já foi corrigido e ${answerTest.status === 'approved' ? 'aprovado' : 'reprovado'}.`;
      }

    } else if (answerTest.beginDate && answerTest.finishDate) {
      this.enableApproved = answerTest.answers
        .filter(screen => Number(screen.nota) === -1)
        .length ? false : true;

      this.helpMessage = 'Este teste ainda não foi corrigido. Para aprovar ou reprovar o teste de um candidato, por favor corrija todas as questões do teste.';

    } else if (answerTest.beginDate && !answerTest.finishDate) {
      this.enableApproved = false;
    }
  }

  public leftClick() {
    const options = this.screens.length;
    if (this.startIndex === 0) {
      this.startIndex = options - 1;
      this.lastIndex--;
      this.screenOptions.unshift(this.screens[options - 1]);
      this.screenOptions.pop();
    } else if (this.lastIndex === 0) {
      this.lastIndex = options - 1;
      this.startIndex--;
      this.screenOptions.unshift(this.screens[this.startIndex]);
      this.screenOptions.pop();
    } else {
      this.startIndex--;
      this.lastIndex--;
      this.screenOptions.unshift(this.screens[this.startIndex]);
      this.screenOptions.pop();
    }
  }

  public rightClick() {
    const options = this.screens.length;
    if (this.lastIndex === (options - 1)) {
      this.lastIndex = 0;
      this.startIndex++;
      this.screenOptions.shift();
      this.screenOptions.push(this.screens[0]);
    } else if (this.startIndex === (options - 1)) {
      this.startIndex = 0;
      this.lastIndex++;
      this.screenOptions.shift();
      this.screenOptions.push(this.screens[this.lastIndex]);
    } else {
      this.startIndex++;
      this.lastIndex++;
      this.screenOptions.shift();
      this.screenOptions.push(this.screens[this.lastIndex]);
    }
  }

  public showScreen(screen) {
    this.selectedScreen = screen;
  }

  public saveGrade(event: number) {
    if (this.isSave) return;

    this.isSave = true;
    this.rating.nota = String(event);
    if (this.selectedScreen.scoreType === 'boolean') {
      this.rating.isCorrect = event === 10;
    }
    this.rating.observation = this.selectedScreen.observation || null;

    this.saveRating();
  }

  public saveObservation(event) {
    if (!event || !event.answers || !event.answers.length) return;

    const screenIndex = this.screens.findIndex(screen => String(screen._id) === String(this.selectedScreen._id));
    const answerIndex = event.answers.findIndex(answer => String(answer.activityId) === String(this.selectedScreen._id));
    if (screenIndex !== -1 && answerIndex !== -1) {
      this.screens[screenIndex].evaluations = event.answers[answerIndex].evaluations || [];
      this.screens[screenIndex].isCorrect = event.answers[answerIndex].isCorrect;
      this.screens[screenIndex].nota = Number(event.answers[answerIndex].nota);
      this.screens[screenIndex].observation = event.answers[answerIndex].observation || '';
      this.selectedScreen = this.screens[screenIndex];
    }

    this.candidateTestChange.emit(this.candidateTest);
    this.screensChange.emit(this.screens);
  }

  private saveRating() {

    this.rating.activityId = this.selectedScreen._id;
    if (this.test.rule === 'tests_with_weights' || this.test.rule === 'tests_with_weights_profiles') {
      this.rating.scoreType = this.selectedScreen.contentFormat === 'weightQuiz' || this.selectedScreen.contentFormat === 'weightProfileQuiz'
        ? 'number'
        : this.selectedScreen.scoreType;
    } else {
      this.rating.scoreType = this.selectedScreen.contentFormat === 'multipleQuiz'
        ? 'boolean'
        : this.selectedScreen.scoreType;
    }
    this.rating.vacancyTest = this.testId;

    this.testService.ratingQuestion(this.companyId, this.vacancyId, this.userId, this.stepId, this.testId, this.token, this.rating)
      .subscribe((res) => {
        if (res) {
          this.candidateTest = res;

          this.checkEnableApproved(this.candidateTest);

          const screenIndex = this.screens.findIndex(screen => String(screen._id) === String(this.selectedScreen._id));
          const answerIndex = res.answers.findIndex(answer => String(answer.activityId) === String(this.selectedScreen._id));
          if (screenIndex !== -1 && answerIndex !== -1) {
            this.screens[screenIndex].evaluations = res.answers[answerIndex].evaluations || [];
            this.screens[screenIndex].isCorrect = res.answers[answerIndex].isCorrect;
            this.screens[screenIndex].nota = Number(res.answers[answerIndex].nota);
            this.screens[screenIndex].observation = res.answers[answerIndex].observation || '';
            this.selectedScreen = this.screens[screenIndex];
          }

          this.candidateTestChange.emit(this.candidateTest);
          this.screensChange.emit(this.screens);
        }

        this.toastrService.clear();
        this.toastrService.success('A avaliação foi salva com sucesso', 'Informação salva!', {
          enableHtml: true,
        });
        this.isSave = false;
      }, (err) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
        this.isSave = false;
      });
  }

  public submitStatus(status: 'approved' | 'disapproved') {
    let modal = this.modalService.open(AlertChangesComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.title = 'Atenção!';
    if (this.test.rule === 'evaluative_tests') {
      modal.componentInstance.text = `O desempenho deste teste foi de ${this.candidateTest.testScore * 100}%. \n\n Uma vez que o teste tenha seu status alterado para ${status === 'approved' ? 'Aprovado' : 'Reprovar'}, não será possível reverter a ação. Deseja continuar com a ação?`;
    } else {
      modal.componentInstance.text = `Uma vez que o teste tenha seu status alterado para ${status === 'approved' ? 'Aprovado' : 'Reprovar'}, não será possível reverter a ação. Deseja continuar com a ação?`;
    }

    const data = {
      status,
      userManager: this.rating.userManager && this.rating.userManager._id ? this.rating.userManager._id : '',
    };

    modal.result.then(res => {
      if (res === true) {
        this.enableApproved = false;
        this.helpMessage = '';

        this.testService.saveTestStatus(this.companyId, this.vacancyId, this.userId, this.stepId, this.testId, this.token, data)
          .subscribe((test: any) => {
            let userManagerName, date;
            if (test.history) {
              userManagerName = test.history.userManagerName || '';
              date = test.history.date
                ? new Date(test.history.date).toLocaleDateString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' })
                : null;
              this.helpMessage = `Este teste já foi corrigido e ${test.status === 'approved' ? 'aprovado' : 'reprovado'} por ${userManagerName} na data ${date}.`;
            } else {
              this.helpMessage = `Este teste já foi corrigido e ${test.status === 'approved' ? 'aprovado' : 'reprovado'}.`;
            }

            this.candidateTest = test;
            this.candidateTestChange.emit(this.candidateTest);
          }, (error) => {
            this.toastrService.clear();
            this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
          });

      } else return;
    }).catch(err => { });
  }

  public generateCandidateDetailsLink(): any {
    const candidateLink = `${environment.url_web_v2}/${this.candidateTest.isEducationCourse ? 'courses' : 'vacancies'}/${this.vacancyId}/candidates/${this.candidateTest.vacancyCandidateId}`;
    window.open(candidateLink, '_blank');
  }

  public openHistoryModal() {
    let modal = this.modalService.open(EvaluationHistoryComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.evaluations = this.selectedScreen.evaluations;
  }

  public getUserFullName(user: any) {
    return this.userHelper.getUserFullName(user);
  }

}
