import { BaseTest } from "src/app/models/test.model";

type Screen = {
  _id: string;
  name: string;
  text: string;
  type: string;
  typeScreen: string;
};

export function getScreens(test: BaseTest, SCREENS_TYPES: any): Screen[] {
  test['activities'] = test?.activities || [];

  const screens = test.activities.map(activity => {
    let text = '';
    if (activity?.presentation?.text) {
      text = activity?.presentation?.text;
    } else if (activity?.content?.questions[0]?.text) {
      text = activity?.content?.questions[0]?.text;
    }

    let contentFormat: string = activity.contentFormat;

    if (test.rule === 'tests_with_weights' && activity.contentFormat === 'multipleQuiz') {
      contentFormat = 'weightQuiz';
    } else if (test.rule === 'tests_with_weights_profiles' && activity.contentFormat === 'multipleQuiz') {
      contentFormat = 'weightProfileQuiz';
    }

    return {
      _id: activity?._id || '',
      name: activity?.name || '',
      text,
      type: SCREENS_TYPES.find(type => type.value === contentFormat)?.name || 'Instrução',
      typeScreen: contentFormat || 'instruction'
    };
  });

  return screens;
}
