import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

  @Input() filters;
  @Input() type: string;

  @Output() filtersChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) { }

  ngOnInit() {
  }

  removeFilterBadge(param) {
    if (param === null) {
      this.filters = [];
      this.selectedFilter.emit(null);
    } else {
      const removedFilter = this.filters.splice(param, 1);
      this.selectedFilter.emit(removedFilter);
    }
    this.filtersChange.emit(this.filters);
  }

}
