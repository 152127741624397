import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';
import CONSTANTS from 'src/app/core/constants';
import { BaseTest } from 'src/app/models/test.model';
import { InfoComponent } from '../modal/info/info.component';
import { PublishTestComponent } from 'src/app/core/modal/publish-test/publish-test.component';
import { Router } from '@angular/router';
import { DeleteTestComponent } from '../modal/delete-test/delete-test.component';
import { CloneTestComponent } from '../modal/clone-test/clone-test.component';

@Component({
  selector: 'app-list-tests',
  templateUrl: './list-tests.component.html',
  styleUrls: ['./list-tests.component.scss']
})
export class ListTestsComponent implements OnInit {

  @Input() tests: BaseTest[];
  @Input() companyId: string;
  @Input() token: string;
  @Input() pagLog: any;
  @Input() loadTest: boolean;

  @Output() testsChange: EventEmitter<BaseTest[]> = new EventEmitter<BaseTest[]>();
  @Output() pagLogChange: EventEmitter<any> = new EventEmitter<any>();

  public TEST_TYPES = CONSTANTS.TEST_TYPES;
  public assets_path: string = environment.assets_path;
  public page: number = 1;

  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  public openPublishTestModal(test: BaseTest, index: number) {
    let modal = this.modalService.open(PublishTestComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.test = test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.tests[index].active = res.active;
        this.testsChange.emit(this.tests);
      }
    }).catch(err => { });
  }

  public openDeleteTestModal(test: BaseTest, index: number) {
    let modal = this.modalService.open(DeleteTestComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.test = test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.tests.splice(index, 1);
        if (!this.tests.length && this.page > 1) {
          this.pagLog.page--;
          this.pagLog.pagination.skip = this.pagLog.page * this.pagLog.pagination.limit;
        }
        this.pagLogChange.emit(this.pagLog);
      }
    }).catch(err => { });
  }

  public openTestInfoModal(test: BaseTest, index: number) {
    let modal = this.modalService.open(InfoComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.index = index;
    modal.componentInstance.test = test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.componentInstance.testChange.subscribe((res) => {
      if (res) {
        this.tests[index] = res;
        this.testsChange.emit(this.tests);
      }
    });
  }

  public editTest(test: BaseTest) {
    this.router.navigateByUrl(`companies/tests/${this.companyId}/edit-test/${test._id}?token=${this.token}`);
  }

  public openTestCloneModal(test: BaseTest) {
    let modal = this.modalService.open(CloneTestComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.test = test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;
  }

  public getPage(page) {
    this.pagLog.page = page;
    this.pagLog.pagination.skip = (page - 1) * this.pagLog.pagination.limit;
    this.pagLogChange.emit(this.pagLog);
  }

}
