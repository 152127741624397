<div class="container">
    <h1>TESTE SELECIONADO</h1>

    <div class="phone-body">
        <div class="phone-detail">
            <div class="rectangle"></div>
            <div class="circle"></div>
        </div>

        <div class="phone-content">
            <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
                <div class="flex-container">
                    <div class="tests-name" style="flex: 0 0 50px">
                        <input type="text" name="name" minlength="5" maxlength="20"
                            placeholder="Nome do Teste (min:5/ máx:20 caracteres)" formControlName="name">
                    </div>
                    <div [innerHtml]='msg' *ngIf="msg" style="color: red;" class="alert-msg"></div>
                    <div class="tests-image" style="flex: 0 0 250px">
                        <label for='selection-image' class="image-label">
                            <div>
                                <img [src]="url" *ngIf="url">
                            </div>
                        </label>

                        <input type="file" id="selection-image" accept="image/.jpg, .jpeg, .png"
                            (change)="selectFile($event)">
                    </div>
                    <div class="tests-description" style="flex: 0 0 150px">
                        <textarea name="description" minlength="10" maxlength="150"
                            placeholder="Descrição do Teste (min-caracters:10 / máx-caracters:150)"
                            formControlName="description">
                        </textarea>
                    </div>

                </div>

                <div class="btn-create-and-save" style="flex: 0 0 100px">
                    <button type="submit" class="btn btn-lg" [disabled]="!formulario.valid">
                        <mat-icon class="icon" [inlineSVG]="assets_path + 'assets/images/icons/green-op-check.svg'">
                        </mat-icon>
                        <span>Criar e Editar</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
