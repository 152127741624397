import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCalendar, faClipboard } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { CandidateCorrection } from 'src/app/models/candidate.model';
import { environment } from 'src/environments/environment';
import CONSTANTS from 'src/app/core/constants';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-candidate-test-list',
  templateUrl: './candidate-test-list.component.html',
  styleUrls: ['./candidate-test-list.component.scss']
})
export class CandidateTestListComponent implements OnInit {

  @Input() candidates: CandidateCorrection[];
  @Input() pagLog: any;
  @Input() loadCandidate: boolean;

  @Output() pagLogChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtersChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() candidate: EventEmitter<any> = new EventEmitter<any>();

  public faRegular = {
    calendar: faCalendar,
    clipboard: faClipboard
  };
  public faSolid = {
    check: faCheck,
    search: faMagnifyingGlass
  };
  public assets_path: string = environment.assets_path;
  public TEST_STATUS = CONSTANTS.TEST_STATUS;
  public page: number = 1;
  public form: FormGroup;
  public filterBadges: any = [];
  public selectUser: string = '';
  public statusTitle: string = '';

  private filters: any = {};

  get name() { return this.form.get('name'); }

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.setTitle();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      name: ['']
    });
  }

  private setTitle() {
    this.statusTitle = this.pagLog.filters && this.pagLog.filters.status
      ? 'Testes não avaliados'
      : 'Todos os testes';
  }

  private setBadge(filter) {
    if (!this.filterBadges.includes(filter)) {
      this.filterBadges.push(filter);
    }
  }

  private removeBadge(filter) {
    this.filterBadges = this.filterBadges.filter(badge => String(badge) !== String(filter));
  }

  public changeName(event: any) {
    this.name.patchValue(event.target.value.trim());
    if (this.name.value) {
      this.filters.name = this.name.value;
      this.setBadge('name');
    } else {
      delete this.filters.name;
      this.removeBadge('name');
    }
    this.filtersChange.emit(this.filters);
  }

  public removeFilter(filter) {
    if (filter == null) {
      this.form.reset();
      this.filters = {};
    } else {
      this.form.get(`${filter}`).patchValue('');
      delete this.filters[filter];
    }
    this.filtersChange.emit(this.filters);
  }

  public getPage(page) {
    this.pagLog.page = page;
    this.pagLog.pagination.skip = (page - 1) * this.pagLog.pagination.limit;
    this.pagLogChange.emit(this.pagLog);
  }

  public selectCandidate(candidate) {
    if (!candidate || !candidate.user) return;
    this.candidate.emit(candidate);
    this.selectUser = candidate.user;
  }

}
