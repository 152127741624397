<div class="custom-card card-test">

  <button *ngIf="test.canEdit" aria-label="Editar informações do teste"
    class="button-icon button-edit-info action-button" (click)="openGeneralInfoModal()"
    ngbTooltip="Editar informações do teste" tooltipClass="custom-tooltip">
    <div class="fill-blue" [inlineSVG]="assets_path + 'assets/images/icons/edit.svg'"></div>
  </button>

  <div class="card-test-img" [ngClass]="{'mt-img': !test.canEdit}">
    <img class="test-img" [src]="testImg || (assets_path + '/assets/images/capas_no_image.png')">
  </div>

  <h3 class="custom-card-header card-test-header">
    Teste - {{ (test.name.length > 20) ? (test.name | slice:0:20) +'...' : (test.name) }}
  </h3>

  <div class="custom-card-body">

    <h4 class="card-test-body-title">Descrição</h4>
    <p class="card-test-description" *ngIf="test.description">
      {{ (test.description.length > 150) ? (test.description | slice:0:150) +'...' : (test.description) }}
    </p>
    <p class="card-test-description" *ngIf="!test.description">
      Teste sem descrição
    </p>

    <div class="position-space">
      <h4 class="card-test-body-title">Publicar</h4>
      <button class="button-icon action-button" aria-label="Abrir modal para publicar teste"
        (click)="openPublishTestModal()">
        <div [ngClass]="{ 'fill-blue': test.active, 'fill-gray': !test.active }"
          [inlineSVG]="assets_path + 'assets/images/icons/blue-language.svg'"></div>
      </button>
    </div>

    <!-- <div class="position-space">
      <h4 class="card-test-body-title">Tornar padrão</h4>
      <button class="button-icon" aria-label="Abrir modal para tornar o teste padrão">
        <mat-icon class="color-blue">push_pin</mat-icon>
      </button>
    </div> -->

    <div class="position-space">
      <h4 class="card-test-body-title">Refazer teste</h4>
      <button class="button-icon action-button" aria-label="Abrir modal para configurar as regras de refazer teste"
        (click)="openConfigPeriodicAvailabilityModal()">
        <div class="fill-blue" [inlineSVG]="assets_path + 'assets/images/icons/update.svg'"></div>
      </button>
    </div>

    <!--  <div class="position-space">
        <h4 class="card-test-body-title">Subir planilha</h4>
        <button class="button-icon" aria-label="Abrir modal para subir planilha de uma tela">
          <mat-icon class="color-blue">upload_file</mat-icon>
        </button>
      </div> -->

  </div>

</div>

<div class="custom-card d-flex justify-content-center py-10" *ngIf="test.canEdit">
  <button mat-button class="card-order-header" aria-label="Abrir modal para reordenar as telas"
    (click)="openSortScreensModal()">
    <mat-icon class="color-blue mr-2">swap_vert</mat-icon>
    Reordenar telas
  </button>
</div>
