<div class="modal-body">
  <div class="center-object">
    <img class="icon" src="assets/images/icons/exclamation-mark.svg" alt="Atenção">
  </div>
  <div class="col-12 text-center">
    <h4>{{title}}</h4>
    <p>{{text}}</p>
  </div>
  <div class="center-object">
    <button mat-button class="btn btn-clean mx-2" (click)="close(false)">Não</button>
    <button mat-button class="btn btn-alert mx-2" (click)="close(true)">Sim</button>
  </div>
</div>
