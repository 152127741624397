import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { TestService } from 'src/app/core/services/test.service';
import { initUploader, formatBytes } from 'src/app/core/helpers';
import CONSTANTS from 'src/app/core/constants';
import { BaseTest } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-general-test-information',
  templateUrl: './edit-general-test-information.component.html',
  styleUrls: ['./edit-general-test-information.component.scss']
})
export class EditGeneralTestInformationComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() testImg: string;
  @Input() companyId: string;
  @Input() token: string;

  public ALLOWED_IMAGE = CONSTANTS.ALLOWED_IMAGE;
  public assets_path: string = environment.assets_path;
  public url: string = environment.url;
  public generalInfoForm: FormGroup;
  public uploader: FileUploader;
  public isSaveForm: boolean = false;
  public wrongFormate: boolean = false;
  public errorImage: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private testService: TestService,
    private toastrService: ToastrService,
  ) { }

  get name() { return this.generalInfoForm.get('name'); }
  get description() { return this.generalInfoForm.get('description'); }

  ngOnInit(): void {
    this.initForm();

    this.uploader = initUploader(
      `${this.url}/companies/vacancy-tests/${this.companyId}/${this.test._id}/upload/file/image`,
      this.token,
      this.ALLOWED_IMAGE.SIZE,
      this.ALLOWED_IMAGE.MIMETYPE
    );
    this.onWhenAddingFileFailedHandler(this.uploader, this.ALLOWED_IMAGE.SIZE, this.ALLOWED_IMAGE.MIMETYPE);
    this.afterAddingFileHandler(this.uploader);
  }

  private initForm() {
    this.generalInfoForm = this.formBuilder.group({
      name: [this.test?.name || '', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20)
      ]],
      description: [this.test?.description || '', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(150)
      ]],
      image: ['', Validators.nullValidator],
    });
  }

  private onWhenAddingFileFailedHandler(uploader: FileUploader, maxFileSize: number, allowedMimeType: string[]) {
    uploader.onWhenAddingFileFailed = (file: FileLikeObject, filter: any) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = `O arquivo tem ${formatBytes(file.size, 2)} e  ultrapassa o limite máximo de ${formatBytes(maxFileSize, 2)}`;
          break;
        case 'mimeType':
          message = `Escolha arquivos somente no formato ${allowedMimeType.join(', ').replace(/image\//g, '')}`;
          break;
        default:
          message = `Erro na subida do arquivo ${file.name}`;
          break;
      }

      this.errorImage = message;
      this.wrongFormate = true;
    };
  }

  private afterAddingFileHandler(uploader: FileUploader) {
    uploader.onAfterAddingFile = (file: FileItem) => {
      file.withCredentials = false;
      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent) => {
        const url = <string>reader.result;
        if (!this.wrongFormate) {
          this.testImg = url;
        }
      };

      reader.readAsDataURL(file._file);
    };
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  public changeName(event: any) {
    this.name.patchValue(event.target.value.trim());
  }

  public changeText(event: any) {
    this.description.patchValue(event.target.value.trim());
  }

  public changeImage() {
    this.testImg = '';
    this.errorImage = '';
    this.wrongFormate = false;
    this.uploader.queue = [];
  }

  public submitForm() {
    if (!this.generalInfoForm.valid) {
      return;
    }
    const name = this.generalInfoForm.get('name').value;
    const description = this.generalInfoForm.get('description').value;
    let data = { name, description };
    this.isSaveForm = true;

    if (this.uploader.queue[0]) {
      this.uploader.queue[0].upload();
      this.uploader.onErrorItem = (item: any, response: any) => {
        this.toastrService.clear();
        this.toastrService.error('Tivemos um problema na subida da imagem', 'Erro na atualização');
        this.isSaveForm = false;
      };
      this.uploader.onCompleteItem = (item: any, response: any) => {
        const res = JSON.parse(response);
        data['image'] = res.url;
        this.updateTest(data);
      };
      return;
    }

    this.updateTest(data);
  }

  private updateTest(data) {
    this.testService.updateTest(
      this.companyId,
      this.token,
      this.test._id,
      {
        vacancyTest: data
      }
    )
      .subscribe((res) => {
        let message = `O teste <b>${data.name}</b> foi atualizado com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });
        this.activeModal.close(res);
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
        this.isSaveForm = false;
      });
  }
}
