<h2 class="title mb-40">
  Teste - {{ (test.name.length > 20) ? (test.name | slice:0:20) +'...' : (test.name) }}
  | <span class="sub-title">{{ test.activities.length }} telas</span>
</h2>

<div class="group-screen-header mb-40">
  <h1 class="title">Atividade do candidato - {{ getUserFullName(candidateTest.user) }}</h1>

  <div *ngIf="finishedTest && test && test.rule !== 'content_screens'" class="position-col align-items-end">
    <div class="d-flex">
      <button class="btn btn-purple-blue" [disabled]="!enableApproved"
        (click)="submitStatus('disapproved')">Desaprovar</button>
      <button class="btn btn-green ml-16" [disabled]="!enableApproved"
        (click)="submitStatus('approved')">Aprovar</button>
    </div>
    <p class="text right w-500 mt-12" *ngIf="!enableApproved">{{helpMessage}}</p>
  </div>
</div>

<p *ngIf="!finishedTest" class="alert alert-warning">
  O teste não finalizado pelo candidato! Para seguir com a atividade de correção acione o candidato e solicite a
  conclusão. Qualquer dúvida, nosso suporte técnico está a disposição.
</p>

<ng-container *ngIf="finishedTest">
  <h2 class="group-screen-title upper mb-12">{{ selectedScreen?.name || '' }}</h2>

  <div class="row group-screen-navigate">
    <div *ngIf="activeSlide">
      <button mat-icon-button class="btn-arrow" aria-label="Navegar para os elementos anteriores" (click)="leftClick()">
        <fa-icon class="fill-blue" size="2xl" [icon]="faSolid.left"></fa-icon>
      </button>
    </div>
    <div *ngFor="let screen of screenOptions">
      <button mat-button class="btn-screen-option mx-4p" [ngClass]="{'active': selectedScreen.num === screen.num }"
        (click)="showScreen(screen)">
        {{screen.num}}
      </button>
    </div>
    <div *ngIf="activeSlide">
      <button mat-icon-button class="btn-arrow" aria-label="Navegar para os elementos seguintes" (click)="rightClick()">
        <fa-icon class="fill-blue" size="2xl" [icon]="faSolid.right"></fa-icon>
      </button>
    </div>
  </div>

  <div class="group-screen-content">
    <app-instruction [screen]="selectedScreen"></app-instruction>

    <app-answer *ngIf="selectedScreen.contentFormat && selectedScreen.answer" [screen]="selectedScreen"></app-answer>

    <app-correction *ngIf="selectedScreen.contentFormat && selectedScreen.scoreType" [screen]="selectedScreen"
      [test]="test" [candidateTest]="candidateTest" (gradeChange)="saveGrade($event)"
      (observationChange)="saveObservation($event)">
    </app-correction>

    <div class="row mb-24">
      <div class="col-2">
        <a class="link d-flex" (click)="generateCandidateDetailsLink()">
          <fa-icon size="xl" class="fill-blue mr-12" [icon]="faRegular.eye"></fa-icon>
          Ver CV
        </a>
      </div>
      <div class="col-4" *ngIf="selectedScreen.evaluations.length">
        <a class="link d-flex" (click)="openHistoryModal()">
          <fa-icon size="xl" class="fill-blue mr-12" [icon]="faRegular.clone"></fa-icon>
          Histórico de correção da tela
        </a>
      </div>
      <div class="col-5 offset-1" *ngIf="selectedScreen.evaluations.length">
        <p class="text right mr-20">
          Última correção feita por usuário
          {{ selectedScreen.evaluations[selectedScreen.evaluations.length - 1].userManager &&
          selectedScreen.evaluations[selectedScreen.evaluations.length - 1].userManager.firstName || '' }}
          {{ selectedScreen.evaluations[selectedScreen.evaluations.length - 1].userManager &&
          selectedScreen.evaluations[selectedScreen.evaluations.length - 1].userManager.lastName || ''}}
          no dia {{ selectedScreen.evaluations[selectedScreen.evaluations.length - 1].date | date: 'dd/MM/yyyy' }}
          às {{ selectedScreen.evaluations[selectedScreen.evaluations.length - 1].date | date: 'HH:mm:ss' }}.
        </p>
      </div>
    </div>
  </div>
</ng-container>
