import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TestService } from '../core/services/test.service';
import CONSTANTS from '../core/constants';
import { CandidateCorrection } from '../models/candidate.model';

@Component({
  selector: 'app-test-correction',
  templateUrl: './test-correction.component.html',
  styleUrls: ['./test-correction.component.scss']
})
export class TestCorrectionComponent implements OnInit {

  public test;
  public candidateTest;
  public load: boolean = true;
  public loadCandidate: boolean = true;
  public loadTest: boolean = false;
  public showTestList: boolean = true;
  public finishedTest: boolean = false;
  public screens = [];
  public candidates: CandidateCorrection[] = [];
  public pagLog: any = {
    page: 1,
    pagination: {
      skip: 0,
      limit: 20,
      total: 0
    },
    filters: {},
  };

  private SCREENS_TYPES = CONSTANTS.SCREEN_OPTIONS_DEFAULT;
  private companyId: string = '';
  private vacancyId: string = '';
  private userId: string = '';
  private stepId: string = '';
  private testId: string = '';
  private status: string = '';
  private token: string = '';

  constructor(
    private testService: TestService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.params.companyId;
    this.vacancyId = this.route.snapshot.params.vacancyId;
    this.userId = this.route.snapshot.params.userId;
    this.stepId = this.route.snapshot.params.stepId;
    this.testId = this.route.snapshot.params.testId;
    this.status = this.route.snapshot.queryParams.status;
    this.token = this.route.snapshot.queryParams.token;

    if (this.userId) {
      this.showTestList = false;
    }

    if (this.status === 'pending') {
      this.pagLog.filters['status'] = this.status;
    }

    this.getTest();
  }

  private getTest() {
    this.testService.showTest(this.companyId, this.token, this.testId)
      .subscribe((test: any) => {
        this.test = test;
        this.setScreenOptions();
        this.getActivities();
        if (this.showTestList) {
          this.getAllCandidate();
        } else {
          this.getCandidateTest(this.userId);
        }
      }, (err) => {
      });
  }

  private setScreenOptions() {
    if (String(this.test.rule) === 'tests_with_weights') {
      this.SCREENS_TYPES = CONSTANTS.WEIGHTS_SCREEN_OPTIONS;
    } else if (String(this.test.rule) === 'tests_with_weights_profiles') {
      this.SCREENS_TYPES = CONSTANTS.WEIGHTS_PROFILES_SCREEN_OPTIONS;
    }
  }

  private getActivities() {
    this.screens = this.test.activities.map((screen, index) => {
      const activity = {
        _id: screen._id,
        name: screen.name,
        num: index + 1,
        text: null,
        image: null,
        video: null,
        contentFormat: null,
        contentFormatName: null,
        alternatives: [],
        alternativesType: null,
        correctAnswerLetter: null,
        correctAnswerOption: null,
        scoreType: null,
        scoreTypeName: null,
        answer: null,
        isCorrect: false,
        nota: -1,
        profileName: null,
        evaluations: [],
        observation: null,
      };

      if (screen.presentation) {
        activity.text = screen.presentation.text || null;
        activity.image = screen.presentation.image || null;
        activity.video = screen.presentation.video || null;
      } else if (screen.content && screen.content.questions && screen.content.questions.length) {
        activity.text = screen.content.questions[0].text || null;
        activity.image = screen.content.questions[0].image || null;
        activity.video = screen.content.questions[0].video || null;
        activity.alternatives = screen.content.questions[0].alternatives || [];
        activity.alternativesType = screen.content.questions[0].alternativesType || null;

        if (this.test.rule === 'tests_with_weights') {
          activity.contentFormatName = 'Quiz com pesos';
          activity.contentFormat = 'weightQuiz';
          activity.scoreType = 'weight';
        } else if (this.test.rule === 'tests_with_weights_profiles') {
          activity.contentFormatName = 'Quiz com pesos e atributos';
          activity.contentFormat = 'weightProfileQuiz';
          activity.scoreType = 'weight';
        } else {
          activity.contentFormat = screen.contentFormat;
          activity.contentFormatName = this.SCREENS_TYPES.find(type => type.value === screen.contentFormat).name || null;
          activity.scoreType = screen.contentFormat === 'multipleQuiz'
            ? 'system'
            : screen.scoreType;
        }

        if (activity.alternatives.length) {
          const correctAnswer = activity.alternatives.find(alternative => alternative.isCorrect);
          if (correctAnswer) {
            activity.correctAnswerLetter = correctAnswer.letter || null;
            activity.correctAnswerOption = correctAnswer.option || null;
          }
        }

        if (activity.scoreType === 'boolean') {
          activity.scoreTypeName = 'Avaliação certo ou errado';
        } else if (activity.scoreType === 'number') {
          activity.scoreTypeName = 'Avaliação de 0 a 10';
        } else if (activity.scoreType === 'weight') {
          activity.scoreTypeName = 'Avaliação por pontuação';
        } else if (activity.scoreType === 'system') {
          activity.scoreTypeName = 'Avaliação automática';
        }
      }

      return activity;
    });
  }

  public getAllCandidate(event?) {
    const data = {
      skip: this.pagLog.pagination.skip,
      limit: this.pagLog.pagination.limit,
    };
    if (event && event.name) {
      this.pagLog.filters = event;
      data['search'] = this.pagLog.filters.name;
    }
    if (this.pagLog.filters && this.pagLog.filters.status) {
      data['status'] = this.pagLog.filters.status;
    }
    this.loadCandidate = true;

    this.testService.getVacancyCandidates(
      this.companyId,
      this.vacancyId,
      this.testId,
      this.token,
      data
    ).subscribe((res: any) => {
      this.candidates = res.data;
      this.pagLog.pagination = res.pagination;
      this.loadCandidate = false;
      this.load = false;
    }, (err) => {
    });
  }

  private getCandidateTest(userId: string) {
    this.testService.getTestCandidate(
      this.companyId,
      this.vacancyId,
      userId,
      this.stepId,
      this.testId,
      this.token
    ).subscribe((res: any) => {
      this.candidateTest = res;
      if (this.candidateTest.finishDate && this.candidateTest.answers && this.candidateTest.answers.length) {
        this.finishedTest = true;
        this.getAnswers();
      }
      this.load = false;
      this.loadTest = false;
    }, (err) => {
    });
  }

  private getAnswers() {
    for (const screen of this.screens) {
      const candidateAnswer = this.candidateTest.answers
        .find(answer => String(answer.activityId) === String(screen._id));

      if (!candidateAnswer) continue;

      if (screen.contentFormat) {
        screen.answer = candidateAnswer.answer;
        screen.isCorrect = candidateAnswer.isCorrect;
        screen.nota = Number(candidateAnswer.nota);
        screen.evaluations = candidateAnswer.evaluations || [];
        screen.observation = candidateAnswer.observation || '';

        if (screen.contentFormat === 'weightProfileQuiz' && candidateAnswer.profileId) {
          const profile = this.test.profiles
            .find(profile => String(profile._id) === String(candidateAnswer.profileId));

          if (profile) {
            screen.profileName = profile.name;
          }
        }
      }
    }
  }

  public changeCandidateTest(candidate) {
    if (candidate && candidate.user) {
      this.loadTest = true;
      this.getCandidateTest(candidate.user);
    }
  }

}
