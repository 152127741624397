import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-score-type',
  templateUrl: './score-type.component.html',
  styleUrls: ['./score-type.component.scss']
})
export class ScoreTypeComponent implements OnInit {

  @Input() score: FormGroup;
  @Input() screen;

  public assets_path: string = environment.assets_path;

  constructor() { }

  get type() { return this.score.get('type'); }

  ngOnInit(): void {
    this.setValidation();
    this.fillForm();
  }

  private setValidation() {
    this.score.get('type').setValidators([Validators.required]);
    this.score.get('type').updateValueAndValidity();
  }

  private fillForm() {
    if (this.screen) {
      this.score.get('type').patchValue(this.screen.scoreType || '');
      this.score.get('type').updateValueAndValidity();
    }
  }

}
