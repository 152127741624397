import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ActivityBase, Alternative } from '../../models/test.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-mobile-screen-test',
    templateUrl: './mobile-screen-test.component.html',
    styleUrls: ['./mobile-screen-test.component.scss']
})
export class MobileScreenTestComponent implements OnInit, OnChanges {
    public assets_path: string = environment.assets_path;

    @Input() clearScreen: boolean = null;
    @Input() screen: ActivityBase = null;
    @Input() lockScreen: boolean = null;

    @Output() selectQuestion = new EventEmitter();
    @Output() selectComponent = new EventEmitter();
    @Output() activeCreateScreen = new EventEmitter();

    /** Salva os valores dos campos */
    @Output() screenNameValue = new EventEmitter();
    @Output() questionTextValue = new EventEmitter();
    @Output() questionImageValue = new EventEmitter();
    @Output() questionVideoValue = new EventEmitter();
    @Output() answerTextValue = new EventEmitter();
    @Output() answerVideoValue = new EventEmitter();
    @Output() answerAudioValue = new EventEmitter();
    @Output() answerMultipleQuizValue = new EventEmitter();

    canEdit: boolean = true;
    screenName: string = '' as string;
    questionText: string = null;
    questionImage = null;
    questionVideo = null;
    answerTextMin: number = 1 as number;
    answerTextMax: number = null;
    answerVideo: number = 1 as number;
    answerAudio: number = 1 as number;
    answerQuiz: Alternative[] = [{ letter: 'A', option: '', isCorrect: false }];

    /** Habilita / Desabilita os componentes no mobile screen */
    textQuestionComponent: boolean = true as boolean;
    imageQuestionComponent: boolean = true as boolean;
    videoQuestionComponent: boolean = true as boolean;
    textAnswerComponent: boolean = true as boolean;
    videoAnswerComponent: boolean = true as boolean;
    audioAnswerComponent: boolean = true as boolean;
    quiz1AnswerComponent: boolean = true as boolean;
    quiz1Add: boolean = false as boolean;

    // Desabilitar o campo de seleção de componentes
    fieldSelectComponent: boolean = false as boolean;

    /** Salva os componentes selecionados */
    components = [];

    /** Auxiliares */
    items;
    alternativeIndex: number = 0 as number;
    alternativeLetter: string = 'B' as string;
    urlImage;
    msgImage: string = '' as string;
    urlVideo;
    msgVideo: string = '' as string;
    msgDurationVideo: string = '' as string;
    msgDurationAudio: string = '' as string;
    msgTextMin: string = '' as string;
    msgTextMax: string = '' as string;
    msgQuiz1: string = '' as string;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['lockScreen'] !== undefined) {
          // permite a edição
          this.canEdit = changes.lockScreen.currentValue;
        }

        if (changes['clearScreen'] !== undefined) {
            // Limpar tela
            if (changes.clearScreen.currentValue) {
                this.clearMobileScreen();
            }
        }

        if (changes['screen'] !== undefined) {
            // Exibir a tela com os valores preenchidos
            this.showScreen(changes.screen.currentValue);
        }
    }

    /** Limpar tela */
    clearMobileScreen(): void {
        this.textQuestionComponent = true;
        this.imageQuestionComponent = true;
        this.videoQuestionComponent = true;
        this.textAnswerComponent = true;
        this.videoAnswerComponent = true;
        this.audioAnswerComponent = true;
        this.quiz1AnswerComponent = true;
        this.quiz1Add = false;
        this.fieldSelectComponent = false;

        // Zerar os valores
        this.screenName = '';
        this.questionText = null;
        this.questionImage = null;
        this.questionVideo = null;
        this.answerTextMin = 1;
        this.answerTextMax = null;
        this.answerVideo = 1;
        this.answerAudio = 1;
        this.answerQuiz = [{ id: 0, letter: 'A', option: '', isCorrect: false }];
        this.components = [];
        this.items = null;
        this.alternativeIndex = 0;
        this.alternativeLetter = 'B';
        this.urlImage = '';
        this.msgImage = '';
        this.urlVideo = '';
        this.msgVideo = '';
        this.msgDurationVideo = '';
        this.msgDurationAudio = '';
        this.msgTextMin = '';
        this.msgTextMax = '';
        this.msgQuiz1 = '';
    }

    /** Exibir tela selecionada */
    showScreen(screen: ActivityBase): void {
        // Limpar tela
        this.clearMobileScreen();

        // Popular valores
        this.screenName = screen?.name;
        this.screenNameValue.emit(this.screenName);

        if (screen?.presentation) {
            this.showScreenQuestionsComponents(screen);
        } else {
            if (screen?.contentFormat === 'text') {

                this.showScreenQuestionsComponents(screen);

                // Exibir o componente Resposta com Texto
                this.textAnswerComponent = false;
                this.answerTextMin = screen?.content?.questions[0]?.min;
                this.answerTextMax = screen?.content?.questions[0]?.max;
                this.answerTextValue.emit({ min: this.answerTextMin, max: this.answerTextMax });

                this.components.push({ id: 'answer-text', value: 'Texto', icon: 'text-format.svg', disabled: false });

            }

            if (screen?.contentFormat === 'video') {

                this.showScreenQuestionsComponents(screen);

                // Exibir o componente Resposta com Texto
                this.videoAnswerComponent = false;

                this.answerVideo = screen?.content?.questions[0]?.maxDuration / 60;
                this.answerVideoValue.emit({ maxDuration: screen?.content?.questions[0]?.maxDuration });

                this.components.push({ id: 'answer-video', value: 'Vídeo', icon: 'video.svg', disabled: false });

            }

            if (screen?.contentFormat === 'audio') {

                this.showScreenQuestionsComponents(screen);

                // Exibir o componente Resposta com Texto
                this.audioAnswerComponent = false;

                this.answerAudio = screen?.content?.questions[0]?.maxDuration / 60;
                this.answerAudioValue.emit({ maxDuration: screen?.content?.questions[0]?.maxDuration });

                this.components.push({ id:'answer-audio', value: 'Áudio', icon: 'microphone.svg', disabled: false });

            }

            if (screen?.contentFormat === 'multipleQuiz' && screen?.content?.questions[0]?.alternativesType === 'text') {

                this.showScreenQuestionsComponents(screen);

                // Exibir as alternativas
                this.quiz1AnswerComponent = false;

                this.answerQuiz = screen?.content?.questions[0]?.alternatives;
                this.answerMultipleQuizValue.emit({ alternatives: this.answerQuiz });

                this.alternativeIndex = this.answerQuiz.length;
                this.alternativeLetter = String.fromCharCode(this.answerQuiz[this.answerQuiz.length - 1].letter.charCodeAt(0) + 1);

                this.components.push({ id: 'answer-quiz1', value: 'Quiz', icon: 'quiz-1.svg', disabled: false });
            }
        }
    }

    showScreenQuestionsComponents(screen: ActivityBase): void {
        if (screen?.presentation?.text) {

            // Exibir o componente Texto com conteúdo
            this.textQuestionComponent = false;
            this.questionText = screen?.presentation?.text;
            this.questionTextValue.emit(this.questionText);
            this.components.push({ id: 'question-text', value: 'Texto', icon: 'text-format.svg', disabled: true });

            // Impedir a escolha do componente Texto
            this.selectComponent.emit({ id: 'question-text', disabled: true });

            // Habilitar componentes de respostas
            this.selectQuestion.emit({ enabledAnswer: true });

            // Habilitar o botão de criar nova tela
            this.activeCreateScreen.emit({ disabled: false });
        }

        if (screen?.presentation?.image) {

            // Exibir o componente Texto com conteúdo
            this.imageQuestionComponent = false;
            this.questionImage = screen?.presentation?.image;
            this.questionImageValue.emit(this.questionImage);

            // Preview da imagem
            this.msgImage = '';
            this.urlImage = this.questionImage;

            // var reader = new FileReader();
            // reader.readAsDataURL(this.questionImage);

            // reader.onload = (_event) => {
            //   this.msgImage = '';
            //   this.urlImage = reader.result;
            // }

            this.components.push({ id: 'question-image', value: 'Imagem', icon: 'image.svg', disabled: true });

            // Impedir a escolha do componente Texto
            this.selectComponent.emit({ id: 'question-image', disabled: true });

            // Habilitar componentes de respostas
            this.selectQuestion.emit({ enabledAnswer: true });

            // Habilitar o botão de criar nova tela
            this.activeCreateScreen.emit({ disabled: false });
        }

        if (screen?.presentation?.video) {
            // Exibir o componente Texto com conteúdo
            this.videoQuestionComponent = false;
            this.questionVideo = screen?.presentation?.video;
            this.questionVideoValue.emit(this.questionVideo);

            // Preview da imagem
            this.msgVideo = '';
            this.urlVideo = this.questionVideo;

            // var reader = new FileReader();
            // reader.readAsDataURL(this.questionVideo);

            // reader.onload = (_event) => {
            //   this.msgVideo = '';
            //   this.urlVideo = reader.result;
            // }

            this.components.push({ id: 'question-video', value: 'Vídeo', icon: 'video.svg', disabled: true });

            // Impedir a escolha do componente Texto
            this.selectComponent.emit({ id: 'question-video', disabled: true });

            // Habilitar componentes de respostas
            this.selectQuestion.emit({ enabledAnswer: true });

            // Habilitar o botão de criar nova tela
            this.activeCreateScreen.emit({ disabled: false });
        }

        if (screen?.content?.questions[0]?.text) {

            // Exibir o componente Texto com conteúdo
            this.textQuestionComponent = false;
            this.questionText = screen?.content?.questions[0]?.text;
            this.questionTextValue.emit(this.questionText);
            this.components.push({ id: 'question-text', value: 'Texto', icon: 'text-format.svg', disabled: false });

            // Impedir a escolha do componente Texto
            this.selectComponent.emit({ id: 'question-text', disabled: true });

            // Desabilitar componentes de respostas
            this.selectQuestion.emit({ enabledAnswer: false });

            // Habilitar o botão de criar nova tela
            this.activeCreateScreen.emit({ disabled: false });
        }

        if (screen?.content?.questions[0]?.image) {

            // Exibir o componente Texto com conteúdo
            this.imageQuestionComponent = false;
            this.questionImage = screen?.content?.questions[0]?.image;
            this.questionImageValue.emit(this.questionImage);

            // Preview da imagem
            this.msgImage = '';
            this.urlImage = this.questionImage;

            // var reader = new FileReader();
            // reader.readAsDataURL(this.questionImage);

            // reader.onload = (_event) => {
            //   this.msgImage = '';
            //   this.urlImage = reader.result;
            // }

            this.components.push({ id: 'question-image', value: 'Imagem', icon: 'image.svg', disabled: true });

            // Impedir a escolha do componente Texto
            this.selectComponent.emit({ id: 'question-image', disabled: true });

            // Desabilitar componentes de respostas
            this.selectQuestion.emit({ enabledAnswer: false });

            // Habilitar o botão de criar nova tela
            this.activeCreateScreen.emit({ disabled: false });
        }

        if (screen?.content?.questions[0]?.video) {
            // Exibir o componente Texto com conteúdo
            this.videoQuestionComponent = false;
            this.questionVideo = screen?.content?.questions[0]?.video;
            this.questionVideoValue.emit(this.questionVideo);

            // Preview do video
            this.msgVideo = '';
            this.urlVideo = this.questionVideo;

            // var reader = new FileReader();
            // reader.readAsDataURL(this.questionVideo);

            // reader.onload = (_event) => {
            //   this.msgVideo = '';
            //   this.urlVideo = reader.result;
            // }

            this.components.push({ id: 'question-video', value: 'Vídeo', icon: 'video.svg', disabled: true });

            // Impedir a escolha do componente Texto
            this.selectComponent.emit({ id: 'question-video', disabled: true });

            // Desabilitar componentes de respostas
            this.selectQuestion.emit({ enabledAnswer: false });

            // Habilitar o botão de criar nova tela
            this.activeCreateScreen.emit({ disabled: false });
        }
    }

    /** Ação drag and drop (cdk) */
    drop(event: CdkDragDrop<string[]>): void {
        const index: number = event.previousIndex as number;
        const container = event.previousContainer.data;
        let isAnswer = false;

        // Salvar o componente usado
        this.items = container[index];
        this.components.push(this.items);

        // Habilitar os campos
        this.showComponentInMobile(this.items);

        // Envia dados do o componente selecionado para o componente pai >> disabled
        this.selectComponent.emit({ id: this.items.id, disabled: true });

        // Libera o uso dos componentes de respostas
        if (!this.textQuestionComponent || !this.imageQuestionComponent || !this.videoQuestionComponent) {
            if (!this.textAnswerComponent || !this.videoAnswerComponent || !this.audioAnswerComponent || !this.quiz1AnswerComponent) {
                isAnswer = true;
            }

            isAnswer ? this.selectQuestion.emit({ enabledAnswer: false }) : this.selectQuestion.emit({ enabledAnswer: true });
        }
    }

    /** Exibir os componentes no celular */
    showComponentInMobile(items): void {

        // Limita em 3 componentes
        if (this.components.length === 3) {
            this.fieldSelectComponent = true;
        }

        if (items.id === 'question-text') {
            this.textQuestionComponent = false;
            this.activeCreateScreen.emit({ disabled: true });
        }

        if (items.id === 'question-image') {
            this.imageQuestionComponent = false;
            this.activeCreateScreen.emit({ disabled: true });
        }

        if (items.id === 'question-video') {
            this.videoQuestionComponent = false;
            this.activeCreateScreen.emit({ disabled: true });
        }

        if (items.id === 'answer-text') {
            this.textAnswerComponent = false;
            this.answerTextValue.emit({ min: 1, max: null });
        }

        if (items.id === 'answer-video') {
            this.videoAnswerComponent = false;
            this.answerVideoValue.emit({ maxDuration: 60 });
        }

        if (items.id === 'answer-audio') {
            this.audioAnswerComponent = false;
            this.answerAudioValue.emit({ maxDuration: 60 });
        }

        if (items.id === 'answer-quiz1') {
            this.quiz1AnswerComponent = false;
            this.answerMultipleQuizValue.emit({ alternatives: this.answerQuiz });
            this.activeCreateScreen.emit({ disabled: true });
        }
    }

    /** Remover componente */
    removeComponent(value): void {

        // Habilita uma nova seleção do componente excluído
        this.selectComponent.emit({ id: value, disabled: false });

        this.fieldSelectComponent = false;

        if (value === 'question-text') {
            this.textQuestionComponent = true;
            this.questionText = null;
            this.questionTextValue.emit(this.questionText);

            if (this.screenName && (this.questionImage || this.questionVideo)) {
                this.activeCreateScreen.emit({ disabled: false });
            } else {
                this.activeCreateScreen.emit({ disabled: true });
            }
        }

        if (value === 'question-image') {
            this.imageQuestionComponent = true;
            this.msgImage = '';
            this.urlImage = '';
            this.questionImage = null;
            this.questionImageValue.emit(this.questionImage);

            if (this.screenName && (this.questionText || this.questionVideo)) {
                this.activeCreateScreen.emit({ disabled: false });
            } else {
                this.activeCreateScreen.emit({ disabled: true });
            }
        }

        if (value === 'question-video') {
            this.videoQuestionComponent = true;
            this.msgVideo = '';
            this.urlVideo = '';
            this.questionVideo = null;
            this.questionVideoValue.emit(this.questionVideo);

            if (this.screenName && (this.questionText || this.questionImage)) {
                this.activeCreateScreen.emit({ disabled: false });
            } else {
                this.activeCreateScreen.emit({ active: true });
            }
        }

        if (value === 'answer-text') {
            this.textAnswerComponent = true;
            this.answerTextMin = 1;
            this.answerTextMax = null;
            this.answerTextValue.emit(null);
        }

        if (value === 'answer-video') {
            this.videoAnswerComponent = true;
            this.answerVideo = 1;
            const maxDuration = this.answerVideo * 60;
            this.answerVideoValue.emit(null);
        }

        if (value === 'answer-audio') {
            this.audioAnswerComponent = true;
            this.answerAudio = 1;
            const maxDuration = this.answerVideo * 60;
            this.answerVideoValue.emit(null);
        }

        this.validationArrayComponent(value);
    }

    /** Validação do array component no ato da remoção */
    validationArrayComponent(value): void {
        let isQuestion = false;

        // Retira o componente selecionado do array
        this.components = this.components.filter((component) => component.id !== value);

        // Verifica se usou componentes de questões
        if (!this.textQuestionComponent || !this.imageQuestionComponent || !this.videoQuestionComponent) {
            isQuestion = true;
        }

        if (!isQuestion) {
            this.selectQuestion.emit({ enabledAnswer: false });
            this.activeCreateScreen.emit({ disabled: true });
        }
    }

    /** Aidicionar alternativas do quiz */
    addAlternatives(): void {
        if (this.answerQuiz.length === 9) {
            this.quiz1Add = true;
        }

        this.msgQuiz1 = '';

        this.answerQuiz.push({ letter: this.alternativeLetter, option: '', isCorrect: false });
        this.answerMultipleQuizValue.emit({ alternatives: this.answerQuiz });

        this.alternativeIndex = this.answerQuiz.length;

        this.alternativeLetter = String.fromCharCode(this.alternativeLetter.charCodeAt(0) + 1);

        this.activeCreateScreen.emit({ disabled: true });
    }

    /** Troca do valor de option */
    changeOptionValue(event): void {
        this.msgQuiz1 = '';

        this.alternativeIndex = event.index;

        this.answerQuiz[this.alternativeIndex].option = event.option;

        this.answerMultipleQuizValue.emit({ alternatives: this.answerQuiz });

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    /** Troca do valor de isCorrect */
    changeIsCorrect(event: any): void {
        this.msgQuiz1 = '';

        this.alternativeIndex = event.index;

        // Etapa: Reseta todas as alternativas da questão.
        this.answerQuiz.forEach((answer: any) => {
          answer.isCorrect = false;
        });

        this.answerQuiz[this.alternativeIndex].isCorrect = event.isCorrect;

        this.answerMultipleQuizValue.emit({ alternatives: this.answerQuiz });

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    /** Remover alternativa */
    removeAlternative(event): void {
        this.answerQuiz.splice(event.index, 1);

        this.answerMultipleQuizValue.emit({ alternatives: this.answerQuiz });

        // Ao excluir todas as opções
        if (this.answerQuiz.length === 0) {
            let isQuestion = false;
            this.alternativeIndex = 0;
            this.alternativeLetter = 'B';
            this.answerQuiz = [{ id: 0, letter: 'A', option: '', isCorrect: false }];
            this.answerMultipleQuizValue.emit({ alternatives: null });

            this.quiz1AnswerComponent = true; // Ocultar o campo de quiz no mobile

            this.checkValueComponentsInUse(); // Ativação do botão de criar nova tela

            this.selectComponent.emit({ id: 'answer-quiz1', disabled: false }); // Disponibilza a seleção do componente quiz

            this.fieldSelectComponent = false; // Liberação do campo de seleção de componentes

            this.components = this.components.filter((component) => component.id !== 'answer-quiz1'); // Retira do array component

            // Verifica se usou componentes de questões
            if (!this.textQuestionComponent || !this.imageQuestionComponent || !this.videoQuestionComponent) {
                isQuestion = true;
            }

            if (!isQuestion) {
                this.selectQuestion.emit({ enabledAnswer: false });
                this.activeCreateScreen.emit({ disabled: true });
            }

            return;
        }

        // Correção da letra das alternativas
        const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        let count = 0;

        for (const item of this.answerQuiz) {
            item.letter = alphabet[count];
            count++;
        }

        this.alternativeLetter = alphabet[count];

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();

        // Libera o botão de inclusão de alternativas
        this.quiz1Add = false;
    }

    /** CAMPOS - Validação */
    checkValueComponentsInUse(): void {
        let active: boolean = false as boolean;

        if (this.screenName && (this.questionText || this.questionImage || this.questionVideo)) {
            active = true;
        } else {
            this.activeCreateScreen.emit({ disabled: true });
            return;
        }

        if (!this.textQuestionComponent) {
            if (this.screenName && this.questionText) {
                active = true;
            } else {
                this.activeCreateScreen.emit({ disabled: true });
                return;
            }
        }

        if (!this.imageQuestionComponent) {
            if (this.screenName && this.questionImage) {
                active = true;
            } else {
                this.activeCreateScreen.emit({ disabled: true });
                return;
            }
        }

        if (!this.videoQuestionComponent) {
            if (this.screenName && this.questionVideo) {
                active = true;
            } else {
                this.activeCreateScreen.emit({ disabled: true });
                return;
            }
        }

        if (!this.textAnswerComponent) {
            if (this.answerTextMin) {
                active = true;
            } else {
                this.activeCreateScreen.emit({ disabled: true });
                return;
            }
        }

        if (!this.videoAnswerComponent) {
            if (this.answerVideo) {
                active = true;
            } else {
                this.activeCreateScreen.emit({ disabled: true });
                return;
            }
        }

        if (!this.audioAnswerComponent) {
            if (this.answerAudio) {
                active = true;
            } else {
                this.activeCreateScreen.emit({ disabled: true });
                return;
            }
        }

        if (!this.quiz1AnswerComponent) {
            let isNotEmptyOption: boolean = true as boolean;
            let countIsCorrect: number = 0 as number;

            // Validação: Apenas uma alternativa correta
            for (const item of this.answerQuiz) {
                if (item.isCorrect) {
                    countIsCorrect++;
                }
            }

            if (countIsCorrect !== 1) {
                this.activeCreateScreen.emit({ disabled: true });

                countIsCorrect > 1 ? this.msgQuiz1 = 'Escolha apenas uma alternativa como correta' : this.msgQuiz1 = '';
                return;
            }

            // Verifica se todas as alternativas estão preenchidas
            for (const item of this.answerQuiz) {
                if (!item.option) {
                    isNotEmptyOption = false;
                }
            }

            !isNotEmptyOption ? this.msgQuiz1 = 'Preencha todas as alternativas' : this.msgQuiz1 = '';

            if (isNotEmptyOption && this.answerQuiz.length > 1) {
                active = true;
            } else {
                this.activeCreateScreen.emit({ disabled: true });
                return;
            }
        }

        if (active) {
            this.activeCreateScreen.emit({ disabled: false });
            return;
        }
    }

    /** CAMPO - Nome da tela */
    changeScreenName(event): void {
        this.screenName = event.trim();
        this.screenNameValue.emit(this.screenName);

        if (!this.screenName) {
            this.activeCreateScreen.emit({ disabled: true });
            return;
        }

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    /** CAMPO QUESTÕES - Texto */
    changeQuestionText(event): void {
        this.questionText = event.trim();
        this.questionTextValue.emit(event);

        if (!this.questionText) {
            this.activeCreateScreen.emit({ disabled: true });
            return;
        }

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    /** CAMPO QUESTÕES - Imagem */
    selectImageFile(event): void {
        const droppedFile: File = event.target.files[0];

        // Verifica se foi selecionado a imagem
        if (!droppedFile) {
            this.msgImage = 'Selecione uma imagem';
            this.urlImage = '';
            this.questionImage = null;
            this.questionImageValue.emit(this.questionImage);
            this.activeCreateScreen.emit({ disabled: true });
            return;
        }

        const size = droppedFile.size / 1024 / 1024 / 1024;

        // Verifica o tipo do arquivo selecionado
        if (droppedFile.type.match('image/png|image/jpg|image/jpeg|image/svg') == null || size > 1) {
            this.msgImage = 'Selecione uma imagem no formato PNG, JPG, JPEG ou SVG';
            this.urlImage = '';
            this.questionImage = null;
            this.questionImageValue.emit(this.questionImage);
            this.activeCreateScreen.emit({ disabled: true });
            return;
        }

        // Preview da imagem
        const reader = new FileReader();
        reader.readAsDataURL(droppedFile);

        // tslint:disable-next-line: variable-name
        reader.onload = (_event) => {
            this.msgImage = '';
            this.urlImage = reader.result;
        };

        // tslint:disable-next-line: no-unused-expression
        droppedFile instanceof Blob;

        // Salvar o campo
        this.questionImage = droppedFile;
        this.questionImageValue.emit(this.questionImage);

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    /** CAMPO QUESTÕES - Video */
    selectVideoFile(event): void {
        const droppedFile: File = event.target.files[0];

        // Verifica se foi selecionado um vídeo
        if (!droppedFile) {
            this.msgVideo = 'Selecione um vídeo';
            this.urlVideo = '';
            this.questionVideo = null;
            this.questionVideoValue.emit(this.questionVideo);
            this.activeCreateScreen.emit({ disabled: true });
            return;
        }

        const size = droppedFile.size / 1024 / 1024 / 1024;

        // Verifica o formato e tamanho do vídeo
        if (droppedFile.type.match('video/mp4') == null || size > 1) {
            this.msgVideo = 'Selecione um vídeo no formato MP4 e menor que 1GB de tamanho';
            this.urlVideo = '';
            this.questionVideo = null;
            this.questionVideoValue.emit(this.questionVideo);
            this.activeCreateScreen.emit({ disabled: true });
            return;
        }

        // Preview do video
        const reader = new FileReader();
        reader.readAsDataURL(droppedFile);

        // tslint:disable-next-line: variable-name
        reader.onload = (_event) => {
            this.msgVideo = '';
            this.urlVideo = reader.result;
        };

        // tslint:disable-next-line: no-unused-expression
        droppedFile instanceof Blob;

        // Salvar o campo
        this.questionVideo = droppedFile;
        this.questionVideoValue.emit(this.questionVideo);

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    /** CAMPO RESPOSTAS - Texto */
    changeAnswerTextMin(event): void {
        if (event < 1) {
            this.msgTextMin = 'O tamanho mínimo de caracteres deve ser maior que 1';
            this.answerTextMin = event;
            this.answerTextValue.emit({ min: this.answerTextMin, max: this.answerTextMax });
            this.activeCreateScreen.emit({ disabled: true });

            return;
        }

        if (this.answerTextMax && event > this.answerTextMax) {
            this.activeCreateScreen.emit({ disabled: true });
            this.msgTextMin = 'A quantidade de caracteres mínimo deve ser menor que o de caracteres máximo';

            return;
        }

        this.msgTextMin = '';
        this.answerTextMin = event;

        this.answerTextValue.emit({ min: this.answerTextMin, max: this.answerTextMax });

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    changeAnswerTextMax(event): void {
        if (!event) {
            this.msgTextMax = '';
            this.answerTextValue.emit({ min: this.answerTextMin, max: null });
            this.activeCreateScreen.emit({ disabled: false });

            return;
        }

        if (event < this.answerTextMin) {
            this.activeCreateScreen.emit({ disabled: true });
            this.msgTextMax = 'A quantidade de caracteres máximo deve ser maior que o de caracteres mínimo';

            return;
        }

        this.msgTextMax = '';
        this.answerTextMax = event;

        this.answerTextValue.emit({ min: this.answerTextMin, max: this.answerTextMax });

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    /** CAMPO RESPOSTAS - Video */
    changeAnswerVideo(event): void {
        if (event < 1) {
            this.msgDurationVideo = 'A duração do vídeo deve ser maior que 1 minuto';
            this.answerVideo = event;
            this.answerVideoValue.emit({ maxDuration: this.answerVideo });
            this.activeCreateScreen.emit({ disabled: true });

            return;
        }

        if (event > 4) {
            this.activeCreateScreen.emit({ disabled: true });
            this.msgDurationVideo = 'A duração do vídeo não pode passar de 4 minutos';

            return;
        }

        this.msgDurationVideo = '';
        this.answerVideo = event;

        const maxDuration = this.answerVideo * 60;
        this.answerVideoValue.emit({ maxDuration });

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }

    /** CAMPO RESPOSTAS - Audio */
    changeAnswerAudio(event): void {
        if (event < 1) {
            this.msgDurationAudio = 'A duração do áudio deve ser maior que 1 minuto';
            this.answerAudio = event;
            this.answerAudioValue.emit({ maxDuration: this.answerAudio });
            this.activeCreateScreen.emit({ disabled: true });

            return;
        }

        if (event > 4) {
            this.activeCreateScreen.emit({ disabled: true });
            this.msgDurationAudio = 'A duração do áudio não pode passar de 4 minutos';

            return;
        }

        this.msgDurationAudio = '';
        this.answerAudio = event;

        const maxDuration = this.answerAudio * 60;
        this.answerAudioValue.emit({ maxDuration });

        // Ativação do botão de criar nova tela
        this.checkValueComponentsInUse();
    }
}
