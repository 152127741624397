import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import CONSTANTS from '../../core/constants';

@Component({
  selector: 'app-choose-screen-type',
  templateUrl: './choose-screen-type.component.html',
  styleUrls: ['./choose-screen-type.component.scss']
})
export class ChooseScreenTypeComponent implements OnInit {

  @Input() token: string;
  @Input() companyId: string;
  @Input() testId: string;
  @Input() rule: string;

  private SCREEN_OPTIONS = [{
    name: 'Instrução',
    value: 'instruction'
  }];
  public startIndex: number = 0;
  public lastIndex: number = 2;
  public screenOptions = [];
  public activeSlide: boolean = true;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.rule === 'evaluative_tests') {
      this.SCREEN_OPTIONS = CONSTANTS.SCREEN_OPTIONS_DEFAULT;
    } else if (this.rule === 'tests_with_weights') {
      this.SCREEN_OPTIONS = CONSTANTS.WEIGHTS_SCREEN_OPTIONS;
    } else if (this.rule === 'tests_with_weights_profiles') {
      this.SCREEN_OPTIONS = CONSTANTS.WEIGHTS_PROFILES_SCREEN_OPTIONS;
    }
    if (this.SCREEN_OPTIONS.length < 4) {
      this.activeSlide = false;
      this.screenOptions = this.SCREEN_OPTIONS;
    } else {
      this.screenOptions = this.SCREEN_OPTIONS.slice(this.startIndex, 3);
    }
  }

  public leftClick() {
    const options = this.SCREEN_OPTIONS.length;
    if (this.startIndex === 0) {
      this.startIndex = options - 1;
      this.lastIndex--;
      this.screenOptions.unshift(this.SCREEN_OPTIONS[options - 1]);
      this.screenOptions.pop();
    } else if (this.lastIndex === 0) {
      this.lastIndex = options - 1;
      this.startIndex--;
      this.screenOptions.unshift(this.SCREEN_OPTIONS[this.startIndex]);
      this.screenOptions.pop();
    } else {
      this.startIndex--;
      this.lastIndex--;
      this.screenOptions.unshift(this.SCREEN_OPTIONS[this.startIndex]);
      this.screenOptions.pop();
    }
  }

  public rightClick() {
    const options = this.SCREEN_OPTIONS.length;
    if (this.lastIndex === (options - 1)) {
      this.lastIndex = 0;
      this.startIndex++;
      this.screenOptions.shift();
      this.screenOptions.push(this.SCREEN_OPTIONS[0]);
    } else if (this.startIndex === (options - 1)) {
      this.startIndex = 0;
      this.lastIndex++;
      this.screenOptions.shift();
      this.screenOptions.push(this.SCREEN_OPTIONS[this.lastIndex]);
    } else {
      this.startIndex++;
      this.lastIndex++;
      this.screenOptions.shift();
      this.screenOptions.push(this.SCREEN_OPTIONS[this.lastIndex]);
    }
  }

  goToPage(typeScreen: string) {
    this.router.navigateByUrl(`companies/tests/${this.companyId}/edit-test/${this.testId}/edit-screen/${typeScreen}?token=${this.token}`);
  }

}
