<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex justify-content-center">
    <mat-icon class="color-blue mr-2">swap_vert</mat-icon>
    <h2 class="title-modal">
      Reordenar telas
    </h2>
  </div>

  <div cdkDropList class="list-screen" role="list" (cdkDropListDropped)="drop($event)">
    <div #listItem cdkDrag tabindex="0" class="screen-box" *ngFor="let activity of test?.activities; let i = index"
      role="listitem" (keydown)="handleKeyDown($event, i)" cdkDragBoundary=".modal-body">
      <p>
        {{ i + 1 }}. {{ activity.name }}
      </p>
      <mat-icon class="color-gray mr-2">swap_vert</mat-icon>
    </div>
  </div>

  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Cancelar</button>
    <button class="btn btn-green mx-4" [ngClass]="{'d-none': !test.canEdit}" type="submit" [disabled]="isSave"
      (click)="saveTest()">
      Salvar tela
    </button>
  </div>
</div>
