<ng-container [formGroup]="multipleQuizWeight">
  <div *ngIf="alternatives.controls.length < 2">
    <p class="message-help">Atenção: </p>
    <p class="message-help">
      - Para salvar a tela é necessário que pelo menos 2 alternativas sejam preenchidas.
    </p>
  </div>

  <div class="section-alternative">
    <ng-container formArrayName="alternatives" *ngFor="let alternative of alternatives.controls; index as i">
      <ng-container [formGroupName]="i">
        <div class="row divider alternative-weight">
          <mat-form-field class="col-12 p-0 mb-10" appearance="outline">
            <mat-label>Alternativa {{ alphabet[i] }}</mat-label>
            <input matInput type="text" placeholder="Digite a alternativa" formControlName="option"
              (change)="changeAlternative($event, i)">
            <mat-error *ngIf="alternative.get('option').errors">
              <p class="error-message" *ngIf="alternative.get('option').errors?.required">Campo obrigatório</p>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-5 p-0 mb-10" appearance="outline"
            [ngClass]="{'col-12': typeScreen === 'weightQuiz'}">
            <mat-label>Pontos</mat-label>
            <input matInput type="number" min="0" max="1000" placeholder="Digite os pontos" class="text-center"
              formControlName="profileValue">
            <mat-error *ngIf="alternative.get('profileValue').errors">
              <p class="error-message" *ngIf="alternative.get('profileValue').errors?.required">Campo obrigatório</p>
              <p class="error-message" *ngIf="alternative.get('profileValue').errors?.min">O ponto não pode ser negativo
              </p>
              <p class="error-message" *ngIf="alternative.get('profileValue').errors?.max">O ponto não pode ser maior
                que
                1000</p>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-7 p-0 pl-2 mb-10" appearance="outline" *ngIf="typeScreen === 'weightProfileQuiz'">
            <mat-label>Atributo</mat-label>
            <mat-select formControlName="profileId">
              <mat-option selected value="null" disabled>Escolha um atributo</mat-option>
              <mat-option *ngFor="let item of profiles" [value]="item._id"
                [hidden]="item.key === 'sem-atributo-definido'">
                {{ item.name }}
              </mat-option>
              <button mat-button class="w-100 text-left btn-profile" (click)="openCreateProfileModal()">
                Adicionar novo atributo...
              </button>
            </mat-select>
            <mat-error *ngIf="alternative.get('profileId').errors">
              <p class="error-message" *ngIf="alternative.get('profileId').errors?.required">Campo obrigatório</p>
              <p class="error-message" *ngIf="alternative.get('profileId').errors?.min">O ponto não pode ser negativo
              </p>
              <p class="error-message" *ngIf="alternative.get('profileId').errors?.max">O ponto não pode ser maior que
                1000</p>
            </mat-error>
          </mat-form-field>

          <button type="button" class="button-icon action-button position-delete-button"
            aria-label="Remover alternativa" ngbTooltip="Remover alternativa" tooltipClass="custom-tooltip"
            placement="left" (click)="removeAlternative(i)">
            <div class="fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/delete.svg'"></div>
          </button>

        </div>
      </ng-container>
    </ng-container>
  </div>

  <button type="button" class="w-100 button-add" aria-label="Abrir modal para reordenar as telas"
    [disabled]="blockAddAlternative" (click)="addAlternative()">
    <div class="mr-3 size-20" [inlineSVG]="assets_path + 'assets/images/icons/add-square.svg'">
    </div>
    Adicionar nova alternativa
  </button>
</ng-container>
