<h3 class="group-screen-title mb-12">Instruções</h3>

<div class="row ml-16 mb-40">
  <img *ngIf="screen.image" [src]="screen.image" class="col-6 media-preview instruction">

  <video *ngIf="screen.video" class="col-6 media-preview instruction" controls>
    <source [src]="screen.video" type="video/mp4">
  </video>

  <div class="m-w-90" [ngClass]="{'col-6': screen.image || screen.video, 'col-12': !screen.image && !screen.video }">
    <app-read-more *ngIf="screen.text" [content]="screen.text" [limit]="300"></app-read-more>
  </div>
</div>
