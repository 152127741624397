import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReadMoreComponent } from './read-more/read-more.component';
import { BadgeComponent } from './badge/badge.component';
import { PipesModule } from '../pipes/pipes.module';


@NgModule({
  imports: [
    CommonModule,
    PipesModule
  ],
  declarations: [
    ReadMoreComponent,
    BadgeComponent,
  ],
  exports: [
    ReadMoreComponent,
    BadgeComponent,
  ]
})
export class CoreModule { }
