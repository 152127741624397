<div class="container-fluid p-area">
  <div class="form-row">
    <div class="form-group col-md-12 flex">
      <div class="card-image status-candidate">
        <!-- [ngClass]="statusTeste === 'AVAILABLE' ? 'available' : statusTeste === 'WAITING_ACCEPT_INVITATION' ? 'pending' : statusTeste === 'BLOCKED' ? 'blocked' : statusTeste === 'INTERVIEWER' ? 'scheduled' : 'undefined'" -->
        <img class="img available" alt="imagem do teste"
          [src]="questionSelected && questionSelected?.image ? questionSelected.image : assets_path + 'assets/images/capas_no_image.png'"
          onerror="try {this.src = assets_path + 'assets/images/capas_no_image.png' } catch (err) { }" />
      </div>
      <div class="info-padding">
        <h1 class="text-title">{{ questionTest?.name}}</h1>
        <p class="text-description">
          {{ questionTest?.description}}
        </p>
        <span class="text-date">
          <img class="small-icon" [src]="assets_path+'assets/images/icons/schedule.svg'" [alt]="'Icone de agenda'"
            [title]="'Data'" />
          Criado em {{ questionTest?.createDate | date:'dd/MM/yyyy' }}
        </span>
        <div *ngIf="answerTest?.beginDate && !answerTest?.finishDate" class="warning-test">
          <p><span>Alerta: </span>As correções só serão salvas após a conclusão do teste por parte dos candidatos! Para
            seguir com a atividade acione o candidato e solicite a conclusão. Qualquer dúvida, nosso suporte técnico
            está a disposição.</p>
        </div>

      </div>
      <div class="group-buttons">
        <div>
          <button mat-button class="btn btn-approve mr-3" [disabled]="!enableApproved"
            (click)="submitStatus('approved')">Aprovado</button>
          <button mat-button class="btn btn-disapprove" [disabled]="!enableApproved"
            (click)="submitStatus('disapproved')">Reprovado</button>
        </div>
        <p class="help-message mt-2" *ngIf="!enableApproved">{{helpMessage}}</p>
      </div>
    </div>
  </div>

  <div class="form-row content-info">
    <div class="form-group col-md-4">
      <ng-container *ngIf="!isEditing">
        <p class="title-question"> Questões </p>

        <!-- EXIBICAO DAS QUESTOES -->
        <div class="question-container">
          <div *ngFor="let item of questions; let i = index;">
            <div class="question-card" (click)="testSelect(item)"
              [ngClass]="item._id === questionSelected?._id ? 'on' : 'off'">
              <span class="text-index">
                {{ i + 1 }}.
              </span>
              <div class="place-center">
                <p class="text-question question m-0">
                  {{ item?.name}}
                </p>
                <p class="text-question question m-0 mt-2">
                  {{ getQuestionType(item.contentFormat) }}
                </p>
              </div>
              <mat-icon class="left-arrow-icon">keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- RESPOSTA E CONTEUDO DAS QUESTOES -->
    <div *ngIf="questionSelected" class="form-group col-md-8 rating-padding">
      <ng-container *ngIf="!isEditing">
        <h1 class="title-rating mt-0"> AVALIAÇÃO - {{ questionSelected?.content?.questions[0]?.text}} </h1>
        <p class="message-for-quiz" *ngIf="questionSelected && questionSelected.contentFormat === 'multipleQuiz'">
          As perguntas do tipo "Quiz" não podem receber uma avaliação do recrutador.
          <br>
          A informação apresentada abaixo é apenas para consulta.
        </p>

        <!-- COMPONENTE DE RATING -->
        <div class="rating-container">
          <div *ngIf="questionSelected?.scoreType === 'number'">
            <div *ngFor="let rating of RATINGS; let i = index;" class="rating-card">
              <div (click)="ratingQuestion(rating.value)" class="circle"
                [ngClass]="[answerSelected?.nota && answerSelected.nota === rating.value  ? 'on' : 'off', getAnswerSelectedCalss(rating.value)]">
                <span>{{ rating.value }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="questionSelected?.scoreType === 'boolean'">
            <div class="mt-4 like-content">
              <div class="like-card" [ngClass]="getAnswerSelectedCalss(rating.value)"
                (click)="ratingWithLikeQuestion('10')">
                <img class="icon" [src]="!answerSelected || (!answerSelected.nota || answerSelected.nota !== '10') ? assets_path + 'assets/images/icons/like.svg' :
                          assets_path + 'assets/images/icons/like-green.svg'" alt="'Icone de like'" title="Gostei" />
              </div>
              <div class="like-card" [ngClass]="getAnswerSelectedCalss(rating.value)"
                (click)="ratingWithLikeQuestion('0')">
                <img class="icon" [src]="!answerSelected ? assets_path + 'assets/images/icons/deslike.svg' : answerSelected.nota && answerSelected.nota === '0' ? assets_path + 'assets/images/icons/deslike-red.svg' : assets_path + 'assets/images/icons/deslike.svg'
                " alt="Icone de deslike" title="Não gostei" />
              </div>
            </div>
          </div>
        </div>

        <div class="content-answer">

          <!-- SETAS PARA MOVER PERGUNTAS -->
          <img (click)="previousQuestion()" class="left-position"
            src="{{assets_path}}assets/images/icons/arrow-left-blue.svg" alt="anterior">
          <img (click)="nextQuestion()" class="right-position"
            src="{{assets_path}}assets/images/icons/arrow-right-blue.svg" alt="próxima">

          <!-- RESPOSTA EM VIDEO -->
          <div *ngIf="answerSelected?.answer && questionSelected?.contentFormat === 'video'">
            <video class="player" poster="{{assets_path}}assets/images/icons/taqe-logo-white.svg" controls
              data-overlay="1" src="{{answerSelected.answer}}" type="video/mp4" #videoPlayer>
              <!-- <source src="{{answerSelected.answer}}" type="video/mp4" /> -->
              <!-- Browser not supported -->
            </video>
          </div>

          <!-- RESPOSTA EM AUDIO -->
          <div *ngIf="answerSelected?.answer && questionSelected?.contentFormat === 'audio'">
            <audio class="player" controls style="width: 100%" data-overlay="1" preload="auto"
              [src]="answerSelected.answer" type="audio/mpeg">
              <!-- <source [src]="answerSelected.answer" />
              Browser not supported -->
            </audio>
          </div>

          <!-- RESPOSTA EM TEXTO -->
          <div *ngIf="answerSelected?.answer &&
           questionSelected?.contentFormat !== 'video' &&
            questionSelected?.contentFormat !== 'audio'">
            <textarea class="input-answer" disabled [value]="answerSelected.answer"></textarea>
          </div>
        </div>

        <div *ngIf="questionSelected && questionSelected.contentFormat !== 'multipleQuiz'">
          <p class="obs-title">Observações</p>
          <textarea rows="5" (keyup)="onKeyUp()" (keydown)="onKeyDown()"
            [class]="observationChanged ? 'b-success' : 'b-off' " [(ngModel)]='rating.observation'
            class="form-control m-0" id="observation">
          </textarea>
        </div>

        <ng-container *ngIf="answerSelected && answerSelected.evaluations">
          <span class="note-user">Última nota aplicada por
            {{answerSelected?.evaluations[answerSelected.evaluations.length - 1]?.userManager?.firstName}}
            {{answerSelected?.evaluations[answerSelected.evaluations.length - 1]?.userManager?.lastName}}
            no dia {{answerSelected?.evaluations[answerSelected.evaluations.length - 1]?.date |
            date: 'dd/MM/yyyy hHH:mm:ss a'}}</span>
        </ng-container>
      </ng-container>
    </div>
  </div>
