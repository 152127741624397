import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';

import { ActivityBase, BaseTest } from '../models/test.model';
import { TestService } from 'src/app/core/services/test.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-create-custom-test',
    templateUrl: './create-custom-test.component.html',
    styleUrls: ['./create-custom-test.component.scss']
})
export class CreateCustomTestComponent implements OnInit {
    public assets_path: string = environment.assets_path;
    @Input() microfrontend: boolean = false as boolean;
    @Input() companyId: string = '' as string;
    @Input() token: string = '' as string;
    @Input() testId: string = '' as string;
    @Output() emitDataToParent = new EventEmitter<any>();

    public screens: ActivityBase[] = [{ name: 'Nome da tela' }];
    public selectScreen: ActivityBase;
    public load: boolean = false as boolean;

    private index: number = 0 as number;
    private test: BaseTest = null;

    // Componentes de questões e respostas
    questions = [
        { id: 'question-text', value: 'Texto', icon: 'text-format.svg', disabled: false },
        { id: 'question-image', value: 'Imagem', icon: 'image.svg', disabled: false },
        { id: 'question-video', value: 'Vídeo', icon: 'video.svg', disabled: false },
    ];

    answers = [
        { id: 'answer-text', value: 'Texto', icon: 'text-format.svg', disabled: true },
        { id: 'answer-video', value: 'Vídeo', icon: 'video.svg', disabled: true },
        { id:'answer-audio', value: 'Áudio', icon: 'microphone.svg', disabled: true },
        { id: 'answer-quiz1', value: 'Quiz', icon: 'quiz-1.svg', disabled: true },
    ];

    // Componentes de Respostas completo
    // answers = [
    //   { id:'answer-text', value: 'Texto', icon: 'text-format.svg', disabled: true },
    //   { id:'answer-image', value: 'Imagem', icon: 'image.svg', disabled: true },
    //   { id:'answer-video', value: 'Vídeo', icon: 'video.svg', disabled: true },
    //   { id:'answer-audio', value: 'Áudio', icon: 'microphone.svg', disabled: true },
    //   { id:'answer-quiz1', value: 'Quiz1', icon: 'quiz-1.svg', disabled: true },
    //   { id:'answer-quiz2', value: 'Quiz2', icon: 'quiz-2.svg', disabled: true },
    //   { id:'answer-quiz3', value: 'Quiz3', icon: 'quiz-3.svg', disabled: true },
    //   { id:'answer-quiz4', value: 'Quiz4', icon: 'quiz-4.svg', disabled: true },
    // ];

    contentFormat = {
        answerText: 'text',
        answerVideo: 'video',
        answerAudio: 'audio',
        answerQuiz1: 'multipleQuiz',
    };

    alternativesType = {
        answerQuiz1: 'text',
    };

    enableButtons = true;
    scoreType: string = 'boolean' as string;
    disabledCreateScreen = true;
    clear: boolean = false as boolean;
    canEdit: boolean = true as boolean;

    // Salvar os campos
    screenName: string = '' as string;
    defaultName: boolean = false as boolean;
    questionText: string = null;
    questionImage = null;
    questionVideo = null;
    answerText: { min: number; max: number; } = null;
    answerVideo: { maxDuration: number } = null;
    answerAudio: { maxDuration: number } = null;
    answerQuiz1 = null;
    correctAnswer: string = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private testService: TestService,
    ) {
        if (!this.microfrontend) {
            // tslint:disable-next-line: no-string-literal
            this.route.params.subscribe((params: any) => this.testId = params['testId']);
        }
    }

    ngOnInit(): void {
        if (!this.microfrontend) {
            this.companyId = this.route.snapshot.params.companyId;
            this.token = this.route.snapshot.queryParams.token;
        }

        this.testService.showTest(this.companyId, this.token, this.testId)
            .subscribe((test: any) => {
                this.getTest(test);
            }, () => {
                if (this.microfrontend) {
                    // Edson rever esta regra
                } else {
                    this.router.navigate(['/']);
                }
            });
    }

    public get testName(): string {
      return this.test?.name ? `Edição do teste ${this.test?.name}` : 'Edição de teste';
    }

    public get testDescription(): string {
      return '...';
    }

    public button1Function($event: boolean): void {
        if ($event && !this.disabledCreateScreen) {
            this.saveTest();
        }
    }

    public button2Function($event: any): void { }

    getTest(test): void {
        this.test = test;

        if (!test.activities.length) {
            return;
        }

        // Salvar as telas
        this.screens = test.activities;

        // Bloquear edição
        this.canEdit = this.test.canEdit;

        // Exibir a primeira tela
        this.selectScreen = this.screens[0];


        if (!this.test.canEdit) {
          this.lockComponent();
        }

        // Habilitar botão tipo de avaliação
        if (this.selectScreen?.contentFormat === 'text' || this.selectScreen?.contentFormat === 'video' || this.selectScreen?.contentFormat === 'audio') {
            this.scoreType = this.selectScreen?.scoreType;

            this.enableButtons = false;
        }
    }

    /** Bloquear componente */
    lockComponent(): void {
      for (const item of this.questions) {
        item.disabled = true;
      }

      for (const item of this.answers) {
        item.disabled = true;
      }
    }

    /** Bloquear tela */
    loader(): void {
        this.load = true;
        this.disabledCreateScreen = true;

        for (const item of this.questions) {
            item.disabled = true;
        }

        for (const item of this.answers) {
            item.disabled = true;
        }
    }

    // Drag and drop (cdk)
    drop(event: CdkDragDrop<any[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    // Habilita/Desabilita os componentes de respostas
    changeDisabledAnswersComponents(event): void {
        if (this.canEdit) {
            if (event.enabledAnswer) {
                for (const item of this.answers) {
                    item.disabled = false;
                }
            } else {
                for (const item of this.answers) {
                    item.disabled = true;
                }
            }
        }

        this.clear = false;
    }

    // Habilita/Desabilita o componente após o seu uso
    changeDisabledComponent(event): void {
        if (this.canEdit) {
            // Limita o uso do componente para apenas imagem ou vídeo
            if (event.id === 'question-image') {
                this.questions[2].disabled = event.disabled;
            }

            if (event.id === 'question-video') {
                this.questions[1].disabled = event.disabled;
            }

            if (event.id === 'question-text' || event.id === 'question-image' || event.id === 'question-video') {
                for (const item of this.questions) {
                    if (event.id === item.id) {
                        item.disabled = event.disabled;
                        return;
                    }
                }
            } else {
                for (const item of this.answers) {
                    item.disabled = event.disabled;
                }
            }
        }
    }

    /** Habilita/Desabilita o botão tipo de avaliação */
    enableEvaluationsButton(event): void {
        if (event.id === 'answer-text' || event.id === 'answer-video' || event.id === 'answer-audio') {
            this.enableButtons = !event.disabled;
        }
    }

    /** Armazena a escolha do tipo de avaliação */
    activeButtonAvaliation(value): void {
        if (this.canEdit) {
            this.scoreType = value;
        }
    }

    /** Altera o estilo do botão de tipo de avaliação */
    onButtonGroupClick(event): void {
      if (this.canEdit) {
        const clickedElement = event.target || event.srcElement;

        if (clickedElement.nodeName === 'BUTTON') {

            const isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector('.active');
            // if a Button already has Class: .active
            if (isCertainButtonAlreadyActive) {
                isCertainButtonAlreadyActive.classList.remove('active');
            }

            clickedElement.className += ' active';
        }
      }
    }

    /** Habilita/Desabilita o botão criar nova tela */
    enabledCreateScreen(event): void {
        if (!this.canEdit)
            return;

        this.disabledCreateScreen = event.disabled;
    }

    /** Salvar os campos */
    saveScreenName(event): void {
        this.screenName = event;

        if (this.screenName) {
            this.screens[this.index].name = this.screenName;
        } else {
            this.screens[this.index].name = 'Nome da tela';
        }
    }

    saveQuestionText(event): void {
        this.questionText = event;
    }

    saveQuestionImage(event): void {
        this.questionImage = event;
    }

    saveQuestionVideo(event): void {
        this.questionVideo = event;
    }

    saveAnswerText(event): void {
        if (event == null) {
            this.answerText = event;
            return;
        }

        this.answerText = { min: event.min, max: event.max };
    }

    saveAnswerVideo(event): void {
        if (event == null) {
            this.answerVideo = event;
            return;
        }

        this.answerVideo = { maxDuration: event.maxDuration };
    }

    saveAnswerAudio(event): void {
        if (event == null) {
            this.answerAudio = event;
            return;
        }

        this.answerAudio = { maxDuration: event.maxDuration };
    }

    saveAnswerQuiz1(event): void {
        this.answerQuiz1 = event.alternatives;
    }

    /** Salvar os dados */
    saveField(): void {
        if (this.answerText) {

            const question = {
                min: this.answerText.min,
                max: this.answerText.max,
                text: this.questionText,
                image: this.questionImage,
                video: this.questionVideo,
            };

            this.screens[this.index] = Object.assign(this.screens[this.index],
                {
                    contentFormat: this.contentFormat.answerText,
                    scoreType: this.scoreType,
                    content: { questions: [question] }
                });

            if (this.screens[this.index].presentation) {
                delete this.screens[this.index].presentation;
            }

        } else if (this.answerVideo) {

            const question = {
                maxDuration: this.answerVideo.maxDuration,
                text: this.questionText,
                image: this.questionImage,
                video: this.questionVideo,
            };

            this.screens[this.index] = Object.assign(this.screens[this.index],
                {
                    contentFormat: this.contentFormat.answerVideo,
                    scoreType: this.scoreType,
                    content: { questions: [question] }
                });

            if (this.screens[this.index].presentation) {
                delete this.screens[this.index].presentation;
            }

        } else if (this.answerAudio) {

            const question = {
                maxDuration: this.answerAudio.maxDuration,
                text: this.questionText,
                image: this.questionImage,
                video: this.questionVideo,
            };

            this.screens[this.index] = Object.assign(this.screens[this.index],
                {
                    contentFormat: this.contentFormat.answerAudio,
                    scoreType: this.scoreType,
                    content: { questions: [question] }
                });

            if (this.screens[this.index].presentation) {
                delete this.screens[this.index].presentation;
            }

        } else if (this.answerQuiz1) {

            for (const item of this.answerQuiz1) {
                if (item.isCorrect === true) {
                    this.correctAnswer = item.letter;
                }
            }

            const question = {
                correctAnswer: this.correctAnswer,
                alternatives: this.answerQuiz1,
                alternativesType: this.alternativesType.answerQuiz1,
                text: this.questionText,
                image: this.questionImage,
                video: this.questionVideo,
            };

            this.screens[this.index] = Object.assign(this.screens[this.index],
                {
                    contentFormat: this.contentFormat.answerQuiz1,
                    scoreType: 'boolean',
                    content: { questions: [question] }
                });

        } else if (this.questionText || this.questionImage || this.questionVideo) {
            const presentation = {
                text: this.questionText,
                image: this.questionImage,
                video: this.questionVideo
            };

            this.screens[this.index] = Object.assign(this.screens[this.index], { presentation });

            if (this.screens[this.index].contentFormat) {
                delete this.screens[this.index].contentFormat;
                delete this.screens[this.index].scoreType;
                delete this.screens[this.index].content;
            }
        }
    }

    /** Limpar os dados */
    clearScreen(): void {

        // Atribuir valores iniciais
        this.enableButtons = true;
        this.scoreType = 'boolean';
        this.disabledCreateScreen = true;
        this.screenName = '';
        this.questionText = null;
        this.questionImage = null;
        this.questionVideo = null;
        this.answerText = null;
        this.answerVideo = null;
        this.answerAudio = null;
        this.answerQuiz1 = null;
        this.correctAnswer = null;


        for (const item of this.questions) {
            item.disabled = false;
        }

        for (const item of this.answers) {
            item.disabled = true;
        }
    }

    /** Salvar a tela */
    saveScreen(type: string, index: number): void {
        if (this.index === index) {
            return;
        }

        if (!this.canEdit) {
            this.clearScreen();

            this.index = index;

            this.selectScreen = this.screens[index];

            this.lockComponent();

            // Habilitar botão tipo de avaliação
            if (this.selectScreen?.contentFormat === 'text' || this.selectScreen?.contentFormat === 'video' || this.selectScreen?.contentFormat === 'audio') {
                this.scoreType = this.selectScreen?.scoreType;

                this.enableButtons = false;
            }

            return;
        }

        if ((!this.questionImage && !this.questionVideo) ||
            typeof this.questionImage === 'string' ||
            typeof this.questionVideo === 'string') {

            this.saveField();

            if (type === 'new') {

                this.index = this.screens.length;

                this.screens.push({ name: 'Nome da tela' });

                this.clear = true;
                this.clearScreen();

            } else if (type === 'change') {

                this.clearScreen();

                this.index = index;

                this.selectScreen = this.screens[index];

                // Habilitar botão tipo de avaliação
                if (this.selectScreen?.contentFormat === 'text' || this.selectScreen?.contentFormat === 'video' || this.selectScreen?.contentFormat === 'audio') {
                    this.scoreType = this.selectScreen?.scoreType;

                    this.enableButtons = false;
                }
            }

            return;
        }

        // Verificar se tem imagem
        if (this.questionImage) {
            const formData = new FormData();
            formData.append('file', this.questionImage, this.questionImage.relativePath);
            this.load = true;
            this.disabledCreateScreen = true;

            for (const item of this.questions) {
                item.disabled = true;
            }

            for (const item of this.answers) {
                item.disabled = true;
            }

            this.testService.uploadFile(
                this.companyId,
                this.token,
                this.testId,
                'image',
                formData
            )
                .subscribe((response: any) => {

                    if (response && response.url) {
                        this.questionImage = response.url;
                    }
                    this.saveField();
                    this.load = false;

                    if (type === 'new') {

                        this.index = this.screens.length;

                        this.screens.push({ name: 'Nome da tela' });

                        this.clear = true;
                        this.clearScreen();

                    } else if (type === 'change') {

                        this.clearScreen();

                        this.index = index;

                        this.selectScreen = this.screens[index];

                        // Habilitar botão tipo de avaliação
                        if (this.selectScreen?.contentFormat === 'text' || this.selectScreen?.contentFormat === 'video') {
                            this.scoreType = this.selectScreen?.scoreType;

                            this.enableButtons = false;
                        }
                    }

                }, (error) => { });
        }

        // Verificar se tem video
        if (this.questionVideo) {
            const formData = new FormData();
            formData.append('file', this.questionVideo, this.questionVideo.relativePath);
            this.load = true;
            this.disabledCreateScreen = true;

            for (const item of this.questions) {
                item.disabled = true;
            }

            for (const item of this.answers) {
                item.disabled = true;
            }

            this.testService.uploadFile(
                this.companyId,
                this.token,
                this.testId,
                'video',
                formData
            )
                .subscribe((response: any) => {

                    if (response && response.url) {
                        this.questionVideo = response.url;
                    }
                    this.saveField();
                    this.load = false;

                    if (type === 'new') {

                        this.index = this.screens.length;

                        this.screens.push({ name: 'Nome da tela' });

                        this.clear = true;
                        this.clearScreen();

                    } else if (type === 'change') {

                        this.clearScreen();

                        this.index = index;

                        this.selectScreen = this.screens[index];

                        // Habilitar botão tipo de avaliação
                        if (this.selectScreen?.contentFormat === 'text' || this.selectScreen?.contentFormat === 'video') {
                            this.scoreType = this.selectScreen?.scoreType;

                            this.enableButtons = false;
                        }
                    }

                }, (error) => { });
        }
    }

    /** Remover tela */
    deleteScreen(index): void {
        this.screens.splice(index, 1);

        if (!this.screens.length) {

            // Limpar a tela
            this.clear = true;
            this.clearScreen();

            this.index = 0;

            this.screens.push({ name: 'Nome da tela' });
        }

        if (index !== this.index) {
            return;
        }

        // Limpar a tela
        this.clearScreen();

        // Exibir a primeira tela
        this.index = 0;

        this.selectScreen = this.screens[this.index];

        if (this.selectScreen?.contentFormat === 'text' || this.selectScreen?.contentFormat === 'video' || this.selectScreen?.contentFormat === 'audio') {
            this.scoreType = this.selectScreen?.scoreType;

            this.enableButtons = false;
        }
    }

    /** Salvar teste */
    saveTest(): void {
        if (this.canEdit) {
          if ((!this.questionImage && !this.questionVideo) ||
              typeof this.questionImage === 'string' ||
              typeof this.questionVideo === 'string') {

              this.saveField();

              this.test.activities = this.screens;

              this.loader();

              this.testService.updateTest(
                  this.companyId,
                  this.token,
                  this.testId,
                  { vacancyTest: this.test }
              )
                  .subscribe(() => {
                      if (this.microfrontend) {
                          // Edson rever esta regra
                      } else {
                          this.router.navigateByUrl(`companies/tests/${this.companyId}?token=${this.token}`);
                      }
                  }, (error) => { });

          } else if (this.questionImage) {

              const formData = new FormData();
              formData.append('file', this.questionImage, this.questionImage.relativePath);

              this.loader();

              this.testService.uploadFile(
                  this.companyId,
                  this.token,
                  this.testId,
                  'image',
                  formData
              )
                  .subscribe((response: any) => {

                      if (response && response.url) {
                          this.questionImage = response.url;
                      }

                      this.saveField();

                      this.load = false;

                      this.test.activities = this.screens;

                      this.testService.updateTest(
                          this.companyId,
                          this.token,
                          this.testId,
                          { vacancyTest: this.test }
                      )
                          .subscribe(() => {
                              if (this.microfrontend) {
                                  // Edson rever esta regra
                              } else {
                                  this.router.navigateByUrl(`companies/tests/${this.companyId}?token=${this.token}`);
                              }
                          }, (error) => { });

                  }, (error) => { });

          } else if (this.questionVideo) {

              const formData = new FormData();
              formData.append('file', this.questionVideo, this.questionVideo.relativePath);

              this.loader();

              this.testService.uploadFile(
                  this.companyId,
                  this.token,
                  this.testId,
                  'video',
                  formData
              )
                  .subscribe((response: any) => {

                      if (response && response.url) {
                          this.questionVideo = response.url;
                      }

                      this.saveField();

                      this.load = false;

                      this.test.activities = this.screens;

                      this.testService.updateTest(
                          this.companyId,
                          this.token,
                          this.testId,
                          { vacancyTest: this.test }
                      )
                          .subscribe(() => {
                              if (this.microfrontend) {
                                  // Edson rever esta regra
                              } else {
                                  this.router.navigateByUrl(`companies/tests/${this.companyId}?token=${this.token}`);
                              }
                          }, (error) => { });

                  }, (error) => { });
          }
        } else {
          this.router.navigateByUrl(`companies/tests/${this.companyId}?token=${this.token}`);
        }
    }

}
