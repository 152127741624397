import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InlineSVGModule } from 'ng-inline-svg';

import { CreateTestsComponent } from './create-tests.component';
import { MobileScreenComponent } from './mobile-screen/mobile-screen.component';
import { MobileScreenEditComponent } from './mobile-screen-edit/mobile-screen-edit.component';

@NgModule({
  declarations: [
    CreateTestsComponent,
    MobileScreenComponent,
    MobileScreenEditComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatCardModule,
    MatProgressBarModule,
    InlineSVGModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CreateTestsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CreateTestsModule { }
