<div class="container-fluid body">
  <div class="row">
    <div *ngIf="!load && showTestList" class="col-4 col-xxl-3">
      <app-candidate-test-list [candidates]="candidates" [pagLog]="pagLog" (pagLogChange)="getAllCandidate()"
        [loadCandidate]="loadCandidate" (filtersChange)="getAllCandidate($event)"
        (candidate)="changeCandidateTest($event)">
      </app-candidate-test-list>
    </div>

    <div *ngIf="!load" [ngClass]="{'col-8 col-xxl-9 px-25': showTestList, 'col-12': !showTestList }">
      <app-candidate-test-info *ngIf="!loadTest && candidateTest" [test]="test" [(candidateTest)]="candidateTest"
        [(screens)]="screens" [finishedTest]="finishedTest">
      </app-candidate-test-info>

      <div *ngIf="loadTest" class="col-12 load-position">
        <mat-spinner class="my-4" [diameter]="60"></mat-spinner>
      </div>
    </div>

    <div *ngIf="load" class="col-12 load-position">
      <mat-spinner class="my-4" [diameter]="60"></mat-spinner>
    </div>
  </div>
</div>
