import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TestService } from 'src/app/core/services/test.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-mobile-screen-edit',
    templateUrl: './mobile-screen-edit.component.html',
    styleUrls: ['./mobile-screen-edit.component.scss']
})
export class MobileScreenEditComponent implements OnInit {
    public assets_path: string = environment.assets_path;
    @Input() microfrontend: boolean = false as boolean;
    @Input() companyId: string = '' as string;
    @Input() token: string = '' as string;
    @Output() emitDataToParent = new EventEmitter<any>();
    url;
    msg = '';
    public load: boolean = false as boolean;

    formulario: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private testService: TestService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.formulario = this.formBuilder.group({
            name: ['', Validators.required],
            image: ['', Validators.nullValidator],
            description: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        if (!this.microfrontend) {
            this.companyId = this.route.snapshot.params.companyId;
            this.token = this.route.snapshot.queryParams.token;
        }
    }

    selectFile(event): void {
        if (!event.target.files[0] || event.target.files[0].length === 0) {
            this.msg = 'Você deve selecionar uma imagem';
            this.url = '';
            return;
        }

        const mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            this.msg = 'Apenas imagens são suportadas';
            this.url = '';
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);

        this.formulario.get('image').setValue(event.target.files[0]);

        // tslint:disable-next-line: variable-name
        reader.onload = (_event) => {
            this.msg = '';
            this.url = reader.result;
        };
    }

    onSubmit(): void {
        if (!this.formulario.valid || this.load) {
            return;
        }

        this.load = true;

        const data = {
            vacancyTest: {
                activities: [],
                type: 'test_module',
                name: this.formulario.get('name').value,
                description: this.formulario.get('description').value
            }
        };

        this.testService.createTest(data, this.companyId, this.token)
            .subscribe((test) => {
                if (this.formulario.get('image').value) {
                    const formData = new FormData();
                    formData.append('file', this.formulario.get('image').value);
                    this.testService.uploadFile(this.companyId, this.token, test._id, 'image', formData)
                        .subscribe((response: any) => {
                            if (response && response.url) {
                                test.image = response.url;
                                this.testService.updateTest(
                                    this.companyId,
                                    this.token,
                                    test._id,
                                    { vacancyTest: test }
                                )
                                    .subscribe(() => { });
                            }

                            this.load = false;
                            this.sendToTest(test._id);
                        });
                } else {
                    this.load = false;
                    this.sendToTest(test._id);
                }
            }, (error) => {
                this.load = false;
            });
    }

    private sendToTest(testId: string): void {
        if (this.microfrontend) {
            this.emitDataToParent.emit({ testId });
        } else {
            this.router.navigateByUrl(`companies/tests/${this.companyId}/create-test/${testId}?token=${this.token}`);
        }
    }
}
