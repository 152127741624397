<div class="group-screen-option">
  <h3 class="title">Adicionar nova tela</h3>
  <p class="description">Escolha qual será o tipo de nova tela que será criado:</p>

  <div class="row justify-content-center w-100">
    <div [ngClass]="{'col-1': activeSlide}">
      <button *ngIf="activeSlide" mat-icon-button class="btn-arrow" aria-label="Navegar para os elementos anteriores"
        (click)="leftClick()">
        <mat-icon class="color-blue icon-size">navigate_before</mat-icon>
      </button>
    </div>
    <div [ngClass]="{'col-3': activeSlide, 'col-4': !activeSlide}" *ngFor="let option of screenOptions">
      <button mat-button class="btn-screen-option" (click)="goToPage(option.value)">{{option.name}}</button>
    </div>
    <div [ngClass]="{'col-1': activeSlide}">
      <button *ngIf="activeSlide" mat-icon-button class="btn-arrow" aria-label="Navegar para os elementos seguintes"
        (click)="rightClick()">
        <mat-icon class="color-blue icon-size">navigate_next</mat-icon>
      </button>
    </div>
  </div>
</div>
