import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { replaceDiacritics } from 'src/app/core/helpers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {

  @Input() profiles;

  public assets_path: string = environment.assets_path;
  public form: FormGroup;
  public isSaveForm: boolean = false;
  public key: string = '';
  public isProfileValid: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
  ) { }

  get profileName() { return this.form.get('profileName'); }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      profileName: ['', [Validators.required, Validators.maxLength(25)]]
    });
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  private checkDuplicate() {
    if (!this.key) {
      return;
    }
    let profileIndex = this.profiles.findIndex((profile) => String(profile.key) === this.key);
    this.isProfileValid = profileIndex === -1;
  }

  public changeName(event: any) {
    this.profileName.patchValue(event.target.value.trim());
    this.key = replaceDiacritics(this.profileName.value);
    this.checkDuplicate();
  }

  public submitForm() {
    if (!this.form.valid) {
      return;
    }
    this.isSaveForm = true;
    this.activeModal.close({ name: this.profileName.value, key: this.key });
  }

}
