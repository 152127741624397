import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTest } from 'src/app/models/test.model';
import CONSTANTS from 'src/app/core/constants';
import { environment } from 'src/environments/environment';
import { TestService } from 'src/app/core/services/test.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-config-periodic-availability',
  templateUrl: './config-periodic-availability.component.html',
  styleUrls: ['./config-periodic-availability.component.scss']
})
export class ConfigPeriodicAvailabilityComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() companyId: string;
  @Input() token: string;

  public PERIODIC_AVAILABILITY = CONSTANTS.PERIODIC_AVAILABILITY;
  public assets_path: string = environment.assets_path;
  public isSave: boolean = false;
  public description: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private testService: TestService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initTest();
  }

  private initTest() {
    this.description = this.test.canEdit
      ? 'Candidato pode refazer o teste?'
      : 'Uma vez que o teste seja usado nas vagas não poderá ser alterado o período de refazer o teste.';
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  public selectPeriodic(periodic) {
    if (this.test.canEdit) {
      this.test.periodicAvailability = periodic;
    }
  }

  public updateTest() {
    if (!this.test.canEdit) {
      return;
    }

    this.isSave = true;
    this.testService.updateTest(
      this.companyId,
      this.token,
      this.test._id,
      {
        vacancyTest: {
          periodicAvailability: this.test.periodicAvailability
        }
      }
    )
      .subscribe((res) => {
        let message = `O período de refazer o teste <b>${this.test.name}</b> foi atualizado com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });
        this.activeModal.close(res);
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
      });
  }

}
