<ng-container [formGroup]="record">
  <mat-form-field class="w-100 mb-8" appearance="outline">
    <mat-label>Quantidade máxima de minutos</mat-label>
    <input matInput type="number" min="1" max="4" placeholder="Digite o tempo máximo em minutos"
      formControlName="maxDuration" class="text-center">
    <mat-error *ngIf="maxDuration.errors">
      <p class="error-message" *ngIf="maxDuration.errors?.min">O tempo precisa ter pelo menos 1 minuto</p>
      <p class="error-message" *ngIf="maxDuration.errors?.max">O tempo precisa ter no máximo 4 minutos</p>
    </mat-error>
    <mat-hint class="form-hint">Por padrão a quantidade máxima é de 1 minuto</mat-hint>
  </mat-form-field>
</ng-container>
