<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="fill-blue modal-img" [inlineSVG]="assets_path + 'assets/images/icons/copy.svg'">
  </div>
  <h2 class="w-100 text-center title-modal">Duplicar teste</h2>

  <p class="w-80">
    Deseja seguir com a duplicação do teste <strong class="screen-name">{{ test.name }}?</strong>
  </p>

  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" type="button" (click)="close()">Cancelar</button>
    <button class="btn btn-green mx-4" type="button" [disabled]="isSaveForm" (click)="createTest()">
      Duplicar teste
    </button>
  </div>
</div>
