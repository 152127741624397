import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { InlineSVGModule } from 'ng-inline-svg';
import { FileUploadModule } from 'ng2-file-upload';

// Component's
import { AppComponent } from './app.component';
import { RatingTestsComponent } from './rating-tests/rating-tests.component';
import { EditCustomScreensComponent } from './edit-custom-screens/edit-custom-screens.component';
import { AlertChangesComponent } from './modal/alert-changes/alert-changes.component';
import { HomepageCustomScreensComponent } from './homepage-custom-screens/homepage-custom-screens.component';

// Module's
import { AppRoutingModule } from './app-routing.module';
import { CreateTestsModule } from './create-tests/create-tests.module';
import { CreateCustomTestModule } from './create-custom-test/create-custom-test.module';
import { EditCustomScreensModule } from './edit-custom-screens/edit-custom-screens.module';
import { HomepageCustomScreensModule } from './homepage-custom-screens/homepage-custom-screens.module';
import { TestCorrectionModule } from './test-correction/test-correction.module';

import { PipesModule } from './core/pipes/pipes.module';
import { CoreModule } from './core/components/core.module';
import { UserHelper } from './core/helpers';

import { environment } from '../environments/environment';
import { CreateCustomScreensComponent } from './create-custom-screens/create-custom-screens.component';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const local = environment.local;

@NgModule({
  declarations: [
    AppComponent,
    // CreateTestsComponent,
    // MobileScreenComponent,
    RatingTestsComponent,
    AlertChangesComponent,
    CreateCustomScreensComponent,
    // EditCustomScreensComponent,
    // CreateCustomTestComponent,
    // MobileScreenTestComponent,
    // MobileScreenEditComponent,
    // AsnwerQuiz1Component

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCardModule,
    CarouselModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    CreateTestsModule,
    CreateCustomTestModule,
    NgbModule,
    FileUploadModule,
    MatProgressSpinnerModule,
    EditCustomScreensModule,
    HomepageCustomScreensModule,
    PipesModule,
    CoreModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      timeOut: 5000
    }),
  ],
  providers: [
    UserHelper
  ],
  entryComponents: [AppComponent],
  bootstrap: [local ? AppComponent : []]
})

export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {
    const micro = createCustomElement(AppComponent, { injector: this.injector });
    customElements.define('mfe-test-module', micro);
  }

  ngDoBootstrap(appRef: ApplicationRef): void { }
}
