import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-mobile-screen',
    templateUrl: './mobile-screen.component.html',
    styleUrls: ['./mobile-screen.component.scss']
})
export class MobileScreenComponent implements OnInit {
    public assets_path: string = environment.assets_path;
    @Input() test: any;

    constructor() { }

    ngOnInit(): void { }

    // customOptions: OwlOptions = {
    //   loop: true,
    //   mouseDrag: false,
    //   touchDrag: false,
    //   pullDrag:false,
    //   dots: false,
    //   navSpeed: 700,
    //   navText: [,],
    //   responsive: {
    //     1: {
    //       items: 1
    //     }
    //   },
    //   nav: false
    // }
}
