import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import CONSTANTS from '../constants';

@Injectable({
  providedIn: 'root'
})
export class EditScreenAccessByUrlGuard implements CanActivate {

  constructor(
    private router: Router,
  ) { }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const typeScreen = next.params.typeScreen ? next.params.typeScreen : next.queryParams.typeScreen;
    const companyId = next.params.companyId ? next.params.companyId : next.queryParams.companyId;
    const testId = next.params.testId ? next.params.testId : next.queryParams.testId;
    const token = next.params.token ? next.params.token : next.queryParams.token;

    if (CONSTANTS.TEST_OPTIONS.includes(typeScreen)) {
      return true;
    }

    this.router.navigateByUrl(`companies/tests/${companyId}/edit-test/${testId}?token=${token}`);
    return false;
  }
}
