<div class="container-row">
    <div>
        <button mat-button class="btn-create-test" (click)="createTest()" [ngClass]="{'d-none':isEditing}">
            <mat-icon>add</mat-icon>
            CRIAR TESTE
        </button>
    </div>
</div>

<div class="main p-2">
    <div class="row">
        <!-- LISTA DE TESTES -->
        <div class="col-sm-4">
            <div class="container-column">
                <h1>BANCO DE TESTES</h1>
                <!-- <div class="filter-search-box">
                    <mat-form-field class="search" appearance="outline">
                    <mat-label>Busca</mat-label>
                    <input matInput #search type="text" placeholder="Digite aqui.." >
                    <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div> -->
                <div class="test-list" *ngFor="let test of tests" #testList>
                    <mat-card class="tests" (click)="testSelect(test)">
                        <div class="test-image">
                            <img src="{{ test?.image || assets_path + 'assets/images/capas_no_image.png'}}" width="60"
                                height="50">
                        </div>
                        <div class="test-description" aria-roledescription="button">
                            <Label>{{test?.name}}</Label>
                            <p>{{test?.description}}</p>
                        </div>
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </mat-card>
                </div>
            </div>
        </div>

        <!-- TELA DO MOBILE -->
        <div class="col-sm-4">
            <div class="container-column-2" [hidden]="!testSelected">
                <ng-container *ngIf="!isEditing">

                    <!-- <button mat-mini-fab aria-label="Example icon button with a menu icon" data-slide="prev">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button> -->

                    <app-mobile-screen [test]="testSelected"></app-mobile-screen>

                    <!-- <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" data-slide="next">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button> -->
                </ng-container>
                <ng-container *ngIf="isEditing">
                    <app-mobile-screen-edit [test]="testSelected" [microfrontend]="microfrontend"
                        [companyId]="companyId" [token]="token" (emitDataToParent)="mfeCreateTestsDataToParent($event)">
                    </app-mobile-screen-edit>
                </ng-container>
            </div>
        </div>

        <!-- OPÇÕES -->
        <div class="col-sm-4">
            <div class="container-column-3">
                <div class="box-questions">
                    <div class="list-question">
                        <mat-icon class="icon" [inlineSVG]="assets_path + 'assets/images/icons/list.svg'"
                            color="primary"></mat-icon>
                    </div>
                    <div class="box-column">
                        {{testSelected?.activities?.length || 0}}
                        <label>Telas</label>
                    </div>
                </div>

                <div class="box-questions">
                    <div class="list-question">
                        <mat-icon class="icon" [inlineSVG]="assets_path + 'assets/images/icons/check-box.svg'"
                            color="primary"></mat-icon>
                    </div>
                    <div class="box-column">
                        {{testSelected?.totalVacancyUse || 0}}
                        <div class="vacancyInUse">
                            <label>Vagas em uso</label>
                        </div>
                    </div>
                </div>

                <div class="box">
                    <h2>O que deseja fazer?</h2>
                    <!-- <div>
                <button mat-button class="btn-duplicate-test" (click)="testDuplicate(testSelected?._id)"
                [disabled]="!testSelected || (isEditing)">
                    <mat-icon>content_copy</mat-icon>
                    <label>DUPLICAR</label>
                </button>
            </div> -->
                    <div >
                            <button mat-button class="btn-edit-test" (click)="testEdit(testSelected?._id)"
                            [disabled]="!testSelected || (isEditing)">
                                <mat-icon>edit</mat-icon>
                                <label>EDITAR</label>
                            </button>
                    </div>
                    <!-- <div>
                <button mat-button class="btn-remove-test" (click)="testRemove(testSelected?._id)"
                [disabled]="!testSelected || (isEditing)">
                    <mat-icon>remove</mat-icon>
                    <label>EXCLUIR</label>
                </button>
            </div> -->
                    <div class="published-toggle">
                        <ng-container *ngIf="!isEditing">
                            <mat-slide-toggle color="primary" [checked]="testSelected?.active"
                            [disabled]="!testSelected || ((testSelected?.totalVacancyUse || 0) > 0)"
                            (change)="changeActiveStatus($event)">
                            PUBLICADO
                            </mat-slide-toggle>
                        </ng-container>
                    </div>
                    <div class="create-date">
                        <mat-icon>calendar_today</mat-icon>
                        <p>Criado em {{testSelected?.createDate | date:'dd/MM/yyy'}}</p>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
