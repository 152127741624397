<div class="container-fluid body">
  <div class="group-buttons">
    <button type="button" class="button-icon btn-green" aria-label="" (click)="backHome()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <a class="text" (click)="backHome()">Voltar para a página inicial</a>
  </div>

  <h1 class="title mt-80 mb-35">Telas customizadas</h1>
  <h2 class="subtitle">Etapa de criação de um novo teste</h2>

  <form [formGroup]="form" class="row">
    <div class="custom-card col-4 offset-1 col-xxl-3 offset-xxl-2">
      <h3 class="create-card-title">Qual será o nome do teste?</h3>
      <mat-form-field class="w-100 mt-20" appearance="outline">
        <mat-label>Digite o nome do teste</mat-label>
        <input type="name" matInput formControlName="name" (change)="changeName($event)">
        <mat-error *ngIf="name.errors">
          <p class="error-message" *ngIf="name.errors?.required">Campo obrigatório</p>
          <p class="error-message" *ngIf="name.errors?.minlength">O nome precisa ter mais que 5 caracteres</p>
          <p class="error-message" *ngIf="name.errors?.maxlength">O nome precisa ter menos que 20 caracteres</p>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="custom-card col-4 offset-2 col-xxl-3 offset-xxl-2">
      <h3 class="create-card-title">Selecione abaixo qual será o tipo das telas customizadas</h3>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Tipo das telas customizadas</mat-label>
        <mat-select formControlName="rule">
          <mat-option *ngFor="let type of testTypeOption" [value]="type.value">
            {{ type.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="rule.errors">
          <p class="error-message" *ngIf="rule.errors?.required">Campo obrigatório</p>
        </mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="button-footer">
    <button type="button" class="btn btn-green" [ngClass]="{'btn-op-gray': !form.valid}"
      [disabled]="!form.valid || isSaveForm" (click)="submitForm()">
      Criar teste
    </button>
  </div>
</div>
