import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  @Input() answerText: FormGroup;
  @Input() screen;

  constructor() { }

  get min() { return this.answerText.get('min'); }
  get max() { return this.answerText.get('max'); }

  ngOnInit(): void {
    this.fillForm();
  }

  private fillForm() {
    if (this.screen) {
      this.answerText.get('min').patchValue(this.screen.content.questions[0].min || 1);
      this.answerText.get('max').patchValue(this.screen.content.questions[0].max || null);
    }
  }

  public inputMin(event) {
    if (event.target.value > 0) {
      this.answerText.get('max').setValidators([Validators.min(event.target.value)]);
      this.answerText.get('max').updateValueAndValidity();
    } else {
      this.answerText.get('max').setValidators([Validators.min(1)]);
      this.answerText.get('max').updateValueAndValidity();
    }
  }

  public inputMax(event) {
    if (event.target.value > 0) {
      this.answerText.get('min').setValidators([Validators.min(1), Validators.max(event.target.value)]);
      this.answerText.get('min').updateValueAndValidity();
    } else {
      this.answerText.get('min').setValidators([Validators.min(1)]);
      this.answerText.get('min').updateValueAndValidity();
    }
  }

}
