<ng-container [formGroup]="score">
  <div class="custom-card">
    <div class="position-icon-text group-title mb-20">
      <div class="fill-purple mr-3" [inlineSVG]="assets_path + 'assets/images/icons/purple-user-list.svg'"></div>
      Critério de avaliação
    </div>

    <mat-label class="mat-label">Escolha o tipo de avaliação:</mat-label>
    <br />
    <mat-radio-group aria-label="Escolha o tipo de avaliação" formControlName="type" class="radio-group">
      <mat-radio-button class="radio-button" value="boolean">Simples Sim/Não</mat-radio-button>
      <mat-radio-button class="radio-button" value="number">Nota de 0 a 10</mat-radio-button>
    </mat-radio-group>
    <mat-error *ngIf="type.errors">
      <p class="error-message" *ngIf="type.errors?.required">Campo obrigatório</p>
    </mat-error>
  </div>
</ng-container>
