import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import CustomEncoder from '../helpers/custom-encoder';

@Injectable({
  providedIn: 'root'
})
export class TestService {
  private baseUrl: string = environment.url;

  constructor(
    private httpClient: HttpClient
  ) { }

  private getHeaders(token: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  }

  public listTest(companyId: string, token: string, data: any): Observable<any[]> {
    const headers = this.getHeaders(token);
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(data)) {
      httpParams = httpParams.append(key, data[key] instanceof Object ? JSON.stringify(data[key]) : data[key]);
    }

    return this.httpClient.get<any[]>(`${this.baseUrl}/companies/vacancy-tests/${companyId}`, { headers, params: httpParams });
  }

  public createTest(test: any, companyId: string, token: string): Observable<any> {
    const headers = this.getHeaders(token);

    return this.httpClient.post(`${this.baseUrl}/companies/vacancy-tests/${companyId}`, test, { headers });
  }

  public updateTest(companyId: string, token: string, testId: string, data: any): Observable<any> {
    const headers = this.getHeaders(token);

    return this.httpClient.patch<any>(`${this.baseUrl}/companies/vacancy-tests/${companyId}/${testId}`, data, { headers });
  }

  public showTest(companyId: string, token: string, testId: string): Observable<any> {
    const headers = this.getHeaders(token);

    return this.httpClient.get<any>(`${this.baseUrl}/companies/vacancy-tests/${companyId}/${testId}`, { headers });
  }

  public uploadFile(companyId: string, token: string, testId: string, fileType: string, file: any): Observable<any[]> {
    const headers = this.getHeaders(token);

    return this.httpClient.post<any[]>(`${this.baseUrl}/companies/vacancy-tests/${companyId}/${testId}/upload/file/${fileType}`, file, { headers });
  }

  public getTestCandidate(companyId: string, vacancyId: string, userId: string, stepId: string, testId: string, token: string): Observable<any> {
    const headers = this.getHeaders(token);

    return this.httpClient.get<any>(`${this.baseUrl}/companies/vacancy-tests/${companyId}/${vacancyId}/${userId}/${stepId}/${testId}`, { headers });
  }

  public getVacancyCandidates(companyId: string, vacancyId: string, testId: string, token: string, data: any): Observable<any> {
    const headers = this.getHeaders(token);
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(data)) {
      httpParams = httpParams.append(key, data[key] instanceof Object ? JSON.stringify(data[key]) : data[key]);
    }

    return this.httpClient.get<any>(`${this.baseUrl}/companies/vacancy-tests/${companyId}/${vacancyId}/${testId}`, { headers, params: httpParams });
  }

  public ratingQuestion(companyId: string, vacancyId: string, userId: string, stepId: string, testId: string, token: string, data: {}): Observable<any> {
    const headers = this.getHeaders(token);

    return this.httpClient.patch<any>(`${this.baseUrl}/companies/vacancy-tests/${companyId}/${vacancyId}/${userId}/${stepId}/${testId}`, data, { headers });
  }

  public saveTestStatus(companyId: string, vacancyId: string, userId: string, stepId: string, testId: string, token: string, data: {}): Observable<any> {
    const headers = this.getHeaders(token);

    return this.httpClient.post<any>(`${this.baseUrl}/companies/vacancy-tests/${companyId}/${vacancyId}/${userId}/${stepId}/${testId}`, data, { headers });
  }
}
