<div class="container">
  <!-- <button class="btn btn-link disabled">
    <img src="{{assets_path}}assets/images/icons/arrow-left-disabled.svg" alt="anterior">
  </button> -->

  <div class="group-phone">

    <div class="icon-input-group">
      <img src="{{assets_path}}assets/images/icons/pencil.svg" alt="Editar">
      <input maxlength="20" type="text" placeholder="Nome da tela"
        [(ngModel)]="screenName" (ngModelChange)="changeScreenName($event)" [disabled]="!canEdit">
    </div>
    <small class="input-note">* Campo obrigatório. Escolha um nome para a tela</small>

    <div class="phone-body">
      <div class="phone-detail">
        <div class="rectangle"></div>
        <div class="circle"></div>
      </div>
      <div class="phone-content">
        <div class="phone-content-components">
          <!-- Question Component -->
          <div class="components-basic components-text" [ngClass]="{'d-none': textQuestionComponent}">
            <button class="components-icon-basic components-icon-delete" (click)="removeComponent('question-text')" [disabled]="!canEdit">
              <img src="{{assets_path}}assets/images/icons/circle-delete.svg" alt="delete">
            </button>

            <textarea minlength="5" name="question-component-text" rows="1" placeholder="* Digite o texto"
            [(ngModel)]="questionText" (ngModelChange)="changeQuestionText($event)" [disabled]="!canEdit"></textarea>
          </div>

          <div class="components-basic components-file" [ngClass]="{'d-none': imageQuestionComponent}">
            <button class="components-icon-basic components-icon-delete" (click)="removeComponent('question-image')" [disabled]="!canEdit">
              <img src="{{assets_path}}assets/images/icons/circle-delete.svg" alt="delete">
            </button>

            <label for="customFileInputImage" class="file-input">
              <img src="{{assets_path}}assets/images/icons/white-link-symbol.svg" alt="upload">
              <span>* Adicionar imagem</span>
            </label>
            <input type="file" class="d-none" name="question-component-image" id="customFileInputImage" accept=".png,.jpg,.jpeg,.svg"
             (change)="selectImageFile($event)" [disabled]="!canEdit">

            <p class="message" [innerHtml]='msgImage' *ngIf="msgImage"></p>
            <div><img class="image-preview" [src]="urlImage" *ngIf="urlImage"></div>
          </div>

          <div class="components-basic components-file" [ngClass]="{'d-none': videoQuestionComponent}">
            <button class="components-icon-basic components-icon-delete" (click)="removeComponent('question-video')" [disabled]="!canEdit">
              <img src="{{assets_path}}assets/images/icons/circle-delete.svg" alt="delete">
            </button>
            <label for="customFileInputVideo" class="file-input">
              <img src="{{assets_path}}assets/images/icons/white-link-symbol.svg" alt="upload">
              <span>* Adicionar vídeo</span>
            </label>
            <input type="file" class="d-none" name="question-component-video" id="customFileInputVideo" accept="video/mp4"
            (change)="selectVideoFile($event)" [disabled]="!canEdit">
            <p class="message" [innerHtml]='msgVideo' *ngIf="msgVideo"></p>
            <div>
              <video class="video-preview" *ngIf="urlVideo" controls>
                <source [src]="urlVideo" type="video/mp4">
              </video>
            </div>
          </div>

          <!-- Answer Component -->
          <div class="components-basic components-answer" [ngClass]="{'d-none': textAnswerComponent}">
            <button class="components-icon-basic components-icon-delete" (click)="removeComponent('answer-text')" [disabled]="!canEdit">
              <img src="{{assets_path}}assets/images/icons/circle-delete.svg" alt="delete">
            </button>

            <label for="maxChar">O tamanho <strong>mínimo</strong> de caracteres da resposta é:</label>
            <input type="number" min="1" name="max-char" id="maxChar" placeholder="caracteres"
            [(ngModel)]="answerTextMin" (ngModelChange)="changeAnswerTextMin($event)" [disabled]="!canEdit">

            <label for="maxChar">O tamanho <strong>máximo</strong> de caracteres da resposta é:</label>
            <input type="number" min="1" name="max-char" id="maxChar" placeholder="caracteres"
            [(ngModel)]="answerTextMax" (ngModelChange)="changeAnswerTextMax($event)" [disabled]="!canEdit">

            <p class="message" [innerHtml]='msgTextMin' *ngIf="msgTextMin"></p>
            <p class="message" [innerHtml]='msgTextMax' *ngIf="msgTextMax"></p>
          </div>

          <div class="components-basic components-answer" [ngClass]="{'d-none': videoAnswerComponent}">
            <button class="components-icon-basic components-icon-delete" (click)="removeComponent('answer-video')" [disabled]="!canEdit">
              <img src="{{assets_path}}assets/images/icons/circle-delete.svg" alt="delete">
            </button>
            <label for="videoTime">Determine a duração máxima do vídeo em minutos:</label>
            <input type="number" min="1" max="4" name="video-time" id="videoTime" placeholder="tempo em minutos"
            [(ngModel)]="answerVideo" (ngModelChange)="changeAnswerVideo($event)" [disabled]="!canEdit">
            <p class="message" [innerHtml]='msgDurationVideo' *ngIf="msgDurationVideo"></p>
          </div>

          <div class="components-basic components-answer" [ngClass]="{'d-none': audioAnswerComponent}">
            <button class="components-icon-basic components-icon-delete" (click)="removeComponent('answer-audio')" [disabled]="!canEdit">
              <img src="{{assets_path}}assets/images/icons/circle-delete.svg" alt="delete">
            </button>
            <label for="audioTime">Determine a duração máxima do áudio em minutos:</label>
            <input type="number" min="1" max="4" name="audio-time" id="audioTime" placeholder="tempo em minutos"
            [(ngModel)]="answerAudio" (ngModelChange)="changeAnswerAudio($event)" [disabled]="!canEdit">
            <p class="message" [innerHtml]='msgDurationAudio' *ngIf="msgDurationAudio"></p>
          </div>

          <div class="components-quiz" [ngClass]="{'d-none': quiz1AnswerComponent}">
            <div class="components-quiz-alternatives" *ngFor="let item of answerQuiz; let i = index" [attr.data-index]="i">
              <app-asnwer-quiz1
                [index]="i"
                [letter]="item.letter"
                [quizText]="item.option"
                [right]="item.isCorrect"
                [canEdit]="canEdit"
                (selectAlternative)="removeAlternative($event)"
                (quizTextValue)="changeOptionValue($event)"
                (isRight)="changeIsCorrect($event)"></app-asnwer-quiz1>
            </div>

            <button
              class="components-icon-basic components-icon-plus" [disabled]="!canEdit"
              [ngClass]="{'d-none': quiz1Add}"
              (click)="addAlternatives()">
              <img src="{{assets_path}}assets/images/icons/plus.svg" alt="adicionar alternativa">
            </button>
          </div>

          <p class="message" [innerHtml]='msgQuiz1' *ngIf="msgQuiz1"></p>

          <div
            class="components-basic select-module"
            [ngClass]="{'d-none': fieldSelectComponent}"
            [ngClass]="{'mt-field-select': textQuestionComponent && imageQuestionComponent && videoQuestionComponent &&
           textAnswerComponent && videoAnswerComponent && audioAnswerComponent && quiz1AnswerComponent}"
            cdkDropList
            [cdkDropListData]="items"
            (cdkDropListDropped)="drop($event)">
            <p class="select-description"><strong>Arraste e solte aqui</strong><br> os componentes.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-box">
      <small>Para editar as informações dessa tela basta clicar sobre os textos e alternativas</small>
    </div>

  </div>

  <!-- <button class="btn btn-link disabled">
    <img src="{{assets_path}}assets/images/icons/arrow-right-disabled.svg" alt="seguinte">
  </button> -->
</div>
