import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterName'
})
export class FiltersPipe implements PipeTransform {

  filters = {
    name: 'Nome',
    rule: 'Tipo',
    active: 'Status',
    creator: 'Criador',
  };

  transform(value: string): string {
    return this.filters[value];
  }
}
