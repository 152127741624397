<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <fa-icon size="2xl" class="fill-blue d-flex justify-content-center mb-12" [icon]="faRegular.clock"></fa-icon>
  <h2 class="w-100 text-center title-modal">Histórico de correção</h2>

  <div class="scroll">
    <ng-container *ngFor="let evaluation of evaluations">
      <div class="custom-card mb-12" *ngIf="evaluation.newTestScore !== evaluation.oldTestScore">
        <h3 class="card-subtitle">Nota alterada para {{ evaluation.nota }}</h3>
      </div>
      <div class="custom-card mb-12" *ngIf="evaluation.newTestScore === evaluation.oldTestScore">
        <h3 class="card-subtitle mb-12">Mensagem adicionada</h3>
        <p class="text">{{ evaluation.observation }}</p>
      </div>
      <p class="text mb-20 ml-16">
        Usuário {{ evaluation.userManager && evaluation.userManager.firstName || '' }}
        {{ evaluation.userManager && evaluation.userManager.lastName || '' }}
        no dia {{ evaluation.date | date: 'dd/MM/yyyy' }}
        às {{ evaluation.date | date: 'HH:mm:ss' }}
      </p>
    </ng-container>
  </div>

  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Fechar</button>
  </div>
</div>
