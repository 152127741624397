<ng-container [formGroup]="multipleQuiz">
  <div class="mb-20" *ngIf="correctAlternative === -1 || alternatives.controls.length < 2">
    <p class="message-help">Atenção: </p>
    <p class="message-help" *ngIf="correctAlternative === -1">
      - Informe qual é a alternativa que deverá ser considerada como correta.
    </p>
    <p class="message-help" *ngIf="alternatives.controls.length < 2">
      - Para salvar a tela é necessário que pelo menos 2 alternativas sejam preenchidas.
    </p>
  </div>

  <div class="row mb-10 section-alternative">
    <ng-container formArrayName="alternatives" *ngFor="let alternative of alternatives.controls; index as i">
      <div class="col-10 alternative">
        <ng-container [formGroupName]="i">
          <mat-form-field class="w-100 mb-10" appearance="outline">
            <mat-label>Alternativa {{ alphabet[i] }}</mat-label>
            <input matInput type="text" placeholder="Digite a alternativa" formControlName="option"
              (change)="changeAlternative($event, i)">
            <mat-error *ngIf="alternative.get('option').errors">
              <p class="error-message" *ngIf="alternative.get('option').errors?.required">Campo obrigatório</p>
            </mat-error>
          </mat-form-field>
          <button type="button" class="button-icon btn-check" aria-label="Marcar alternativa como correta"
            ngbTooltip="Alternativa correta" tooltipClass="custom-tooltip" placement="top"
            (click)="selectCorrectAlternative(i)" [ngClass]="{'active': correctAlternative === i}">
            <mat-icon>check</mat-icon>
          </button>
        </ng-container>
      </div>
      <div class="col-2 mt-10">
        <button type="button" class="button-icon action-button" aria-label="Remover alternativa"
          ngbTooltip="Remover alternativa" tooltipClass="custom-tooltip" placement="left"
          (click)="removeAlternative(i)">
          <div class="fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/delete.svg'"></div>
        </button>
      </div>
    </ng-container>

  </div>

  <button type="button" class="w-100 button-add" aria-label="Abrir modal para reordenar as telas"
    [disabled]="blockAddAlternative" (click)="addAlternative()">
    <div class="mr-3 size-20" [inlineSVG]="assets_path + 'assets/images/icons/add-square.svg'">
    </div>
    Adicionar nova alternativa
  </button>
</ng-container>
