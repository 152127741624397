import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTest, Screen } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';
import { EditScreenNameComponent } from '../modal/edit-screen-name/edit-screen-name.component';
import CONSTANTS from '../../core/constants';
import { getScreens } from '../../core/helpers';
import { DeleteScreenComponent } from '../modal/delete-screen/delete-screen.component';

@Component({
  selector: 'app-list-screens',
  templateUrl: './list-screens.component.html',
  styleUrls: ['./list-screens.component.scss']
})
export class ListScreensComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() screens: Screen[];
  @Input() companyId: string;
  @Input() token: string;

  @Output() screensChange: EventEmitter<Screen[]> = new EventEmitter<Screen[]>();
  @Output() testChange: EventEmitter<BaseTest> = new EventEmitter<BaseTest>();

  public SCREENS_TYPES = CONSTANTS.SCREEN_OPTIONS_DEFAULT;
  public assets_path: string = environment.assets_path;

  constructor(
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (String(this.test.rule) === 'tests_with_weights') {
      this.SCREENS_TYPES = CONSTANTS.WEIGHTS_SCREEN_OPTIONS;
    } else if (String(this.test.rule) === 'tests_with_weights_profiles') {
      this.SCREENS_TYPES = CONSTANTS.WEIGHTS_PROFILES_SCREEN_OPTIONS;
    }
  }

  public openEditScreenNameModal(screen: Screen) {
    let modal = this.modalService.open(EditScreenNameComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.screen = screen;
    modal.componentInstance.test = this.test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.screens = getScreens(res, this.SCREENS_TYPES);
        this.screensChange.emit(this.screens);
      }
    }).catch(err => { });
  }

  public openDeleteScreenModal(screen: Screen) {
    let modal = this.modalService.open(DeleteScreenComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.screen = screen;
    modal.componentInstance.test = this.test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.test = res;
        this.testChange.emit(this.test);
        this.screens = getScreens(res, this.SCREENS_TYPES);
        this.screensChange.emit(this.screens);
      }
    }).catch(err => { });
  }

  public duplicateScreen(screen: Screen) {
    this.router.navigateByUrl(`companies/tests/${this.companyId}/edit-test/${this.test._id}/edit-screen/${screen.typeScreen}?screenId=${screen._id}&copy=true&token=${this.token}`);
  }

  public editScreen(screen: Screen) {
    this.router.navigateByUrl(`companies/tests/${this.companyId}/edit-test/${this.test._id}/edit-screen/${screen.typeScreen}?screenId=${screen._id}&token=${this.token}`);
  }

}
