import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TestService } from '../core/services/test.service';
import { environment } from '../../environments/environment';
import CONSTANTS from '../core/constants';
import { AlertChangesComponent } from '../modal/alert-changes/alert-changes.component';

@Component({
  selector: 'app-rating-tests',
  templateUrl: './rating-tests.component.html',
  styleUrls: ['./rating-tests.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RatingTestsComponent implements OnInit, AfterViewInit {
  public assets_path: string = environment.assets_path;
  @ViewChildren('testList') testList;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild('audioPlayer') audioplayer: ElementRef;
  presentations: any = [];
  questions: any = [];
  isEditing = false;
  questionTest: any = null;
  answerTest: any = null;
  questionSelected: any = null;
  answerSelected: any = null;
  statusTeste: 'AVAILABLE';
  QUESTIONS_TYPES = CONSTANTS.QUESTIONS_TYPES;
  RATINGS = CONSTANTS.RATINGS;
  companyId: any = null;
  vacancyId: any = null;
  userId: any = null;
  stepId: any = null;
  testId: any = null;
  token: any = null;
  rating: any = JSON.parse(JSON.stringify({
    activityId: null, // ID da questão
    userManager: null,
    scoreType: null, // Tipo da nota
    nota: null, // Nota,
    vacancyTest: null,
    observation: null,
    isCorrect: null
  }));
  searchTextChange: Subject<string> = new Subject<string>();
  observationChanged: boolean = false as boolean;
  enableApproved: boolean = false;
  helpMessage: string = '';

  constructor(
    private router: Router,
    private testService: TestService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  // tslint:disable-next-line: typedef
  async ngOnInit() {
    this.companyId = this.route.snapshot.params.companyId;
    this.vacancyId = this.route.snapshot.params.vacancyId;
    this.userId = this.route.snapshot.params.userId;
    this.stepId = this.route.snapshot.params.stepId;
    this.testId = this.route.snapshot.params.testId;
    this.token = this.route.snapshot.queryParams.token;

    if (localStorage.getItem('user')) {
      const userManager = JSON.parse(localStorage.getItem('user'));

      this.rating.userManager = {
        _id: userManager._id,
        firstName: userManager.firstName,
        lastName: userManager.lastName,
        email: userManager.email
      };
    }

    await this.getQuestionsAndAnswers();

    this.searchTextChange
      .pipe(
        debounceTime(800)
      )
      .subscribe(() => {
        this.ratingObservationQuestion();
      });
  }

  ngAfterViewInit(): void { }

  submitStatus(status: 'approved' | 'disapproved') {
    let modal = this.modalService.open(AlertChangesComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.title = 'Atenção!';
    modal.componentInstance.text = `Uma vez que o teste tenha seu status alterado para ${status === 'approved' ? 'Aprovado' : 'Reprovar'}, não será possível reverter a ação. Deseja continuar com a ação?`;

    const data = {
      status,
      userManager: this.rating.userManager && this.rating.userManager._id ? this.rating.userManager._id : '',
    };

    modal.result.then(res => {
      if (res === true) {
        this.enableApproved = false;
        this.helpMessage = '';

        this.testService.saveTestStatus(this.companyId, this.vacancyId, this.userId, this.stepId, this.testId, this.token, data)
          .subscribe((test: any) => {
            let userManagerName, date;
            if (test.history) {
              userManagerName = test.history.userManagerName || '';
              date = test.history.date
                ? new Date(test.history.date).toLocaleDateString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' })
                : null;
            }
            this.helpMessage = `Este teste já foi corrigido e ${status === 'approved' ? 'aprovado' : 'reprovado'} por ${userManagerName} na data ${date}.`;
          }, (error) => { });

      } else return;
    }).catch(err => { });
  }

  testEdit(testId: string): void {
    // tslint:disable-next-line: max-line-length
    this.router.navigateByUrl(`${this.route.snapshot.params.companyId}/create-test/${testId}?token=${this.route.snapshot.queryParams.token}`);
  }

  public getAnswerSelectedCalss(nota: string): string {
    let response = 'disable';
    if (this.questionSelected && String(this.questionSelected.contentFormat) !== 'multipleQuiz') {
      response = '';
    }

    return response;
  }

  public ratingQuestion(nota: string): void {
    if (!this.questionSelected || String(this.questionSelected.contentFormat) === 'multipleQuiz') {
      return;
    }

    if (nota && this.answerSelected) {
      if (nota === this.answerSelected.nota) {
        return;
      }

      this.rating.activityId = this.answerSelected.activityId;
      this.rating.scoreType = String('number');
      this.rating.nota = String(nota);
      this.rating.vacancyTest = this.questionSelected._id;
      this.answerSelected.nota = this.rating.nota;

      this.testService.ratingQuestion(this.companyId, this.vacancyId, this.userId, this.stepId, this.testId, this.token, this.rating)
        .subscribe(async (ratingQuestion) => {
          const index = ratingQuestion.answers.findIndex(answer => answer.activityId === String(this.questionSelected._id));
          if (index !== -1) {
            this.answerTest.answers = ratingQuestion.answers;
            this.answerSelected = ratingQuestion.answers[index];
          }
          this.checkEnableApproved(this.answerTest);
        }, (errorRating) => { });
    }
  }

  public ratingWithLikeQuestion(nota: string): void {
    if (!this.questionSelected || String(this.questionSelected.contentFormat) === 'multipleQuiz') {
      return;
    }

    if (nota && this.answerSelected) {
      if (nota === this.answerSelected.nota) {
        return;
      }

      this.rating.activityId = this.answerSelected.activityId;
      this.rating.scoreType = String('boolean');
      this.rating.nota = String(nota);
      this.rating.isCorrect = nota === '10';
      this.rating.vacancyTest = this.answerSelected.activityId;
      this.answerSelected.nota = this.rating.nota;

      this.testService.ratingQuestion(this.companyId, this.vacancyId, this.userId, this.stepId, this.testId, this.token, this.rating)
        .subscribe((ratingQuestion) => {
          const index = ratingQuestion.answers.findIndex(answer => answer.activityId === String(this.questionSelected._id));
          if (index !== -1) {
            this.answerTest.answers = ratingQuestion.answers;
            this.answerSelected = ratingQuestion.answers[index];
          }
          this.checkEnableApproved(this.answerTest);
        }, (errorRating) => { });
    }
  }

  onKeyUp(): void {
    this.searchTextChange.next();
  }

  onKeyDown(): void {
    this.searchTextChange.next();
  }

  ratingObservationQuestion(): void {
    if (!this.rating.observation || this.rating.observation.length === 0) {
      this.rating.observation = '';
    }

    if (this.answerSelected && this.rating) {
      this.rating.activityId = this.answerSelected.activityId;
      this.rating.scoreType = this.questionSelected.scoreType;
      this.rating.nota = this.answerSelected.nota;
      this.rating.vacancyTest = this.questionSelected._id;
      this.testService.ratingQuestion(this.companyId, this.vacancyId, this.userId, this.stepId, this.testId, this.token, this.rating)
        .subscribe((ratingQuestion) => {
          this.observationChanged = true;
          this.answerTest = ratingQuestion.answers;
          const index = ratingQuestion.answers.findIndex(answer => answer.activityId === String(this.questionSelected._id));
          if (index !== -1) {
            this.answerTest.answers = ratingQuestion.answers;
            this.answerSelected = ratingQuestion.answers[index];
          }
          this.setObservationChanged();
        }, (errorRating) => { });
    }
  }

  setObservationChanged(): void {
    setTimeout(() => {
      this.observationChanged = false;
    }, 2000);
  }

  checkEnableApproved(answerTest) {
    if (answerTest.status && answerTest.status !== 'pending') {
      this.enableApproved = false;
      let userManagerName, date;
      if (answerTest.history) {
        userManagerName = answerTest.history.userManagerName || '';
        date = answerTest.history.date
          ? new Date(answerTest.history.date).toLocaleDateString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' })
          : null;
      }
      this.helpMessage = `Este teste já foi corrigido e ${answerTest.status === 'approved' ? 'aprovado' : 'reprovado'} por ${userManagerName} na data ${date}.`;
    } else if (answerTest.beginDate && answerTest.finishDate) {
      this.enableApproved = this.answerTest?.answers
        .filter(answer => String(answer.nota) === "-1")
        .length ? false : true;

      this.helpMessage = 'Este teste ainda não foi corrigido. Para aprovar ou reprovar o teste de um candidato, por favor corrija todas as questões do teste.';
    } else if (answerTest.beginDate && !answerTest.finishDate) {
      this.enableApproved = false;
    }
  }

  // tslint:disable-next-line: typedef
  async getQuestionsAndAnswers() {
    this.testService.getTestCandidate(this.companyId,
      this.vacancyId,
      this.userId,
      this.stepId,
      this.testId,
      this.token).subscribe((answerTest) => {
        this.answerTest = answerTest;

        this.checkEnableApproved(answerTest);

        this.testService.showTest(this.route.snapshot.params.companyId,
          this.route.snapshot.queryParams.token,
          this.route.snapshot.params.testId
        )
          .subscribe((questionTest) => {
            this.questionTest = questionTest;
            this.separeteQuestions(questionTest);
          }, (errorResult) => { });
      }, (errorResult) => { });
  }

  refreshQuestionsAndAnswers(): void {
    this.testService.getTestCandidate(this.companyId,
      this.vacancyId,
      this.userId,
      this.stepId,
      this.testId,
      this.token).subscribe((answer) => {
        this.getAnswer(this.questionSelected);
      });
  }

  testSelect(test: any): void {
    this.questionSelected = test;

    // pega a resposta da questao selecionada
    this.getAnswer(this.questionSelected);

    // seta a colecao da questao selecionada para alterar
    this.rating.activityId = test._id;
  }

  getAnswer(questionTest): void {
    if (this.answerTest && this.answerTest.answers) {
      const indexAnswer = this.answerTest.answers.findIndex(answer => answer.activityId === String(questionTest._id));
      if (indexAnswer !== -1) {

        this.answerSelected = this.answerTest.answers[indexAnswer];
        // caso tenha observacao, popula na colacao de rating
        if (this.answerSelected.observation) {
          this.rating.observation = this.answerSelected.observation;
        } else {
          this.rating.observation = null;
        }
      }
    }
  }

  separeteQuestions(questionTest): void {
    this.presentations = questionTest.activities.filter(item => item.presentation);
    this.questions = questionTest.activities;
    // .filter(item => item.content && item.contentFormat && item.contentFormat !== String('multipleQuiz'));

    // inicializa com a primeira pergunta selecionada.
    if (this.questions.length > 0) {
      this.questionSelected = this.questions[0];
      this.getAnswer(this.questions[0]);
    }
  }

  getQuestionType(type): string {
    if (type) {
      const index = this.QUESTIONS_TYPES.findIndex(item => item.value === String(type));
      if (index !== -1) {
        return this.QUESTIONS_TYPES[index].name;
      } else {
        return null;
      }
    }
    return null;
  }

  nextQuestion(): void {
    if (this.questionSelected && this.questions && this.questions.length > 0) {
      const indexQuestion = this.questions.findIndex(item => item._id === this.questionSelected._id);
      if (indexQuestion !== -1) {
        if (this.questions[indexQuestion + 1]) {
          this.questionSelected = this.questions[indexQuestion + 1];
        } else {
          this.questionSelected = this.questions[0];
        }
        this.getAnswer(this.questionSelected);
      }
    } else {
      this.questionSelected = this.questions[0];
    }
  }

  toggleVideo(event: any): void {
    this.videoplayer.nativeElement.play();
  }

  toggleAudio(event: any): void {
    this.audioplayer.nativeElement.play();
  }

  previousQuestion(): void {
    if (this.questionSelected && this.questions && this.questions.length > 0) {
      const indexQuestion = this.questions.findIndex(item => item._id === this.questionSelected._id);
      if (indexQuestion !== -1) {
        if (this.questions[indexQuestion - 1]) {
          this.questionSelected = this.questions[indexQuestion - 1];
        } else {
          this.questionSelected = this.questions[this.questions.length - 1];
        }
        this.getAnswer(this.questionSelected);
      }
    } else {
      this.questionSelected = null;
    }
  }

  isUrl(str): boolean {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
}
