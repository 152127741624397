import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import CONSTANTS from 'src/app/core/constants';
import { UserManagerService } from 'src/app/core/services/user-manager.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  @Input() companyId: string;
  @Input() token: string;

  @Output() filtersChange: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;
  public testTypeOption: any = [];
  public creatorOption: any = [];
  private filters: any = {};
  public filterBadges: any = [];

  constructor(
    private userManagerService: UserManagerService,
    private formBuilder: FormBuilder,
  ) { }

  get name() { return this.form.get('name'); }
  get rule() { return this.form.get('rule'); }
  get active() { return this.form.get('active'); }
  get creator() { return this.form.get('creator'); }

  ngOnInit(): void {
    this.testTypeOption = Object.keys(CONSTANTS.TEST_TYPES).map(type => ({ value: type, name: CONSTANTS.TEST_TYPES[type] }));

    this.initForm();
    this.loadUsersManagers();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      name: [''],
      rule: [''],
      active: [''],
      creator: [''],
    });
  }

  private loadUsersManagers() {
    this.userManagerService.getUsersManagerByCompany(this.companyId, this.token)
      .subscribe(response => {
        if (!response.length) {
          return;
        }
        this.creatorOption = response
          .filter(creator => creator.role !== 'MANAGER')
          .map(creator => ({ name: `${creator.firstName} ${creator.lastName}`, value: creator._id }));

        this.creatorOption.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
  }

  private setBadge(filter) {
    if (!this.filterBadges.includes(filter)) {
      this.filterBadges.push(filter);
    }
  }

  private removeBadge(filter) {
    this.filterBadges = this.filterBadges.filter(badge => String(badge) !== String(filter));
  }

  public changeName(event: any) {
    this.name.patchValue(event.target.value.trim());
    if (this.name.value) {
      this.filters.name = this.name.value;
      this.setBadge('name');
    } else {
      delete this.filters.name;
      this.removeBadge('name');
    }
    this.filtersChange.emit(this.filters);
  }

  public changeType() {
    if (this.rule.value) {
      this.filters.rule = this.rule.value;
      this.setBadge('rule');
    } else {
      delete this.filters.rule;
      this.removeBadge('rule');
    }
    this.filtersChange.emit(this.filters);
  }

  public changeActive() {
    if (this.active.value !== '') {
      this.filters.active = this.active.value;
      this.setBadge('active');
    } else {
      delete this.filters.active;
      this.removeBadge('active');
    }
    this.filtersChange.emit(this.filters);
  }

  public changeCreator() {
    if (this.creator.value !== '') {
      this.filters.creator = this.creator.value;
      this.setBadge('creator');
    } else {
      delete this.filters.creator;
      this.removeBadge('creator');
    }
    this.filtersChange.emit(this.filters);
  }

  public removeFilter(filter) {
    if (filter == null) {
      this.form.reset();
      this.filters = {};
    } else {
      this.form.get(`${filter}`).patchValue('');
      delete this.filters[filter];
    }
    this.filtersChange.emit(this.filters);
  }

}
