<h2 class="title">Preview</h2>

<div class="iphone-x">
  <i>Speaker</i>
  <b>Camera</b>
  <div class="iphone-content">
    <div class="position-center">
      <div class="preview-component" *ngIf="text.length">
        <button mat-icon-button class="btn-delete" aria-label="Remover o texto de intrução" (click)="delete('text')">
          <mat-icon class="size-30 color-green">remove</mat-icon>
        </button>
        <p class="preview-text">{{ text }}</p>
      </div>
      <div class="preview-component" *ngIf="image">
        <button mat-icon-button class="btn-delete" aria-label="Remover a imagem de instrução" (click)="delete('image')">
          <mat-icon class="size-30 color-green">remove</mat-icon>
        </button>
        <img class="image-size" [src]="image">
      </div>
      <div class="preview-component" *ngIf="video">
        <button mat-icon-button class="btn-delete" aria-label="Remover o vídeo de instrução" (click)="delete('video')">
          <mat-icon class="size-30 color-green">remove</mat-icon>
        </button>
        <video class="video-preview" controls>
          <source [src]="video" type="video/mp4">
        </video>
      </div>
      <div class="preview-component"
        *ngIf="typeScreen === 'multipleQuiz' && rule === 'evaluative_tests' && alternatives.length">
        <ol type="A" class="mb-0">
          <ng-container *ngFor="let alternative of alternatives">
            <li class="my-4">{{ alternative.option }}</li>
          </ng-container>
        </ol>
      </div>
      <div class="preview-component"
        *ngIf="typeScreen !== 'instruction' && (rule === 'tests_with_weights' || rule === 'tests_with_weights_profiles')  && alternativesWeight.length">
        <ol type="A" class="mb-0">
          <ng-container *ngFor="let alternative of alternativesWeight">
            <li class="my-4">{{ alternative.option }}</li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>
