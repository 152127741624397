<div class="container-fluid body">
  <ng-container *ngIf="!load">
    <div class="group-buttons">
      <button type="button" class="btn btn-green" (click)="createTest()">
        <mat-icon class="mr-2">add</mat-icon>
        Criar novo teste
      </button>
    </div>

    <div class="row">
      <div class="col-4 col-xxl-3">
        <app-filters [companyId]="companyId" [token]="token" (filtersChange)="getTest($event)"></app-filters>
      </div>

      <div class="col-7 offset-05 col-xxl-8 offset-xxl-05">
        <app-list-tests [(tests)]="tests" [companyId]="companyId" [token]="token" [pagLog]="pagLog"
          (pagLogChange)="getTest()" [loadTest]="loadTest"></app-list-tests>
      </div>
    </div>
  </ng-container>

  <div *ngIf="load" class="load-position">
    <mat-spinner class="my-4" [diameter]="60"></mat-spinner>
  </div>
</div>
