import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseTest } from 'src/app/models/test.model';
import CONSTANTS from 'src/app/core/constants';
import { environment } from 'src/environments/environment';
import { PublishTestComponent } from 'src/app/core/modal/publish-test/publish-test.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() index: number;
  @Input() companyId: string;
  @Input() token: string;

  @Output() testChange: EventEmitter<BaseTest> = new EventEmitter<BaseTest>();

  public TEST_TYPES = CONSTANTS.TEST_TYPES;
  public PERIODIC_AVAILABILITY = CONSTANTS.PERIODIC_AVAILABILITY;
  public assets_path: string = environment.assets_path;
  public periodicAvailability;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.periodicAvailability = this.PERIODIC_AVAILABILITY
      .find(periodic => periodic.value === this.test.periodicAvailability);
  }

  public openPublishTestModal() {
    let modal = this.modalService.open(PublishTestComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.test = this.test;
    modal.componentInstance.companyId = this.companyId;
    modal.componentInstance.token = this.token;

    modal.result.then(res => {
      if (res) {
        this.test.active = res.active;
        this.testChange.emit(this.test);
      }
    }).catch(err => { });
  }

  public editTest() {
    this.close();
    this.router.navigateByUrl(`companies/tests/${this.companyId}/edit-test/${this.test._id}?token=${this.token}`);
  }

  public close() {
    this.activeModal.close();
  }

}
