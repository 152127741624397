export default {
  ALLOWED_IMAGE: {
    SIZE: 1 * 1024 * 1024,
    MIMETYPE: [
      'image/png',
      'image/jpg',
      'image/svg',
      'image/jpeg',
    ]
  },
  ALLOWED_VIDEO: {
    SIZE: 250 * 1024 * 1024,
    MIMETYPE: [
      'video/mp4',
    ]
  },
  RATINGS: [{
    value: '1',
    name: '1'
  },
  {
    value: '2',
    name: '2'
  },
  {
    value: '3',
    name: '3'
  },
  {
    value: '4',
    name: '4'
  },
  {
    value: '5',
    name: '5'
  },
  {
    value: '6',
    name: '6'
  },
  {
    value: '7',
    name: '7'
  },
  {
    value: '8',
    name: '8'
  },
  {
    value: '9',
    name: '9'
  },
  {
    value: '10',
    name: '10'
  }
  ],
  QUESTIONS_TYPES: [{
    value: 'video',
    name: 'Vídeo'
  },
  {
    value: 'text',
    name: 'Texto'
  },
  {
    value: 'multipleQuiz',
    name: 'Quiz 2'
  }
  ],
  TEST_PERIOD: [{
    value: 'not_redone',
    name: 'O teste não pode ser refeito'
  }, {
    value: 'redone_6_months',
    name: 'O teste pode ser refeito a cada 6 meses'
  }, {
    value: 'redone_vacancy',
    name: 'O teste pode ser refeito em uma vaga diferente'
  }],
  TEST_TYPES: {
    'content_screens': 'Telas de conteúdo',
    'evaluative_tests': 'Testes avaliativos',
    'tests_with_weights': 'Testes com peso',
    'tests_with_weights_profiles': 'Testes com peso e atributos',
  },
  TEST_STATUS: {
    'pending': 'Indefinido',
    'approved': 'Aprovado',
    'disapproved': 'Desaprovado',
  },
  TEST_OPTIONS: [
    'instruction',
    'text',
    'video',
    'audio',
    'multipleQuiz',
    'weightQuiz',
    'weightProfileQuiz',
  ],
  SCREEN_OPTIONS_DEFAULT: [{
    name: 'Instrução',
    value: 'instruction'
  }, {
    name: 'Resposta em texto',
    value: 'text'
  }, {
    name: 'Resposta em vídeo',
    value: 'video'
  }, {
    name: 'Resposta em áudio',
    value: 'audio'
  }, {
    name: 'Quiz múltipla escolha',
    value: 'multipleQuiz'
  },
    // {
    //   name: 'Quiz verdadeiro ou falso',
    //   value: 'booleanQuiz'
    // }
  ],
  WEIGHTS_SCREEN_OPTIONS: [{
    name: 'Instrução',
    value: 'instruction'
  }, {
    name: 'Quiz com pesos',
    value: 'weightQuiz'
  }],
  WEIGHTS_PROFILES_SCREEN_OPTIONS: [{
    name: 'Instrução',
    value: 'instruction'
  }, {
    name: 'Quiz com pesos e atributos',
    value: 'weightProfileQuiz'
  }],
  PROFILE_DEFAULT: [{
    name: 'Sem atributo definido' // Definido para as alternativas sem perfis, deve ser o primeiro perfil de todos.
  }, {
    name: 'Proatividade'
  }, {
    name: 'Paciência'
  }, {
    name: 'Organização'
  }],
  PERIODIC_AVAILABILITY: [{
    name: 'O teste não pode ser refeito',
    value: 'NEVER'
  }, {
    name: 'O teste pode ser refeito a cada 6 meses',
    value: 'BI-ANNUAL'
  }, {
    name: 'O teste pode ser refeito a cada 1 ano (12 meses)',
    value: 'ANNUAL'
  }, {
    name: 'O teste pode ser refeito em uma vaga diferente',
    value: 'EVERY'
  }],
  GENDER: {
    male: 'Masculino',
    female: 'Feminino',
    other: 'Outro',
    binaryGenders: [
      'Feminino',
      'Masculino',
      'Mulher Cisgênero',
      'Homem Cisgênero'
    ]
  },
};
