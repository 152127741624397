<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <fa-icon size="2xl" class="fill-blue d-flex justify-content-center mb-12" [icon]="faRegular.clock"></fa-icon>
  <h2 class="w-100 text-center title-modal">Observação interna</h2>

  <div *ngFor="let history of observationHistory">
    <div class="custom-card mb-12" *ngIf="history.observation">
      <p class="text">{{ history.observation }}</p>
    </div>
    <p class="text mb-20 ml-16">
      Usuário {{ history.userManager && history.userManager.firstName || '' }}
      {{ history.userManager && history.userManager.lastName || '' }}
      no dia {{ history.date | date: 'dd/MM/yyyy' }}
      às {{ history.date | date: 'HH:mm:ss' }}
    </p>
  </div>

  <form [formGroup]="form" class="mb-24">
    <div class="custom-card">
      <mat-form-field class="w-100">
        <mat-label>Observação</mat-label>
        <textarea matInput #input placeholder="Digite a sua observação" rows="5" formControlName="text"
          (change)="changeText($event)"></textarea>
        <mat-error *ngIf="textField.errors">
          <p class="error-message" *ngIf="textField.errors?.required">Campo obrigatório</p>
          <p class="error-message" *ngIf="textField.errors?.minlength">A observação precisa ter pelo menos 5
            caracteres</p>
        </mat-error>
        <mat-hint align="end">{{input.value?.length || 0}} caracteres</mat-hint>
      </mat-form-field>
    </div>
  </form>

  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Cancelar</button>
    <button class="btn btn-green mx-4" [ngClass]="{'btn-op-gray': !form.valid}" type="submit" [disabled]="!form.valid"
      (click)="submitForm()">Salvar observação interna</button>
  </div>
</div>
