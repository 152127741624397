import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseTest, Screen } from '../models/test.model';
import { TestService } from '../core/services/test.service';
import CONSTANTS from '../core/constants';
import { getScreens } from '../core/helpers';

@Component({
  selector: 'app-edit-custom-screens',
  templateUrl: './edit-custom-screens.component.html',
  styleUrls: ['./edit-custom-screens.component.scss']
})
export class EditCustomScreensComponent implements OnInit {
  SCREENS_TYPES = [{
    name: 'Instrução',
    value: 'instruction'
  }];

  public load: boolean = true;
  public test: BaseTest;
  public screens: Screen[] = [];
  public companyId: string = '' as string;
  public testId: string = '' as string;
  public token: string = '' as string;

  constructor(
    private testService: TestService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.companyId = this.route.snapshot.params.companyId;
    this.testId = this.route.snapshot.params.testId;
    this.token = this.route.snapshot.queryParams.token;
    this.getTest();
  }

  private getTest() {
    this.testService.showTest(this.companyId, this.token, this.testId)
      .subscribe((test: any) => {
        this.test = test;
        this.setScreenOptions();
        this.screens = getScreens(test, this.SCREENS_TYPES);
        this.load = false;
      }, (err) => {
      });
  }

  private setScreenOptions() {
    if (String(this.test.rule) === 'evaluative_tests') {
      this.SCREENS_TYPES = CONSTANTS.SCREEN_OPTIONS_DEFAULT;
    } else if (String(this.test.rule) === 'tests_with_weights') {
      this.SCREENS_TYPES = CONSTANTS.WEIGHTS_SCREEN_OPTIONS;
    } else if (String(this.test.rule) === 'tests_with_weights_profiles') {
      this.SCREENS_TYPES = CONSTANTS.WEIGHTS_PROFILES_SCREEN_OPTIONS;
    }
  }

  public goHomepage() {
    this.router.navigateByUrl(`companies/tests/${this.companyId}?token=${this.token}`);
  }

}
