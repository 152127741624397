import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {

  @Input() content: string;
  @Input() limit: number;

  public isContentToggled: boolean = false;
  public toggleAllowed: boolean = false;
  private nonEditedContent: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['content'] !== undefined) {
      this.setContent(changes.content.currentValue);
    }
  }

  private setContent(content: string) {
    this.nonEditedContent = content;
    this.toggleAllowed = this.content.length > this.limit;
    this.content = this.toggleAllowed ? this.formatContent(content) : content;
  }

  private formatContent(content: string) {
    return `${content.slice(0, this.limit)}...`;
  }

  public toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    this.content = this.isContentToggled
      ? this.nonEditedContent
      : this.formatContent(this.content);
  }

}
