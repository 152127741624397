<h1 class="title ml-32 mb-16">Filtros</h1>
<div class="custom-card">
  <div class="custom-card-body">
    <form [formGroup]="form">
      <div class="d-flex mb-12">
        <mat-form-field class="w-100">
          <mat-label>Buscar pelo nome</mat-label>
          <input type="name" matInput formControlName="name" (change)="changeName($event)">
        </mat-form-field>
        <mat-icon class="icon-30 color-blue mt-12">search</mat-icon>
      </div>

      <div>
        <h4 class="filter-card-title">Tipo de teste</h4>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Selecione o tipo do teste</mat-label>
          <mat-select formControlName="rule" (selectionChange)="changeType()">
            <mat-option selected value="">Todos os tipos</mat-option>
            <mat-option *ngFor="let type of testTypeOption" [value]="type.value">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <h4 class="filter-card-title">Status do teste</h4>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Selecione o status do teste</mat-label>
          <mat-select formControlName="active" (selectionChange)="changeActive()">
            <mat-option selected value="">Todos os status</mat-option>
            <mat-option value="true">Publicado</mat-option>
            <mat-option value="false">Não publicado</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <h4 class="filter-card-title">Criador do teste</h4>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Selecione o(s) criador(es)</mat-label>
          <mat-select formControlName="creator" (selectionChange)="changeCreator()">
            <mat-option selected value="">Todos os criadores</mat-option>
            <mat-option *ngFor="let creator of creatorOption" [value]="creator.value">
              {{creator.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
  <app-badge [(filters)]="filterBadges" (selectedFilter)="removeFilter($event)"></app-badge>
</div>
