import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArrayValidators, getAlphabet } from 'src/app/core/helpers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-multiple-quiz',
  templateUrl: './multiple-quiz.component.html',
  styleUrls: ['./multiple-quiz.component.scss']
})
export class MultipleQuizComponent implements OnInit {

  @Input() multipleQuiz: FormGroup;
  @Input() screen;
  @Input() correctAlternative: number;

  @Output() correctAlternativeChange: EventEmitter<number> = new EventEmitter<number>();

  public assets_path: string = environment.assets_path;
  public alphabet: string[] = [];
  public blockAddAlternative: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  get alternatives(): FormArray {
    return this.multipleQuiz.get('alternatives') as FormArray;
  }

  ngOnInit(): void {
    this.alphabet = getAlphabet();
    this.setValidation();
    this.checkAlternatives();
  }

  private setValidation() {
    this.alternatives.setValidators(ArrayValidators.betweenLength(2, 10));
    this.alternatives.at(0).get('option').setValidators([Validators.required]);
    this.alternatives.at(0).get('option').markAsTouched();
    this.alternatives.at(0).get('option').updateValueAndValidity();
  }

  private checkAlternatives() {
    if (this.screen) {
      let alternatives = this.screen.content.questions[0].alternatives || [];
      this.blockAddAlternative = alternatives.length > 9;
    }
  }

  public changeAlternative(event: any, index: number) {
    this.alternatives.at(index).get('option').patchValue(event.target.value.trim());
  }

  public selectCorrectAlternative(index: number) {
    if (index === this.correctAlternative) return;
    this.correctAlternative = index;
    this.correctAlternativeChange.emit(this.correctAlternative);
  }

  public removeAlternative(index: number) {
    this.blockAddAlternative = false;
    this.alternatives.removeAt(index);
    if (this.correctAlternative !== -1) {
      if (index === this.correctAlternative) {
        this.correctAlternative = -1;
      } else if (index < this.correctAlternative) {
        this.correctAlternative--;
      }
      this.correctAlternativeChange.emit(this.correctAlternative);
    }
  }

  public addAlternative() {
    this.alternatives.push(this.formBuilder.group({
      option: ['', Validators.required],
    }));
    this.alternatives.at(this.alternatives.length - 1).get('option').markAsTouched();
    if (this.alternatives.controls.length > 9) {
      this.blockAddAlternative = true;
    } else {
      this.blockAddAlternative = false;
    }
  }

}
