import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileUploadModule } from 'ng2-file-upload';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EditGeneralTestInformationComponent } from "./edit-general-test-information/edit-general-test-information.component";
import { SortScreensComponent } from "./sort-screens/sort-screens.component";
import { EditScreenNameComponent } from "./edit-screen-name/edit-screen-name.component";
import { DeleteScreenComponent } from "./delete-screen/delete-screen.component";
import { CreateProfileComponent } from "./create-profile/create-profile.component";
import { ConfigPeriodicAvailabilityComponent } from "./config-periodic-availability/config-periodic-availability.component";

const modals = [
  EditGeneralTestInformationComponent,
  SortScreensComponent,
  EditScreenNameComponent,
  DeleteScreenComponent,
  CreateProfileComponent,
  ConfigPeriodicAvailabilityComponent
];

@NgModule({
  declarations: modals,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InlineSVGModule.forRoot(),
    NgbDatepickerModule,
    NgbModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    DragDropModule,
    FileUploadModule,
  ],
  entryComponents: modals,
  exports: modals
})
export class EditCustomModalModule { }
