import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { ActivatedRoute, Router } from '@angular/router';
import { TestService } from '../core/services/test.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-create-tests',
  templateUrl: './create-tests.component.html',
  styleUrls: ['./create-tests.component.scss']
})
export class CreateTestsComponent implements OnInit, AfterViewInit, OnChanges {
  public assets_path: string = environment.assets_path;
  @Input() microfrontend: boolean = false as boolean;
  @Input() companyId: string = '' as string;
  @Input() token: string = '' as string;
  @Output() emitDataToParent = new EventEmitter<any>();
  @ViewChildren('testList') testList;

  // Etapa: Define se os dados passados via @Input foram carregados.
  private ifLoaded: boolean = false as boolean;

  isEditing = false;
  tests = [];
  testSelected: any;

  constructor(
    private router: Router,
    private testService: TestService,
    private route: ActivatedRoute
  ) {
    this.tests = [];
    this.testSelected = this.tests[0];
  }

  ngOnInit(): void {
    if (!this.microfrontend) {
      this.companyId = this.route.snapshot.params.companyId;
      this.token = this.route.snapshot.queryParams.token;
    }

    // this.testService.listTest(this.companyId, this.token)
    //     .subscribe((test: any) => {
    //         if (test) {
    //             this.tests = test;
    //             if (this.tests.length) {
    //                 this.testSelected = this.tests[0];
    //             }
    //         }
    //     }, (error) => {
    //         if (this.microfrontend) {
    //             this.emitDataToParent.emit({
    //                 access: false
    //             });
    //         } else {
    //             this.router.navigate(['/']);
    //         }
    //     });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.ifLoaded) {
      this.ifLoaded = true;
      this.ngOnInit();
    }
  }

  public changeActiveStatus($event): void {
    if ((this.testSelected?.totalVacancyUse || 0) > 0) {
      return;
    }

    if ($event) {
      this.testSelected.active = $event.checked;
      this.testService.updateTest(this.companyId, this.token, this.testSelected._id, {
        vacancyTest: {
          active: $event.checked
        }
      })
        .subscribe((test: any) => { }, (error) => { });
    }
  }

  ngAfterViewInit(): void { }

  testEdit(testId: string): void {
    if (this.microfrontend) {
      this.mfeCreateTestsDataToParent({
        testId
      });
    } else {
      this.router.navigateByUrl(`companies/tests/${this.companyId}/create-test/${testId}?token=${this.token}`);
    }
  }

  mfeCreateTestsDataToParent($event): void {
    if ($event) {
      this.emitDataToParent.emit({
        testEditionEnabled: true,
        testId: $event.testId
      });
    }
  }

  testSelect(test: any): void {
    this.testSelected = test;
  }

  createTest(): void {
    const newTest = {
      name: 'teste novo',
      description: 'Descrição do teste',
      img: '',
      questions: 0,
      vacancyInUse: 0
    };

    this.tests.push(newTest);
    this.testSelect(newTest);
    this.isEditing = true;

    this.testList.forEach(element => {
      element.nativeElement.style.pointerEvents = 'none';
      element.nativeElement.classList.add('disabledTest');
    });
  }
}
