import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TestService } from 'src/app/core/services/test.service';
import { BaseTest } from 'src/app/models/test.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sort-screens',
  templateUrl: './sort-screens.component.html',
  styleUrls: ['./sort-screens.component.scss']
})
export class SortScreensComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() companyId: string;
  @Input() token: string;
  @ViewChildren("listItem") listItems: QueryList<ElementRef>;

  public activeItem: number;
  public isSave: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private testService: TestService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.listItems.changes.subscribe(_ => {
      this.setFocus();
    });
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  private setFocus() {
    this.listItems.toArray()[this.activeItem]?.nativeElement.focus();
  }

  public drop(event: CdkDragDrop<[]>) {
    moveItemInArray(this.test?.activities, event.previousIndex, event.currentIndex);
    this.activeItem = event.currentIndex;
    this.setFocus();
  }

  public handleKeyDown(event: KeyboardEvent, currentIndex: number) {
    let newIndex = currentIndex;
    if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      newIndex += 1;
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      newIndex -= 1;
    }
    moveItemInArray(this.test?.activities, currentIndex, newIndex);
    this.activeItem = newIndex;
    this.setFocus();
  }

  public saveTest() {
    if (!this.test.canEdit) {
      return;
    }

    this.isSave = true;
    this.testService.updateTest(
      this.companyId,
      this.token,
      this.test._id,
      {
        vacancyTest: {
          activities: this.test.activities
        }
      }
    )
      .subscribe((res) => {
        let message = `As telas foram reordenadas com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });
        this.activeModal.close(res);
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
      });
  }
}
