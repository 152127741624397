<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="fill-blue modal-img" [inlineSVG]="assets_path + 'assets/images/icons/edit.svg'"></div>
  <h2 class="w-100 text-center title-modal">Editar nome da tela</h2>

  <form [formGroup]="form" class="w-80 center-object">

    <mat-form-field class="w-100 mb-10" appearance="outline">
      <mat-label>Nome da tela</mat-label>
      <input matInput type="text" placeholder="Digite o nome da tela" formControlName="name"
        (change)="changeName($event)">
      <mat-error *ngIf="name.errors">
        <p class="error-message" *ngIf="name.errors?.required">Campo obrigatório</p>
        <p class="error-message" *ngIf="name.errors?.maxlength">O nome precisa ter menos que 20 caracteres</p>
      </mat-error>
    </mat-form-field>

  </form>

  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Cancelar</button>
    <button class="btn btn-green mx-4" [ngClass]="{'btn-op-gray': !form.valid}" type="submit"
      [disabled]="!form.valid || isSaveForm" (click)="submitForm()">Salvar</button>
  </div>
</div>
