<div class="d-flex align-items-center justify-content-between mb-24">
  <h3 class="group-screen-title">Correção |
    <span class="sub-title">{{ screen.scoreTypeName }}</span>
  </h3>
  <button
    *ngIf="screen.contentFormat !== 'multipleQuiz' && screen.contentFormat !== 'weightQuiz' && screen.contentFormat !== 'weightProfileQuiz'"
    class="mr-40 link" (click)="openObservationModal()">
    <fa-icon [icon]="faSolid.plus"></fa-icon>
    Adicionar observação interna
  </button>
</div>

<div *ngIf="screen.scoreType === 'boolean'" class="d-flex justify-content-center">
  <button class="btn-thumb mx-40" [ngClass]="{ 'active': !grade }" mat-icon-button aria-label="Errado"
    (click)="changeGrade(0)">
    <fa-icon size="2xl" transform="flip-h" [icon]="faRegular.thumbsDown"></fa-icon>
  </button>
  <button class="btn-thumb mx-40" [ngClass]="{ 'active': grade > 0 }" mat-icon-button aria-label="Certo"
    (click)="changeGrade(10)">
    <fa-icon size="2xl" [icon]="faRegular.thumbsUp"></fa-icon>
  </button>
</div>

<div *ngIf="screen.scoreType === 'number'" class="d-flex justify-content-center">
  <div *ngFor="let item of [].constructor(11); let i = index">
    <button class="btn-grade mx-8" [ngClass]="{ 'active': grade === i }" mat-button (click)="changeGrade(i)">
      {{ i }}
    </button>
  </div>
</div>

<div *ngIf="screen.scoreType === 'system'" class="d-flex justify-content-center mb-40">
  <div class="btn-thumb circle mx-40" [ngClass]="{'active': !screen.isCorrect }" aria-label="Errado">
    <fa-icon size="2xl" transform="flip-h" [icon]="faRegular.thumbsDown">
    </fa-icon>
  </div>
  <div class="btn-thumb circle mx-40" [ngClass]="{'active': screen.isCorrect }" aria-label="Certo">
    <fa-icon size="2xl" [icon]="faRegular.thumbsUp"></fa-icon>
  </div>
</div>

<div *ngIf="screen.scoreType === 'weight' && screen.nota !== -1" class="position-col mb-40">
  <p class="text answer mb-12">Pontuação atingida na questão:</p>
  <div *ngIf="screen.profileName === null" class="card-weight self-center">
    {{ screen.nota }} pontos
  </div>
  <div *ngIf="screen.profileName !== null" class="card-weight self-center">
    {{ screen.nota }} pontos |
    {{ (screen.profileName.length > 30) ? (screen.profileName | slice:0:30) + '...' :
    (screen.profileName) }}
  </div>
</div>

<div *ngIf="screen.scoreType !== 'weight' && screen.scoreType !== 'system'"
  class="d-flex justify-content-center mt-30 mb-40">
  <button type="button" class="btn btn-green" (click)="correctionQuestion()">Salvar
    correção</button>
</div>
