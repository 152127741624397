import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-asnwer-quiz1',
  templateUrl: './asnwer-quiz1.component.html',
  styleUrls: ['./asnwer-quiz1.component.scss']
})
export class AsnwerQuiz1Component implements OnInit {
  public assets_path: string = environment.assets_path;
  @Input() index;
  @Input() letter;
  @Input() canEdit;
  @Input() quizText: string;
  @Input() right = false;

  @Output() selectAlternative = new EventEmitter();
  @Output() quizTextValue = new EventEmitter();
  @Output() isRight = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  enableDisableRule(): void {
    this.right = !this.right;
    this.isRight.emit({ index: this.index, isCorrect: this.right });
  }

  removeAlternative(): void {
    this.selectAlternative.emit({ index: this.index });
  }

  changeQuizText(event): void {
    event = event.trim();
    this.quizText = event;
    this.quizTextValue.emit({ index: this.index, option: this.quizText });
  }
}
