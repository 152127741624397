import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { CreateProfileComponent } from 'src/app/edit-custom-screens/modal/create-profile/create-profile.component';
import { ArrayValidators, getAlphabet, replaceDiacritics } from 'src/app/core/helpers';
import { environment } from 'src/environments/environment';
import CONSTANTS from 'src/app/core/constants';

@Component({
  selector: 'app-multiple-quiz-weights',
  templateUrl: './multiple-quiz-weights.component.html',
  styleUrls: ['./multiple-quiz-weights.component.scss']
})
export class MultipleQuizWeightsComponent implements OnInit {

  @Input() multipleQuizWeight: FormGroup;
  @Input() screen;
  @Input() profiles;
  @Input() typeScreen: string;

  @Output() profilesChange: EventEmitter<any> = new EventEmitter<any>();

  public assets_path: string = environment.assets_path;
  public alphabet: string[] = [];
  public blockAddAlternative: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { }

  get alternatives(): FormArray {
    return this.multipleQuizWeight.get('alternatives') as FormArray;
  }

  ngOnInit(): void {
    this.alphabet = getAlphabet();
    this.setValidation();
    this.checkAlternatives();
    this.setProfiles();
  }

  private setProfiles(): void {
    CONSTANTS.PROFILE_DEFAULT.forEach((profile) => {
      const key = replaceDiacritics(profile.name);
      let profileIndex = this.profiles.findIndex((option) => String(option.key) === key);

      if (profileIndex === -1) {
        this.profiles.push({
          _id: uuidv4(),
          name: profile.name,
          key: key,
        });
      }
    });

    this.profilesChange.emit(this.profiles);
  }

  private setValidation(): void {
    this.alternatives.setValidators(ArrayValidators.betweenLength(2, 10));
    this.alternatives.at(0).get('option').setValidators([Validators.required]);
    this.alternatives.at(0).get('option').markAsTouched();
    this.alternatives.at(0).get('option').updateValueAndValidity();
    if (this.typeScreen === 'weightProfileQuiz') {
      this.alternatives.at(0).get('profileId').setValidators([Validators.required]);
      this.alternatives.at(0).get('profileId').markAsTouched();
      this.alternatives.at(0).get('profileId').updateValueAndValidity();
    }
    this.alternatives.at(0).get('profileValue').setValidators([Validators.required, Validators.min(0), Validators.max(1000)]);
    this.alternatives.at(0).get('profileValue').markAsTouched();
    this.alternatives.at(0).get('profileValue').updateValueAndValidity();
  }

  private checkAlternatives() {
    if (this.screen) {
      let alternatives = this.screen.content.questions[0].alternatives || [];
      this.blockAddAlternative = alternatives.length > 9;
    }
  }

  public changeAlternative(event: any, index: number) {
    this.alternatives.at(index).get('option').patchValue(event.target.value.trim());
  }

  public removeAlternative(index: number) {
    this.blockAddAlternative = false;
    this.alternatives.removeAt(index);
  }

  public addAlternative() {
    this.alternatives.push(this.formBuilder.group({
      option: ['', Validators.required],
      profileId: ['', this.typeScreen === 'weightProfileQuiz' ? Validators.required : Validators.nullValidator],
      profileValue: [null, [Validators.required, Validators.min(0), Validators.max(1000)]],
    }));

    this.alternatives.at(this.alternatives.length - 1).get('option').markAsTouched();
    this.alternatives.at(this.alternatives.length - 1).get('profileId').markAsTouched();
    this.alternatives.at(this.alternatives.length - 1).get('profileValue').markAsTouched();

    if (this.alternatives.controls.length > 9) {
      this.blockAddAlternative = true;
    } else {
      this.blockAddAlternative = false;
    }
  }

  private addProfile(res) {
    this.profiles.push({
      _id: uuidv4(),
      name: res.name,
      key: res.key,
    });
    this.profilesChange.emit(this.profiles);
  }

  public openCreateProfileModal() {
    let modal = this.modalService.open(CreateProfileComponent, { backdrop: 'static', size: 'lg' });
    modal.componentInstance.profiles = this.profiles;

    modal.result.then(res => {
      if (res) {
        this.addProfile(res);
      }
    }).catch(err => { });
  }
}
