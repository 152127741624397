import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-observation',
  templateUrl: './observation.component.html',
  styleUrls: ['./observation.component.scss']
})
export class ObservationComponent implements OnInit {

  @Input() observationHistory;

  public faRegular = {
    clock: faClock,
  };
  public form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) { }

  get textField() { return this.form.get('text'); }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      text: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  public changeText(event: any) {
    this.textField.patchValue(event.target.value.trim());
  }

  public submitForm() {
    this.close(this.textField.value);
  }

}
