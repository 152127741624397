export function getAlphabet() {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  return alpha.map((x) => String.fromCharCode(x));
}

export function replaceDiacritics(strToReplace: string) {
  if (!strToReplace || typeof strToReplace !== 'string') {
    strToReplace = String(strToReplace);
  }

  const diacritics: any[] = [
    /[\300-\306]/g, /[\340-\346]/g, // A, a
    /[\310-\313]/g, /[\350-\353]/g, // E, e
    /[\314-\317]/g, /[\354-\357]/g, // I, i
    /[\322-\330]/g, /[\362-\370]/g, // O, o
    /[\331-\334]/g, /[\371-\374]/g, // U, u
    /[\321]/g, /[\361]/g, // N, n
    /[\307]/g, /[\347]/g, // C, c
  ];

  const chars: string[] = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

  for (let i = 0; i < diacritics.length; i++) {
    strToReplace = strToReplace.replace(diacritics[i], chars[i]);
  }

  return String(String(strToReplace).match(/[a-zA-Z0-9]+/g)?.join('-')).toLowerCase();
};
