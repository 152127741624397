<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="fill-blue modal-img" [inlineSVG]="assets_path + 'assets/images/icons/delete.svg'">
  </div>
  <h2 class="w-100 text-center title-modal">Excluir tela</h2>

  <p class="w-80">
    A ação de exluir tela é irreversível. <br>
    Deseja seguir com a exclusão da tela <strong class="screen-name">{{ screen?.name }}</strong>?
  </p>

  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Cancelar</button>
    <button class="btn btn-green mx-4" type="submit" [disabled]="isSave" (click)="deleteScreen()">
      Excluir
    </button>
  </div>
</div>
