import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { TestService } from 'src/app/core/services/test.service';
import { ActivityBase, BaseTest } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';
import CONSTANTS from '../../core/constants';
import { initUploader, formatBytes, getAlphabet } from '../../core/helpers';

@Component({
  selector: 'app-edit-screen',
  templateUrl: './edit-screen.component.html',
  styleUrls: ['./edit-screen.component.scss']
})
export class EditScreenComponent implements OnInit {

  private SCREEN_OPTIONS = CONSTANTS.SCREEN_OPTIONS_DEFAULT;
  private ALLOWED_IMAGE = CONSTANTS.ALLOWED_IMAGE;
  private ALLOWED_VIDEO = CONSTANTS.ALLOWED_VIDEO;
  private url: string = environment.url;
  private testId: string = '';
  private screenId: string = '';
  private copy: boolean = false;
  private wrongFormate: boolean = false;
  private alphabet: string[] = [];

  public TEST_TYPES = CONSTANTS.TEST_TYPES;
  public assets_path: string = environment.assets_path;
  public companyId: string = '';
  public token: string = '';
  public load: boolean = true;
  public isSaveForm: boolean = false;
  public isQuestion: boolean = false;
  public blockScreen: boolean = false;
  public enableCorrectionChoice: boolean = false;
  public test: BaseTest;
  public screen: any;
  public typeScreen: string = '';
  public subTitle: string = '';
  public image: string = '';
  public video: string = '';
  public profiles = [];
  public form: FormGroup;
  public imageUploader: FileUploader;
  public videoUploader: FileUploader;
  public progress: number = 0;
  public errorFileMessage: string = '';
  public correctAlternative: number = -1;

  constructor(
    private testService: TestService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
  ) { }

  get instruction() { return this.form.get('instruction') as FormGroup; }
  get textField() { return this.instruction.get('text'); }
  get answerText() { return this.form.get('answerText') as FormGroup; }
  get record() { return this.form.get('record') as FormGroup; }
  get score() { return this.form.get('score') as FormGroup; }
  get multipleQuiz() { return this.form.get('multipleQuiz') as FormGroup; }
  get multipleQuizWeight() { return this.form.get('multipleQuizWeight') as FormGroup; }

  get alternatives(): FormArray {
    return this.multipleQuiz.get('alternatives') as FormArray;
  }

  get alternativesWeight(): FormArray {
    return this.multipleQuizWeight.get('alternatives') as FormArray;
  }

  ngOnInit(): void {

    this.companyId = this.route.snapshot.params.companyId;
    this.testId = this.route.snapshot.params.testId;
    this.typeScreen = this.route.snapshot.params.typeScreen;
    this.token = this.route.snapshot.queryParams.token;
    this.screenId = this.route.snapshot.queryParams.screenId;
    this.copy = this.route.snapshot.queryParams.copy ? true : false;

    if (this.typeScreen !== 'instruction') {
      this.isQuestion = true;
      if (this.typeScreen !== 'multipleQuiz' && this.typeScreen !== 'weightQuiz' && this.typeScreen !== 'weightProfileQuiz') {
        this.enableCorrectionChoice = true;
      }
    }

    this.alphabet = getAlphabet();

    this.initForm();
    this.getTest();
  }

  private initForm() {
    const dataQuiz = [{ option: ['', Validators.nullValidator] }];
    const dataQuizWeight = [{
      option: ['', Validators.nullValidator],
      profileId: ['', Validators.nullValidator],
      profileValue: [null, Validators.nullValidator],
    }];

    this.form = this.formBuilder.group({
      instruction: this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(20)]],
        text: ['', [Validators.minLength(5)]],
      }),
      answerText: this.formBuilder.group({
        min: [null, [Validators.min(1)]],
        max: [null, [Validators.min(1)]],
      }),
      record: this.formBuilder.group({
        maxDuration: [null, [Validators.min(1), Validators.max(4)]],
      }),
      score: this.formBuilder.group({
        type: ['', Validators.nullValidator],
      }),
      multipleQuiz: this.formBuilder.group({
        alternatives: this.formBuilder.array(
          dataQuiz.map(alternative => this.formBuilder.group(alternative)),
        ),
      }),
      multipleQuizWeight: this.formBuilder.group({
        alternatives: this.formBuilder.array(
          dataQuizWeight.map(alternative => this.formBuilder.group(alternative)),
        )
      }),
    });
  }

  private getTest() {
    this.testService.showTest(this.companyId, this.token, this.testId)
      .subscribe((test: any) => {
        this.test = test;
        this.setScreenOptions();
        this.setSubTitle();
        this.checkScreenTypeAllowed();
        if (this.test.profiles) {
          this.profiles = this.test.profiles
            .map(profile => ({ _id: profile._id, name: profile.name, key: profile.key }));
        }

        if (this.screenId) {
          this.screen = this.test.activities.find(activity => String(activity._id) === String(this.screenId));
          this.fillForm(this.screen);
        }

        this.configUpload();
        this.load = false;
      }, (err) => {
      });
  }

  private setScreenOptions() {
    if (String(this.test.rule) === 'evaluative_tests') {
      this.SCREEN_OPTIONS = CONSTANTS.SCREEN_OPTIONS_DEFAULT;
    } else if (String(this.test.rule) === 'tests_with_weights') {
      this.SCREEN_OPTIONS = CONSTANTS.WEIGHTS_SCREEN_OPTIONS;
    } else if (String(this.test.rule) === 'tests_with_weights_profiles') {
      this.SCREEN_OPTIONS = CONSTANTS.WEIGHTS_PROFILES_SCREEN_OPTIONS;
    }
  }

  private setSubTitle() {
    const screenName = this.SCREEN_OPTIONS.find(screen => screen.value === this.typeScreen)?.name || '';
    this.subTitle = `Tipo: ${screenName}`;
  }

  private checkScreenTypeAllowed() {
    if (this.test.rule === 'content_screens' && this.typeScreen !== 'instruction') {
      this.blockScreen = true;
    }
    const types = this.SCREEN_OPTIONS.map(option => option.value);
    if (!types.includes(this.typeScreen)) {
      this.blockScreen = true;
    }
  };

  private configUpload() {
    this.imageUploader = initUploader(
      `${this.url}/companies/vacancy-tests/${this.companyId}/${this.test._id}/upload/file/image`,
      this.token,
      this.ALLOWED_IMAGE.SIZE,
      this.ALLOWED_IMAGE.MIMETYPE
    );
    this.videoUploader = initUploader(
      `${this.url}/companies/vacancy-tests/${this.companyId}/${this.test._id}/upload/file/video`,
      this.token,
      this.ALLOWED_VIDEO.SIZE,
      this.ALLOWED_VIDEO.MIMETYPE
    );

    this.onWhenAddingFileFailedHandler(this.imageUploader, this.ALLOWED_IMAGE.SIZE, this.ALLOWED_IMAGE.MIMETYPE.join(', ').replace(/image\//g, ''));
    this.onWhenAddingFileFailedHandler(this.videoUploader, this.ALLOWED_VIDEO.SIZE, this.ALLOWED_VIDEO.MIMETYPE.join(', ').replace(/video\//g, ''));

    this.afterAddingFileHandler(this.imageUploader, 'image');
    this.afterAddingFileHandler(this.videoUploader, 'video');
  }

  private onWhenAddingFileFailedHandler(uploader: FileUploader, maxFileSize: number, allowedMimeType: string) {
    uploader.onWhenAddingFileFailed = (file: FileLikeObject, filter: any) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = `O arquivo tem ${formatBytes(file.size, 2)} e  ultrapassa o limite máximo de ${formatBytes(maxFileSize, 2)}`;
          break;
        case 'mimeType':
          message = `Escolha arquivos somente no formato ${allowedMimeType}`;
          break;
        default:
          message = `Erro na subida do arquivo ${file.name}`;
          break;
      }

      this.errorFileMessage = message;
      this.wrongFormate = true;
    };
  }

  private afterAddingFileHandler(uploader: FileUploader, type: string) {
    uploader.onAfterAddingFile = (file: FileItem) => {
      file.withCredentials = false;
      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent) => {
        const url = <string>reader.result;
        if (!this.wrongFormate) {
          if (type === 'image') {
            this.image = url;
          }
          if (type === 'video') {
            this.video = url;
          }
        }
      };

      reader.readAsDataURL(file._file);
    };
  }

  public changeFile(type: string) {
    if (type === 'image') {
      this.image = '';
      this.imageUploader.queue = [];
    } else if (type === 'video') {
      this.video = '';
      this.videoUploader.queue = [];
    }
    this.errorFileMessage = '';
    this.wrongFormate = false;
  }

  public deleteInstructionComponent(component) {
    if (component === 'text') {
      this.instruction.get('text').patchValue('');
    } else if (component === 'image') {
      this.image = null;
      this.imageUploader.queue = [];
    } else if (component === 'video') {
      this.video = null;
      this.videoUploader.queue = [];
    }
  }

  private fillForm(screen) {
    if (this.typeScreen === 'instruction') {
      this.image = screen.presentation.image || '';
      this.video = screen.presentation.video || '';
    } else {
      this.image = screen.content.questions[0].image || '';
      this.video = screen.content.questions[0].video || '';

      if (this.typeScreen === 'multipleQuiz' && this.test.rule === 'evaluative_tests') {
        let alternatives = this.screen.content.questions[0].alternatives || [];
        alternatives = alternatives.map(alternative => alternative.option);

        if (this.screen.content.questions[0].correctAnswer) {
          this.correctAlternative = this.alphabet.indexOf(this.screen.content.questions[0].correctAnswer);
        }

        for (const [index, alternative] of alternatives.entries()) {
          if (index) {
            this.alternatives.push(this.formBuilder.group({
              option: ['', Validators.required],
            }));
          }
          this.alternatives.at(index).get('option').patchValue(alternative);
          this.alternatives.at(index).get('option').updateValueAndValidity();
        }
      }

      if (this.typeScreen !== 'instruction' && (this.test.rule === 'tests_with_weights' || this.test.rule === 'tests_with_weights_profiles')) {
        let testAlternatives = this.screen.content.questions[0].alternatives || [];

        testAlternatives = testAlternatives
          .map(alternative => ({ option: alternative.option, profileId: alternative.profileId, profileValue: alternative.profileValue }));

        for (const [index, alternative] of testAlternatives.entries()) {
          if (index) {
            this.alternativesWeight.push(this.formBuilder.group({
              option: ['', Validators.required],
              profileId: ['', this.test.rule === 'tests_with_weights' ? Validators.nullValidator : Validators.required],
              profileValue: [null, [Validators.required, Validators.min(0), Validators.max(1000)]],
            }));
          }
          this.alternativesWeight.at(index).get('option').patchValue(alternative.option);
          this.alternativesWeight.at(index).get('option').updateValueAndValidity();
          if (this.test.rule === 'tests_with_weights_profiles') {
            this.alternativesWeight.at(index).get('profileId').patchValue(alternative.profileId);
            this.alternativesWeight.at(index).get('profileId').updateValueAndValidity();
          }
          this.alternativesWeight.at(index).get('profileValue').patchValue(alternative.profileValue);
          this.alternativesWeight.at(index).get('profileValue').updateValueAndValidity();
        }
      }
    }
  }

  public infoScreenValid(): boolean {
    if (this.blockScreen) {
      return true;
    }
    if (this.textField.value.length < 5 && !this.image && !this.video) {
      return true;
    }
    if (this.typeScreen === 'multipleQuiz' && this.test.rule === 'evaluative_tests') {
      if (this.correctAlternative === -1) {
        return true;
      }
    }
    if (!this.form.valid || this.isSaveForm) {
      return true;
    }
    return false;
  }

  private uploadFile(uploader: FileUploader, type: string, errorMessage: string) {
    uploader.queue[0].upload();

    uploader.onErrorItem = (item: any, response: any) => {
      this.toastrService.clear();
      this.toastrService.error(errorMessage, 'Erro na atualização');
      this.isSaveForm = false;
    };

    uploader.onCompleteItem = (item: any, response: any) => {
      const res = JSON.parse(response);
      const file = res.url;
      if (type === 'image') {
        this.updateTest(file, '');
      } else if (type === 'video') {
        this.updateTest('', file);
      }
    };
  }

  public submitForm() {
    if (!this.form.valid) {
      return;
    }
    this.isSaveForm = true;

    if (this.imageUploader.queue[0]) {
      this.uploadFile(this.imageUploader, 'image', 'Tivemos um problema na subida da imagem');
      return;
    }
    if (this.videoUploader.queue[0]) {
      this.uploadFile(this.videoUploader, 'video', 'Tivemos um problema na subida do vídeo');
      return;
    }

    // Populate image and video not edit
    const image = this.image
      ? this.typeScreen === 'instruction'
        ? this.screen.presentation.image
        : this.screen.content.questions[0].image
      : '';
    const video = this.video
      ? this.typeScreen === 'instruction'
        ? this.screen.presentation.video
        : this.screen.content.questions[0].video
      : '';
    this.updateTest(image, video);
  }

  private mountActivity(image?: string, video?: string): any {
    const data = {
      text: this.instruction.get('text').value || null,
      image: image || null,
      video: video || null,
    };

    if (this.typeScreen === 'instruction') {
      return data;
    }

    if (this.typeScreen === 'video' || this.typeScreen === 'audio') {
      data['maxDuration'] = this.record.get('maxDuration').value
        ? this.record.get('maxDuration').value * 60
        : 60;
    }

    if (this.typeScreen === 'text') {
      data['min'] = this.answerText.get('min').value || 1;
      data['max'] = this.answerText.get('max').value || null;
    }

    if (this.typeScreen === 'multipleQuiz' && this.test.rule === 'evaluative_tests') {
      data['correctAnswer'] = this.alphabet[this.correctAlternative];
      data['alternativesType'] = 'text';
      let alternatives = this.multipleQuiz.get('alternatives').value || [];
      alternatives = alternatives.map((item, index) => ({
        letter: this.alphabet[index],
        option: item.option,
        isCorrect: index === this.correctAlternative
      }));
      data['alternatives'] = alternatives;
    }

    if (this.test.rule === 'tests_with_weights' || this.test.rule === 'tests_with_weights_profiles') {
      data['alternativesType'] = 'text';
      let alternatives = this.multipleQuizWeight.get('alternatives').value || [];
      alternatives = alternatives.map((item, index) => ({
        letter: this.alphabet[index],
        option: item.option,
        profileId: item.profileId ? item.profileId : this.profiles[0]._id,
        profileValue: item.profileValue,
        profileName: item.profileId ? this.profiles.find(profile => item.profileId === profile._id).name || '' : this.profiles[0].name,
      }));
      data['alternatives'] = alternatives;
    }

    return (data);
  }

  private mountActivities({ name, image, video }: { name: string; image: string, video: string; }) {
    const activities: any = this.test.activities;
    const data = { name };

    if (this.screenId && !this.copy) {

      const index = activities.findIndex(activity => String(activity._id) === String(this.screenId));
      activities[index].name = name;
      if (this.typeScreen === 'instruction') {
        activities[index].presentation = this.mountActivity(image, video);
      } else {
        activities[index].scoreType = this.score.get('type').value || 'boolean';
        if(this.typeScreen === 'weightQuiz') activities[index].hasProfile = false;
        activities[index].content.questions = [this.mountActivity(image, video)];
      }
    } else {

      if (this.typeScreen === 'instruction') {
        data['presentation'] = this.mountActivity(image, video);
      } else {
        data['contentFormat'] = this.typeScreen;
        data['scoreType'] = this.score.get('type').value || 'boolean';

        if (this.typeScreen !== 'instruction' && (this.test.rule === 'tests_with_weights' || this.test.rule === 'tests_with_weights_profiles')) {
          data['contentFormat'] = 'multipleQuiz';
          data['scoreType'] = 'number';
        }

        if(this.typeScreen === 'weightQuiz') data['hasProfile'] = false;
        data['content'] = {
          questions: [this.mountActivity(image, video)],
        };
      }

      activities.push(data);
    }
    return activities;
  }

  private updateTest(image: string, video: string) {
    const name = this.instruction.get('name').value;
    const activities = this.mountActivities({ name, image, video });

    this.testService.updateTest(
      this.companyId,
      this.token,
      this.test._id,
      {
        vacancyTest: {
          activities,
          rule: this.test.rule,
        }
      }
    )
      .subscribe((res) => {
        let message = `A tela <b>${name}</b> foi salva com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });
        this.gotToListScreenPage();
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
        this.isSaveForm = false;
      });
  }

  public gotToListScreenPage() {
    this.router.navigateByUrl(`companies/tests/${this.companyId}/edit-test/${this.testId}?token=${this.token}`);
  }
}
