<div class="progress mb-3" *ngIf="imageUploader && imageUploader.progress">
  <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': imageUploader.progress + '%' }"></div>
</div>
<div class="progress" *ngIf="videoUploader && videoUploader.progress">
  <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': videoUploader.progress + '%' }"></div>
</div>
<div class="container-fluid body">
  <app-header *ngIf="!load" [test]="test" [subTitle]="subTitle"></app-header>

  <div class="alert alert-danger mt-28" role="alert" *ngIf="blockScreen">
    Este tipo de tela não está liberado para a tela customizada do tipo
    {{ test.rule ? TEST_TYPES[test.rule] : 'Testes avaliativos' }}!
  </div>

  <form [formGroup]="form">
    <div class="row" *ngIf="!load">
      <div class="col-4 mt-100">
        <app-instruction [screen]="screen" [instruction]="instruction" [typeScreen]="typeScreen" [image]="image"
          [video]="video" [imageUploader]="imageUploader" [videoUploader]="videoUploader"
          [errorFileMessage]="errorFileMessage" (fileChange)="changeFile($event)">
        </app-instruction>
      </div>

      <app-preview class="col-4" [text]="textField.value" (textChange)="deleteInstructionComponent('text')"
        [image]="image" (imageChange)="deleteInstructionComponent('image')" [video]="video"
        (videoChange)="deleteInstructionComponent('video')" [alternatives]="multipleQuiz.get('alternatives').value"
        [typeScreen]="typeScreen" [rule]="test.rule"
        [alternativesWeight]="multipleQuizWeight.get('alternatives').value">
      </app-preview>

      <div class="col-4 mt-100" *ngIf="isQuestion">
        <div class="custom-card">
          <div class="position-icon-text group-title mb-20">
            <div class="fill-purple mr-3" [inlineSVG]="assets_path + 'assets/images/icons/purple-user-list.svg'"></div>
            Resposta do candidato
          </div>

          <app-record *ngIf="typeScreen === 'video' || typeScreen === 'audio'" [record]="record" [screen]="screen">
          </app-record>

          <app-text *ngIf="typeScreen === 'text'" [answerText]="answerText" [screen]="screen"></app-text>

          <app-multiple-quiz *ngIf="typeScreen === 'multipleQuiz' && test.rule === 'evaluative_tests'"
            [multipleQuiz]="multipleQuiz" [screen]="screen" [(correctAlternative)]="correctAlternative">
          </app-multiple-quiz>

          <app-multiple-quiz-weights
            *ngIf="test.rule === 'tests_with_weights' || test.rule === 'tests_with_weights_profiles'"
            [typeScreen]="typeScreen" [multipleQuizWeight]="multipleQuizWeight" [screen]="screen"
            [(profiles)]="profiles">
          </app-multiple-quiz-weights>
        </div>

        <app-score-type *ngIf="enableCorrectionChoice" [score]="score" [screen]="screen"></app-score-type>
      </div>
    </div>

    <div class="group-buttons" *ngIf="!load">
      <button type="button" class="btn btn-purple-blue mr-3" (click)="gotToListScreenPage()">Cancelar</button>
      <button type="submit" class="btn btn-green" [disabled]="infoScreenValid()" (click)="submitForm()">
        Salvar tela
      </button>
    </div>

  </form>

  <div *ngIf="load" class="load-position">
    <mat-spinner class="my-4" [diameter]="60"></mat-spinner>
  </div>
</div>
