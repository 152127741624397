import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TestService } from '../core/services/test.service';
import { BaseTest } from '../models/test.model';

@Component({
  selector: 'app-homepage-custom-screens',
  templateUrl: './homepage-custom-screens.component.html',
  styleUrls: ['./homepage-custom-screens.component.scss']
})

export class HomepageCustomScreensComponent implements OnInit {
  public load: boolean = true;
  public loadTest: boolean = true;
  public companyId: string = '' as string;
  public token: string = '' as string;
  public tests: BaseTest[];
  public pagLog: any = {
    page: 1,
    pagination: {
      skip: 0,
      limit: 5,
      total: 0
    },
    filters: {},
  };

  constructor(
    private testService: TestService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.companyId = this.route.snapshot.params.companyId;
    this.token = this.route.snapshot.queryParams.token;
    this.getTest();
  }

  public getTest(event?) {
    if (event) {
      this.pagLog.filters = event;
    }
    this.loadTest = true;
    this.testService.listTest(this.companyId, this.token, this.pagLog)
      .subscribe((res: any) => {
        this.tests = res.data;
        this.pagLog.pagination = res.pagination;
        this.load = false;
        this.loadTest = false;
      }, (err) => {
      });
  }

  public createTest() {
    this.router.navigateByUrl(`companies/tests/${this.companyId}/create-test?token=${this.token}`);
  }

}
