<div class="loader-container" *ngIf="load">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div [ngClass]="{'mb-2': canEdit}">
  <lib-taqe-mfe-component-header-config
    [title]=testName
    [subtitle]=testDescription
    (button1Function)="button1Function($event)"
    (button2Function)="button2Function($event)"
  >
  </lib-taqe-mfe-component-header-config>
</div>

<div class="alert-edit" [ngClass]="{'d-none': canEdit}">
  <p>* As telas customizadas que estão vinculadas a algum processo seletivo não podem ser editadas.</p>
</div>

<div class="main" cdkDropListGroup>

  <div class="container-column-1">
    <h1>Módulos</h1>

    <h2>Componentes de Questões e Instruções</h2>
    <div class="group-modules" cdkDropList [cdkDropListData]="questions" cdkDropListSortingDisabled
      (cdkDropListDropped)="drop($event)">

      <div class="modules-box" [ngClass]="{'disabled': item.disabled}" *ngFor="let item of questions" cdkDrag
        [cdkDragDisabled]="item.disabled">
        <img class="modules-icon" src="{{assets_path}}assets/images/icons/{{item.icon}}">
        <p>{{item.value}}</p>
        <img class="modules-icon align-right" src="{{assets_path}}assets/images/icons/drag-indicator.svg"
          alt="icon drag indicator">
      </div>

    </div>

    <h2>Componentes de Respostas</h2>
    <p class="description">Você precisa adicionar uma questão antes.</p>
    <div class="group-modules" cdkDropList [cdkDropListData]="answers" cdkDropListSortingDisabled
      (cdkDropListDropped)="drop($event)">

      <div class="modules-box" [ngClass]="{'disabled': item.disabled}" *ngFor="let item of answers" cdkDrag
        [cdkDragDisabled]="item.disabled">
        <img class="modules-icon" src="{{assets_path}}assets/images/icons/{{item.icon}}">
        <p>{{item.value}}</p>
        <img class="modules-icon align-right" src="{{assets_path}}assets/images/icons/drag-indicator.svg"
          alt="icon drag indicator">
      </div>

    </div>

    <h1>Critério</h1>
    <div class="card-group">
      <p class="card-text">tipo de avaliação</p>
      <div class="btn-group" (click)="onButtonGroupClick($event)">
        <button class="btn btn-light" [ngClass]="{'active': scoreType == 'boolean'}" [disabled]="enableButtons"
          (click)="activeButtonAvaliation('boolean')">
          simples sim/não
        </button>
        <button class="btn btn-light" [ngClass]="{'active': scoreType == 'number'}" [disabled]="enableButtons"
          (click)="activeButtonAvaliation('number')">
          nota de 0 a 10
        </button>
      </div>
    </div>
  </div>

  <div class="container-column-2">

    <app-mobile-screen-test [clearScreen]="clear" [screen]="selectScreen" [lockScreen]="canEdit"
      (selectQuestion)="changeDisabledAnswersComponents($event)" (selectComponent)="changeDisabledComponent($event)"
      (selectComponent)="enableEvaluationsButton($event)" (activeCreateScreen)="enabledCreateScreen($event)"
      (screenNameValue)="saveScreenName($event)" (questionTextValue)="saveQuestionText($event)"
      (questionImageValue)="saveQuestionImage($event)" (questionVideoValue)="saveQuestionVideo($event)"
      (answerTextValue)="saveAnswerText($event)" (answerVideoValue)="saveAnswerVideo($event)"
      (answerAudioValue)="saveAnswerAudio($event)"
      (answerMultipleQuizValue)="saveAnswerQuiz1($event)">
    </app-mobile-screen-test>

  </div>

  <div class="container-column-3">
    <h1>Telas</h1>
    <button class="btn btn-link" [disabled]="disabledCreateScreen" (click)="saveScreen('new', null)">
      Criar nova tela
      <img src="{{assets_path}}assets/images/icons/plus.svg" alt="Mais">
    </button>
    <p class="alert">* Você precisa adicionar o nome da tela e usar pelo menos um componente de questões e instruções.
    </p>

    <div class="list-screen" *ngFor="let item of screens; let i = index" [attr.data-index]="i">
      <button class="list-screen-card" (click)="saveScreen('change', i)">
        <p class="screen-text screen-index">{{i + 1}}.</p>
        <div class="screen-description">
          <p class="screen-text">{{item.name}}</p>
          <p class="screen-text screen-components">
            <span *ngIf="item?.presentation">Instrução: </span>
            <span *ngIf="item?.presentation?.text">Texto</span>
            <span *ngIf="item?.presentation?.text && (item?.presentation?.image || item?.presentation?.video)"> |
            </span>
            <span *ngIf="item?.presentation?.image">Imagem</span>
            <span *ngIf="item?.presentation?.video">Video</span>

            <span *ngIf="item?.contentFormat">Questões: </span>
            <span *ngIf="item?.content?.questions[0]?.text">Texto</span>
            <span
              *ngIf="item?.content?.questions[0]?.text && (item?.content?.questions[0]?.image || item?.content?.questions[0]?.video)">
              | </span>
            <span *ngIf="item?.content?.questions[0]?.image">Imagem</span>
            <span *ngIf="item?.content?.questions[0]?.video">Video</span>
          </p>
          <p class="screen-text screen-components" *ngIf="item?.contentFormat">
            Respostas:
            <span *ngIf="item?.contentFormat === 'text'">Texto</span>
            <span *ngIf="item?.contentFormat === 'video'">Vídeo</span>
            <span *ngIf="item?.contentFormat === 'audio'">Áudio</span>
            <span *ngIf="item?.contentFormat === 'multipleQuiz'">Quiz</span>
          </p>
        </div>
        <button class="components-icon-basic components-icon-delete" (click)="deleteScreen(i)" [disabled]="!canEdit">
          <img src="{{assets_path}}assets/images/icons/circle-delete.svg" alt="delete">
        </button>
        <!-- <img class="modules-icon align-right" src="{{assets_path}}assets/images/icons/drag-indicator.svg" alt="icon drag indicator"> -->
      </button>
    </div>
  </div>

</div>
