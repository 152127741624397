import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FileUploadModule } from 'ng2-file-upload';
import { InlineSVGModule } from 'ng-inline-svg';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';

import { EditCustomScreensComponent } from './edit-custom-screens.component';
import { EditCustomModalModule } from './modal/edit-custom-modal.module';
import { ModalModule } from '../core/modal/modal.module.component';
import { HeaderComponent } from './header/header.component';
import { TestInfoComponent } from './test-info/test-info.component';
import { ChooseScreenTypeComponent } from './choose-screen-type/choose-screen-type.component';
import { ListScreensComponent } from './list-screens/list-screens.component';
import { EditScreenComponent } from './edit-screen/edit-screen.component';
import { PreviewComponent } from './edit-screen/preview/preview.component';
import { InstructionComponent } from './edit-screen/instruction/instruction.component';
import { TextComponent } from './edit-screen/answer/text/text.component';
import { RecordComponent } from './edit-screen/answer/record/record.component';
import { ScoreTypeComponent } from './edit-screen/answer/score-type/score-type.component';
import { MultipleQuizComponent } from './edit-screen/answer/multiple-quiz/multiple-quiz.component';
import { MultipleQuizWeightsComponent } from './edit-screen/answer/multiple-quiz-weights/multiple-quiz-weights.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    EditCustomScreensComponent,
    HeaderComponent,
    TestInfoComponent,
    ChooseScreenTypeComponent,
    ListScreensComponent,
    EditScreenComponent,
    PreviewComponent,
    InstructionComponent,
    TextComponent,
    RecordComponent,
    ScoreTypeComponent,
    MultipleQuizComponent,
    MultipleQuizWeightsComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FileUploadModule,
    InlineSVGModule.forRoot(),
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatCardModule,
    EditCustomModalModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatRadioModule,
    ModalModule,
  ],
  exports: [
    EditCustomScreensComponent,
  ]
})
export class EditCustomScreensModule { }
