<div class="components-answer components-text">
  <button
    class="components-icon-basic components-icon-add" [disabled]="!canEdit"
    (click)="enableDisableRule()"
    [ngClass]="{'' : !right, 'components-icon-add-active' : right}">
    <img src="{{assets_path}}assets/images/icons/check.svg" alt="add">
  </button>
  <button
    class="components-icon-basic components-icon-delete" [disabled]="!canEdit"
    (click)="removeAlternative()">
    <img src="{{assets_path}}assets/images/icons/circle-delete.svg" alt="delete">
  </button>
  <div class="answer-quiz1">
    <p>{{letter}}</p>
    <textarea minlength="2" class="answer-quiz1-option" name="answer-quiz1" rows="1" placeholder="* Digite a alternativa"
    [(ngModel)]="quizText" (ngModelChange)="changeQuizText($event)" [disabled]="!canEdit"></textarea>
  </div>
</div>
