<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="fill-blue modal-img" [inlineSVG]="assets_path + 'assets/images/icons/edit.svg'"></div>
  <h2 class="w-100 text-center title-modal">Editar informações do teste</h2>

  <form [formGroup]="generalInfoForm" class="row">

    <div class="col-3 center-object">
      <p class="info-image">Imagem do teste</p>
      <div class="mb-8">
        <img class="test-img" [src]="testImg || (assets_path + '/assets/images/capas_no_image.png')">
        <div class="pos-rel group-img">
          <label for="customFileInput" class="btn-edit-logo" (click)="changeImage()">
            <div class="fill-blue" [inlineSVG]="assets_path + 'assets/images/icons/edit.svg'"
              alt="Escolher imagem do teste"></div>
          </label>
          <input type="file" class="custom-file-input-image" id="customFileInput" ng2FileSelect [uploader]="uploader"
            formControlName="image" [accept]="ALLOWED_IMAGE.MIMETYPE.toString()" placeholder="Escolher imagem do teste">
        </div>
      </div>
      <p *ngIf="wrongFormate" class="red-text">{{ errorImage }}</p>
    </div>

    <div class="col-9">
      <mat-form-field class="w-100 mb-10" appearance="outline">
        <mat-label>Nome do teste</mat-label>
        <input matInput type="text" placeholder="Digite o nome do teste" formControlName="name"
          (change)="changeName($event)">
        <mat-error *ngIf="name.errors">
          <p class="error-message" *ngIf="name.errors?.required">Campo obrigatório</p>
          <p class="error-message" *ngIf="name.errors?.minlength">O nome precisa ter mais que 5 caracteres</p>
          <p class="error-message" *ngIf="name.errors?.maxlength">O nome precisa ter menos que 20 caracteres</p>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-100 mb-10" appearance="outline">
        <mat-label>Descrição do teste</mat-label>
        <textarea matInput placeholder="Digite a descrição do teste" formControlName="description" cols="30" rows="10"
          (change)="changeText($event)"></textarea>
        <mat-error *ngIf="description.errors">
          <p class="error-message" *ngIf="description.errors?.required">Campo obrigatório</p>
          <p class="error-message" *ngIf="description.errors?.minlength">A descrição precisa ter mais que 10
            caracteres</p>
          <p class="error-message" *ngIf="description.errors?.maxlength">A descrição precisa ter menos que 150
            caracteres</p>
        </mat-error>
      </mat-form-field>
    </div>

  </form>

  <div class="position-button">
    <button class="btn btn-purple-blue mx-4" (click)="close()">Cancelar</button>
    <button class="btn btn-green mx-4" [ngClass]="{'btn-op-gray': !generalInfoForm.valid || wrongFormate}" type="submit"
      [disabled]="!generalInfoForm.valid || wrongFormate || isSaveForm" (click)="submitForm()">Salvar</button>
  </div>
</div>
