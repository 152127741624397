<div class="screen-content" [ngClass]="{'h-70': !test.canEdit}">
  <div *ngFor="let screen of screens; let i = index;">
    <div class="custom-card card-screen w-97">
      <div class="row w-100 card-screen-main">

        <h3 class="col-xl-3 col-xxl-2 custom-card-header card-screen-header">
          Tela {{ i + 1 }} <br>
          {{ (screen.name.length > 20) ? (screen.name | slice:0:20) +'...' : (screen.name) }}
        </h3>

        <div class="col-xl-9 col-xxl-10">
          <dl class="d-flex">
            <dt class="d-flex align-items-center mr-2">
              <div class="icon-tag fill-gray option-icon" [inlineSVG]="assets_path + 'assets/images/icons/tags.svg'">
              </div>
              {{ screen.type }}
            </dt>
            <!-- <dt class="d-flex align-items-center">
              <div class="fill-gray option-icon" [inlineSVG]="assets_path + 'assets/images/icons/time.svg'"></div>
              5 minutos para responder a questão
            </dt> -->
          </dl>

          <p class="card-screen-description">
            {{ (screen.text.length > 20) ? (screen.text | slice:0:200) +'...' : (screen.text) }}
          </p>
        </div>
      </div>

      <dl class="card-screen-footer" *ngIf="test?.canEdit">
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Editar nome da tela"
            (click)="openEditScreenNameModal(screen)" ngbTooltip="Editar nome da tela" tooltipClass="custom-tooltip">
            <div class="icon-pt-4 fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/text-format.svg'"></div>
          </button>
        </dt>
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Duplicar tela" (click)="duplicateScreen(screen)"
            ngbTooltip="Duplicar tela" tooltipClass="custom-tooltip">
            <div class="icon-pt-4 fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/copy.svg'"></div>
          </button>
        </dt>
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Editar tela" (click)="editScreen(screen)"
            ngbTooltip="Editar tela" tooltipClass="custom-tooltip">
            <div class="icon-pt-4 fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/edit.svg'"></div>
          </button>
        </dt>
        <dt class="mx-2">
          <button class="button-icon action-button" aria-label="Excluir tela" (click)="openDeleteScreenModal(screen)"
            ngbTooltip="Excluir" tooltipClass="custom-tooltip">
            <div class="icon-pt-4 fill-gray" [inlineSVG]="assets_path + 'assets/images/icons/delete.svg'"></div>
          </button>
        </dt>
      </dl>
    </div>
  </div>
</div>
