import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TestService } from 'src/app/core/services/test.service';
import { BaseTest } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clone-test',
  templateUrl: './clone-test.component.html',
  styleUrls: ['./clone-test.component.scss']
})
export class CloneTestComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() companyId: string;
  @Input() token: string;

  public assets_path: string = environment.assets_path;
  public isSaveForm: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private testService: TestService,
    private toastrService: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  public createTest() {
    this.isSaveForm = true;

    const data = {
      vacancyTest: {
        name: this.test.name,
        rule: this.test.rule || 'evaluative_tests',
        description: this.test.description,
        image: this.test.image,
        periodicAvailability: this.test.periodicAvailability,
        profiles: this.test.profiles || [],
        activities: this.test.activities
      }
    };

    this.testService.createTest(
      data,
      this.companyId,
      this.token,
    )
      .subscribe((res) => {
        let message = `O teste <b>${this.test.name}</b> foi duplicado com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });

        this.router.navigateByUrl(`companies/tests/${this.companyId}/edit-test/${res._id}?token=${this.token}`);
        this.close();
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As informações não foram salvas!', 'Erro na criação');
        this.isSaveForm = false;
      });
  }

}
