<ng-container [formGroup]="answerText">
  <mat-form-field class="w-100 mb-10" appearance="outline">
    <mat-label>Quantidade mínima de caracteres</mat-label>
    <input matInput type="number" min="1" placeholder="Digite a quantidade de mínima de caracteres"
      formControlName="min" class="text-center" (change)="inputMin($event)">
    <mat-error *ngIf="min.errors">
      <p class="error-message" *ngIf="min.errors?.min">Precisa ter pelo menos 1 caracter</p>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-100 mb-20" appearance="outline">
    <mat-label>Quantidade máxima de caracteres</mat-label>
    <input matInput type="number" min="1" placeholder="Digite a quantidade de máxima de caracteres"
      formControlName="max" class="text-center" (change)="inputMax($event)">
    <mat-error *ngIf="max.errors">
      <p class="error-message" *ngIf="max.errors?.min">A quantidade de carcteres máximo precisa ser maior que a
        quantidade mínima</p>
    </mat-error>
  </mat-form-field>
</ng-container>
