import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TestService } from 'src/app/core/services/test.service';
import { BaseTest } from 'src/app/models/test.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-test',
  templateUrl: './delete-test.component.html',
  styleUrls: ['./delete-test.component.scss']
})
export class DeleteTestComponent implements OnInit {

  @Input() test: BaseTest;
  @Input() companyId: string;
  @Input() token: string;
  public assets_path: string = environment.assets_path;
  public isSave: boolean = false;
  public description: string = '';
  public confirm: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private testService: TestService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.initText();
  }

  private initText() {
    if (!this.test.active || this.test.canEdit) {
      this.description = 'A ação de exluir o teste é irreversível.';
      this.confirm = 'Deseja seguir com a exclusão do teste';
    } else {
      this.description = 'Uma vez que o teste seja usado nas vagas não poderá ser excluída.';
    }
  }

  public close(param?) {
    this.activeModal.close(param);
  }

  public deleteTest() {
    if (!this.test.canEdit) {
      return;
    }

    this.isSave = true;

    this.testService.updateTest(
      this.companyId,
      this.token,
      this.test._id,
      {
        vacancyTest: {
          active: false,
          delected: true
        }
      }
    )
      .subscribe((res) => {
        let message = `O teste <b>${this.test.name}</b> foi excluído com sucesso`;
        this.toastrService.clear();
        this.toastrService.success(message, 'Informação salva!', {
          enableHtml: true,
        });
        this.activeModal.close(true);
      }, (error) => {
        this.toastrService.clear();
        this.toastrService.error('As alterações não foram salvas!', 'Erro na atualização');
      });
  }

}
